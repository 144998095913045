import { createSelector } from "reselect";

const selectUser = (state) => state.semaiUser;

export const selectAllUsers = createSelector(
	[selectUser],
	(user) => user.allUsers
);

export const selectCurrentUser = createSelector(
	[selectUser],
	(user) => user.currentUser
);

export const selectUserError = createSelector(
	[selectUser],
	(user) => user.error
);

export const selectUserLoading = createSelector(
	[selectUser],
	(user) => user.loading
);

export const selectUserRememberMe = createSelector(
	[selectUser],
	(user) => user.rememberMe
);

export const selectUserToken = createSelector(
	[selectUser],
	(user) => user.token
);

export const selectUserRefreshToken = createSelector(
	[selectUser],
	(user) => user.refreshToken
);

export const selectUserProvider = createSelector(
	[selectUser],
	(user) => user.provider
);

export const selectUserRole = createSelector(
	[selectUser], 
	(user) => user.role
);

export const selectUserIdToken = createSelector(
	[selectUser], 
	(user) => user.idToken
);
