import React from "react";
import { NavLink, useRouteMatch } from "react-router-dom";
import { useStyles } from "../NavlinkItem/NavLinkItem.styles";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import BinarIcon from "../../assets/img/binaricon.svg";

const NavLinkItem = (props) => {
	const { path, menuName, menuIcon, open } = props;
	const classes = useStyles({
		open,
	});
	const match = useRouteMatch(path);
	const isActive = !!match
		? match.path === "/"
			? match.isExact
			: path.indexOf(match.path) >= 0
			? true
			: false
		: false;

	return (
		<NavLink className={classes.navigation} to={path} exact>
			<ListItem
				button
				className={`${classes.listItem} ${isActive ? "active" : null}`}
			>
				<ListItemIcon
					className={`${classes.listItemIcon} ${
						isActive ? "active" : null
					}`}
				>
					{menuName != "Binar" ? 
					React.createElement(menuIcon)
					:
					(
						<img src={BinarIcon} width={"25px"} />
					)
					}
				</ListItemIcon>
				<ListItemText
					disableTypography
					primary={menuName}
					className={`${classes.listItemText} ${
						isActive ? "active" : null
					}`}
				/>
			</ListItem>
		</NavLink>
	);
};

export default NavLinkItem;
