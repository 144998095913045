import { Grid, Paper, Button, TextField } from "@material-ui/core";
import MaterialTable from "material-table";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { tableColumns } from "../../helpers/VehicleInputTable.helper";
import CustomModal from "../CustomModal/CustomModal";
import CustomSelect from "../CustomSelect/CustomSelect";
import { useStyles } from "./VehicleInputTable.styles";
import PillButton from "../PillButton/PillButton";
import { selectCurrentUserCompany } from "../../redux/company/company.selector";
import {
	deleteEstateStart,
	fetchAllEstatesStart,
} from "../../redux/estate/estate.action";
import {
	selectAllEstates,
	selectEstateLoading,
} from "../../redux/estate/estate.selector";
import { selectMembersCompanies } from "../../redux/member/member.selector";
import InputVehicleModal from "../InputVehicleModal/InputVehicleModal";
import UpdateVehicleModal from "../UpdateVehicleModal/UpdateVehicleModal";

const VehicleInputTable = (props) => {
	const classes = useStyles();

	const {
		company,
		fetchAllEstatesStart,
		estates,
		isEstateLoading,
		deleteEstateStart,
	} = props;
	const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
	const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
	const [activeData, setActiveData] = useState(null);
	const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
	const tableRef = useRef();

	useEffect(() => {
		if (!!company) {
			fetchAllEstatesStart(company.id);
		}
	}, [company, fetchAllEstatesStart]);

	const handleCreateModalOpen = () => {
		setIsCreateModalOpen(true);
	};

	const handleCreateModalClose = () => {
		setIsCreateModalOpen(false);
	};

	const handleUpdateModalOpen = (data) => {
		setIsUpdateModalOpen(true);
		setActiveData(data);
	};

	const handleUpdateModalClose = () => {
		setIsUpdateModalOpen(false);
		setActiveData(null);
	};

	const handleConfirmationModalOpen = (data) => {
		setOpenConfirmationModal(true);
		setActiveData(data);
	};
	const handleConfirmationModalClose = (data) => {
		setOpenConfirmationModal(false);
		setActiveData(null);
	};

	const handleDeleteVehicle = () => {
		if (!isEstateLoading && !!activeData) {
			deleteEstateStart(activeData.id);
			handleConfirmationModalClose();
			tableRef.current.onChangePage(null, 0);
		}
	};

	return (
		<Grid container className={classes.estateContainer}>
			<InputVehicleModal
				open={isCreateModalOpen}
				onClose={handleCreateModalClose}
			/>
			<UpdateVehicleModal
				open={isUpdateModalOpen}
				onClose={handleUpdateModalClose}
				estateData={activeData}
			/>

			<CustomModal
				open={openConfirmationModal}
				width="40vw"
				title="Apakah anda yakin?"
				onClose={handleConfirmationModalClose}
				footer={
					<Grid container justify="flex-end">
						<Grid item xs={6} container justify="center">
							<PillButton
								element="button"
								type="button"
								text="Ya, saya ingin menghapus"
								onClick={handleDeleteVehicle}
								additionalClasses={[
									classes.pillButton,
									classes.dangerButton,
								]}
							/>
						</Grid>
						<Grid item xs={6} container justify="center">
							<PillButton
								element="button"
								type="button"
								text="Tidak"
								backgroundColor="#B9940D"
								onClick={handleConfirmationModalClose}
								additionalClasses={[classes.pillButton]}
							/>
						</Grid>
					</Grid>
				}
			>
				Apakah anda yakin untuk menghapus data{" "}
				{activeData && `${activeData.name}`}?
			</CustomModal>

			{/* <Grid item xs={12} lg={2} className={classes.selectContainer}>
				<CustomSelect
					fullWidth
					variant="outlined"
					id="companyFilter"
					label="Perusahaan"
					name="companyFilter"
					options={memberCompanies.map((companyName) => ({
						value: companyName,
						text: companyName,
					}))}
					control={control}
				/>
			</Grid> */}
			<Grid
				container
				item
				xs={12}
				justify="flex-end"
				className={classes.sectionContainer}
			>
				<PillButton
					element="button"
					type="button"
					text="Tambah Kendaraan"
                    onClick={handleCreateModalOpen}
					additionalClasses={[classes.pillButton]}
				/>
			</Grid>

			<Grid item xs={12}>
				<MaterialTable
					tableRef={tableRef}
					components={{
						Container: (props) => (
							<Paper {...props} elevation={0} />
						),
					}}
					isLoading={isEstateLoading}
					title="" // EMPTY THIS
					options={{
						search: false,
						headerStyle: {
							// backgroundColor: "#2E7D32",
							// color: "#FFF",
							fontWeight: "600",
						},
					}}
					columns={tableColumns({
						classes,
						handleUpdateModalOpen,
						handleConfirmationModalOpen,
					})}
					data={estates}
				/>
			</Grid>
		</Grid>
	);
};

const mapStateToProps = createStructuredSelector({
	company: selectCurrentUserCompany,
	estates: selectAllEstates,
	isEstateLoading: selectEstateLoading,
});

const mapDispatchToProps = (dispatch) => ({
	fetchAllEstatesStart: (companyId) =>
		dispatch(fetchAllEstatesStart(companyId)),
	deleteEstateStart: (estateId) => dispatch(deleteEstateStart(estateId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VehicleInputTable);
