import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
	container: {
		padding: "24px",
	},
	activitiesTabPanelContainer: {
		// marginTop: "28px",
	},
}));
