import { Grid } from "@material-ui/core";
import React, { lazy } from "react";
import { useStyles } from "./TransportPage.styles";
import TransportTabPanel from "../../components/TransportTabPanel/TransportTabPanel";
import { useParams } from "react-router";

const TransportPage = () => {
	const classes = useStyles();

	const { index } = useParams();

	return (
		<Grid container className={classes.container}>
			<Grid item xs={12} container justify="space-between">
       		 <h1>Kirim</h1>
			</Grid>
			<Grid item xs={12} className={classes.activitiesTabPanelContainer}>
				<TransportTabPanel index={index} />
			</Grid>
		</Grid>
	);
};

export default TransportPage;
