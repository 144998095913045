// core components/views for Public layout
import MapIcon from "@material-ui/icons/Map";
import HomeIcon from "@material-ui/icons/Home";
import StorageIcon from "@material-ui/icons/Storage";
import AssignmentIcon from "@material-ui/icons/Assignment";
import DateRangeIcon from '@material-ui/icons/DateRange';
import EventNoteOutlinedIcon from '@material-ui/icons/EventNoteOutlined';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import PeopleIcon from '@material-ui/icons/People';

import { lazy } from "react";
import AvatarDefault from "@material-ui/icons/AccountCircle";
import TransportPage from "../pages/TransportPage/TransportPage";
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import { TurnedIn } from "@material-ui/icons";
import BinarIcon from "../assets/img/binaricon.svg";
import ScheduleIcon from '@material-ui/icons/Schedule';

const LogoutCallback = lazy(() =>
	import("../pages/LogoutCallback/LogoutCallback")
);
const LoginCallback = lazy(() =>
	import("../pages/LoginCallback/LoginCallback")
);
const Dashboard = lazy(() => import("../pages/Dashboard/Dashboard"));
const MapsPage = lazy(() => import("../pages/MapsPage/MapsPage"));
const Worker = lazy(() => import("../pages/Worker/Worker"))
// const Login = lazy(() => import("../pages/Login/Login"));
const ForgotPassword = lazy(() =>
	import("../pages/ForgotPassword/ForgotPassword")
);
const ForgotPasswordSuccess = lazy(() =>
	import("../pages/ForgotPassword/ForgotPasswordSuccess")
);
const MasterData = lazy(() => import("../pages/MasterData/MasterData"));
const TaksasiDetail = lazy(() =>
	import("../pages/TaksasiDetail/TaksasiDetail")
);
const MutuAncakDetail = lazy(() =>
	import("../pages/MutuAncakDetail/MutuAncakDetail")
);
const UpdateUser = lazy(() => import("../pages/UpdateUser/UpdateUser"));
const MembersPage = lazy(() => import("../pages/MembersPage/MembersPage"));
const Profile = lazy(() => import("../pages/Profile/Profile"));
const ResetPassword = lazy(() =>
	import("../pages/ResetPassword/ResetPassword")
);
const EmailConfirmCallback = lazy(() =>
	import("../pages/EmailConfirmCallback/EmailConfirmCallback")
);
const JoinRequestsPage = lazy(() =>
	import("../pages/JoinRequestsPage/JoinRequestsPage")
);
const Planning = lazy(() => import("../pages/Planning/Planning"));
const KawalPage = lazy(() => import("../pages/KawalPage/KawalPage"));
const RKT = lazy(() => import("../pages/RKT/RKT"));
const RKB = lazy(() => import("../pages/RKB/RKB"));
const MonitoringProduksi = lazy(() => import("../pages/MonitoringProduksi/MonitoringProduksi"));
const BinarPage = lazy(()=>import("../pages/BinarPage/BinarPage"));
const BinarDetailPage = lazy(()=>import("../pages/BinarDetailPage/BinarDetailPage"));
const Absen = lazy(()=> import("../pages/Absen/Absen"));
const RencanaKerjaHarian =(lazy(()=>import("../pages/RencanaKerjaHarian/RencanaKerjaHarian")));
const RealisasiKerjaHarian = (lazy(() => import("../pages/RealisasiKerjaHarian/RealisasiKerjaHarian")));
const RencanaKerjaKaryawan =(lazy(()=>import("../pages/RencanaKerjaKaryawan/RencanaKerjaKaryawan")));
const RealisasiKerjaKaryawan  = (lazy(() => import("../pages/RealisasiKerjaKaryawan/RealisasiKerjaKaryawan")));
const KaryawanDetail= (lazy(()=>import("../pages/KaryawanDetail/KaryawanDetail")));
const Yield = (lazy(() => import("../pages/YieldPage/YieldPage")));
const PupukRKH = (lazy(()=> import("../pages/PupukRKH/PupukRKH")));
const PupukPenerimaan = (lazy(() => import("../pages/PupukPenerimaan/PupukPenerimaan")));
const PupukPermintaan = (lazy(() => import("../pages/PupukPermintaan/PupukPermintaan")));
const YieldBudget = (lazy(()=> import("../pages/YieldBudget/YieldBudget")));



// check auth values = [true, false, null], use null to have public page (can be accessed whether the user is authenticated or not)
const publicRoutes = [
	{
		path: "/",
		component: Dashboard,
		withHeader: true,
		withFooter: true,
		checkAuth: true,
		hasMenu: true,
		menuName: "Dashboard",
		menuIcon: HomeIcon,
		showDrawer : true,
	},
	{
		path: "/login",
		component: null,
		withHeader: false,
		withFooter: false,
		checkAuth: false,
	},
	{
		path: "/forgot-password",
		component: ForgotPassword,
		withHeader: false,
		withFooter: false,
		checkAuth: false,
	},
	{
		path: "/forgot-password-success",
		component: ForgotPasswordSuccess,
		withHeader: false,
		withFooter: false,
		checkAuth: false,
	},
	{
		path: "/reset-password",
		component: ResetPassword,
		withHeader: false,
		withFooter: false,
		checkAuth: false,
	},
	{
		path: "/login-callback",
		component: LoginCallback,
		withHeader: false,
		withFooter: false,
		checkAuth: false,
		showDrawer : false,
	},
	{
		path: "/logout-callback",
		component: LogoutCallback,
		withHeader: false,
		withFooter: false,
		checkAuth: true,
		showDrawer : false,
	},
	{
		path: "/dashboard-absen",
		component: Absen,
		withHeader: true,
		withFooter: true,
		checkAuth: true,
		hasMenu: true,
		menuName: "Presensi Karyawan",
		menuIcon: PermContactCalendarIcon,
		showDrawer : true,
	},
	{
		path: "/dashboard-absen/:index",
		component: Absen,
		withHeader: true,
		withFooter: true,
		checkAuth: true,
		showDrawer : true,
	},
	{
		path: "/rkh-pupuk",
		component: PupukRKH,
		withHeader: true,
		withFooter: true,
		checkAuth: true,
		showDrawer : true,
	},
	{
		path: "/penerimaan-pupuk",
		component: PupukPenerimaan,
		withHeader: true,
		withFooter: true,
		checkAuth: true,
		showDrawer : true,
	},
	{
		path: "/permintaan-pupuk",
		component: PupukPermintaan,
		withHeader: true,
		withFooter: true,
		checkAuth: true,
		showDrawer : true,
	},
	{
		path: "/realisasi-harian",
		component: RealisasiKerjaHarian,
		withHeader: true,
		withFooter: true,
		checkAuth: true,
		showDrawer : true,
	},
	{
		path: "/rencana-harian",
		component: RencanaKerjaHarian,
		withHeader: true,
		withFooter: true,
		checkAuth: true,
		showDrawer : true,
	},
	{
		path: "/realisasi-karyawan",
		component: RealisasiKerjaKaryawan,
		withHeader: true,
		withFooter: true,
		checkAuth: true,
		showDrawer : true,
	},
	{
		path: "/rencana-karyawan",
		component: RencanaKerjaKaryawan,
		withHeader: true,
		withFooter: true,
		checkAuth: true,
		showDrawer : true,
	},
	{
		path: "/detail-karyawan/:karyawanId",
		component: KaryawanDetail,
		withHeader: true,
		withFooter: true,
		checkAuth: true,
		showDrawer : true,
	},
	{
		path: "/maps",
		component: MapsPage,
		withHeader: true,
		withFooter: true,
		checkAuth: true,
		hasMenu: true,
		menuName: "Areal Statement",
		menuIcon: MapIcon,
		showDrawer : true,
	},
	{
		path: "/RKT",
		component: RKT,
		withHeader: true,
		withFooter: true,
		checkAuth: null,
		hasMenu: true,
		menuName: "Rencana Kerja Tahunan",
		menuIcon: DateRangeIcon,
		showDrawer : true,
	},
	{
		path: "/RKB",
		component: RKB,
		withHeader: true,
		withFooter: true,
		checkAuth: null,
		hasMenu: true,
		menuName: "Rencana Kerja Bulanan",
		menuIcon: EventNoteOutlinedIcon,
		showDrawer : true,
	},
	{
		path: "/monitor-produksi",
		component: MonitoringProduksi,
		withHeader: true,
		withFooter: true,
		checkAuth: null,
		hasMenu: true,
		menuName: "Monitoring Produksi",
		menuIcon: SupervisorAccountIcon,
		showDrawer : true,
	},
	{
		path: "/kirim",
		component: TransportPage,
		withHeader: true,
		withFooter: true,
		checkAuth: null,
		hasMenu: true,
		menuName: "Kirim",
		menuIcon: LocalShippingIcon,
		showDrawer : true,
	},
	{
		path: "/kirim/:index",
		component: TransportPage,
		withHeader: true,
		withFooter: true,
		checkAuth: null,
		showDrawer : true,
	},
	{
		path: "/kawal",
		component: KawalPage,
		withHeader: true,
		withFooter: true,
		checkAuth: null,
		hasMenu: true,
		menuName: "Kawal",
		menuIcon: MapIcon,
		showDrawer : true,
	},
	{
		path: "/kawal/:index",
		component: KawalPage,
		withHeader: true,
		withFooter: true,
		checkAuth: null,
		showDrawer : true,
	},
	{
		path: "/worker",
		component: Worker,
		withHeader: true,
		withFooter: true,
		checkAuth: true,
		menuIcon: PeopleIcon,
		menuName: "Worker",
		hasMenu: true,
		showDrawer : true,
	},
	{
		path: "/activities/taksasi/:taksasiId",
		component: TaksasiDetail,
		withHeader: true,
		withFooter: true,
		checkAuth: true,
		showDrawer : true,
	},
	{
		path: "/activities/mutu-ancak/:mutuAncakId",
		component: MutuAncakDetail,
		withHeader: true,
		withFooter: true,
		checkAuth: true,
		showDrawer : true,
	},
	{
		path: "/master-data",
		component: MasterData,
		withHeader: true,
		withFooter: true,
		checkAuth: true,
		hasMenu: true,
		menuName: "Master Data",
		menuIcon: StorageIcon,
		showDrawer : true,
	},
	{
		path: "/binar",
		component: BinarPage,
		withHeader: true,
		withFooter: true,
		checkAuth: null,
		hasMenu: true,
		menuName: "Binar",
		menuIcon: StorageIcon,
		showDrawer : true,
	},
	{
		path: "/binar/:index",
		component: BinarPage,
		withHeader: true,
		withFooter: true,
		checkAuth: null,
		showDrawer : true,
	},
	
	{
		path: "/binardetail/:index",
		component: BinarDetailPage,
		withHeader: true,
		withFooter: false,
		checkAuth: null,
		showDrawer : true,
	},
	{
		path: "/members",
		component: MembersPage,
		withHeader: true,
		withFooter: true,
		checkAuth: true,
		showDrawer : true,
	},
	{
		path: "/update-user",
		component: UpdateUser,
		withHeader: true,
		withFooter: true,
		checkAuth: true,
		showDrawer : true,
	},
	{
		path: "/yield",
		component: Yield,
		withHeader: true,
		withFooter: true,
		checkAuth: true,
		hasMenu: true,
		menuName: "Yield",
		menuIcon: AvatarDefault,
		showDrawer : true,
	},
	{
		path: "/yield-budget",
		component: YieldBudget,
		withHeader: true,
		withFooter: true,
		checkAuth: true,
		showDrawer : true,
	},
	{
		path: "/profile",
		component: Profile,
		withHeader: true,
		withFooter: true,
		checkAuth: true,
		hasMenu: true,
		menuName: "Profile",
		menuIcon: AvatarDefault,
		showDrawer : true,
	},
	{
		path: "/email-confirm-success",
		component: EmailConfirmCallback,
		withHeader: true,
		withFooter: true,
		checkAuth: null,
		showDrawer : false,
	},
	{
		path: "/join-requests",
		component: JoinRequestsPage,
		withHeader: true,
		withFooter: true,
		checkAuth: true,
		showDrawer : true,
	},
	// {
	// 	path: "/planning",
	// 	component: Planning,
	// 	menuIcon: ScheduleIcon,
	// 	hasMenu: true,
	// 	showDrawer : true,
	// 	withHeader: true,
	// 	withFooter: true,
	// 	checkAuth: true,
	// },
];

export default publicRoutes;
