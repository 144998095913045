// Configurations
export const FACEBOOK_ID = process.env.REACT_APP_FACEBOOK_ID;
export const FACEBOOK_SECRET = process.env.REACT_APP_FACEBOOK_SECRET;

export const GOOGLE_ID = process.env.REACT_APP_GOOGLE_ID;
export const GOOGLE_SECRET = process.env.REACT_APP_GOOGLE_SECRET;
export const INSTAGRAM_ID = process.env.REACT_APP_INSTAGRAM_ID;
export const LINKEDIN_ID = process.env.REACT_APP_LINKEDIN_ID;

export const GITHUB_ID = process.env.REACT_APP_GITHUB_ID;

export const AZURE_ID = process.env.REACT_APP_AZURE_ID;
export const AZURE_TENANT_ID = process.env.REACT_APP_AZURE_TENANT_ID;
export const KEYCLOAK_ID =
	process.env.NODE_ENV === "development"
		? process.env.REACT_APP_KEYCLOAK_ID_DEV_LOCAL
		: process.env.REACT_APP_KEYCLOAK_ID_DEV;
export const KEYCLOAK_SECRET = process.env.REACT_APP_KEYCLOAK_SECRET;
export const REDIRECT_URI =
	process.env.NODE_ENV === "development"
		? process.env.REACT_APP_REDIRECT_URI_DEV_LOCAL
		: process.env.REACT_APP_REDIRECT_URI_DEV;
export const REDIRECT_URI_LOGOUT =
	process.env.NODE_ENV === "development"
		? process.env.REACT_APP_REDIRECT_URI_LOGOUT_DEV_LOCAL
		: process.env.REACT_APP_REDIRECT_URI_LOGOUT_DEV;

export const BASE_URL_API = process.env.REACT_APP_BASE_URL_API_DEV;
export const SERVER_ENV = "development";
export const MAPBOX_TOKEN_DEV = process.env.REACT_APP_MAPBOX_TOKEN_DEV;
export const MAPBOX_TOKEN_PROD = process.env.REACT_APP_MAPBOX_TOKEN_PROD;
