import axios from "axios";
import moment from "moment";
import { takeLatest, put, all, call, select } from "redux-saga/effects";
import { BASE_URL_API } from "../../configs/env";
import { openSnackbar } from "../snackbar/snackbar.action";
import { selectCurrentUser } from "../user/user.selector";
import {
	clearVehicleTypeError,
	fetchAllVehicleTypeStart,
	fetchAllVehicleTypeSuccess,
	fetchAllVehicleTypeFailure,
	createVehicleTypeSuccess,
	createVehicleTypeFailure,
	updateVehicleTypeSuccess,
	updateVehicleTypeFailure,
	deleteVehicleTypeSuccess,
	deleteVehicleTypeFailure,
} from "./vehicle-type.action";
import VehicleTypeActionTypes from "./vehicle-type.types";
import queryString from "query-string";
import { selectCurrentUserCompany } from "../company/company.selector";

export function* fetchAllVehicleType() {
	const company = yield select(selectCurrentUserCompany);
	const query = queryString.stringify({
		fltCompany: company.id,
		fltDeleted: 0,
		pageSize: 0,
	});
	try {
		const response = yield axios({
			method: "GET",
			url: `${BASE_URL_API}/vehicle-types?${query}`,
		});

		if (!response.data.success) {
			throw new Error(response.data.message);
		}

		const vehicles = response.data.records;

		yield put(fetchAllVehicleTypeSuccess(vehicles));
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearVehicleTypeError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(fetchAllVehicleTypeFailure(errorMessage));
	}
}

export function* onFetchAllVehicleTypeStart() {
	yield takeLatest(
		VehicleTypeActionTypes.FETCH_ALL_VEHICLE_TYPE_START, fetchAllVehicleType
	);
}

export function* createVehicleType({ payload }) {
	const currentUser = yield select(selectCurrentUser);
	const company = yield select(selectCurrentUserCompany);
	const info = payload;
	try {
		const uuid = yield axios({
			method: "GET",
			url: "https://www.uuidgenerator.net/api/version1",
		});

		const response = yield axios({
			method: "POST",
			url: `${BASE_URL_API}/vehicle-types`,
			data: {
				appMode: 0,
				companyId: company.id,
				companyName: company.name,
				createdBy: currentUser.name,
				createdTime: moment().format("YYYY-MM-DD[T]HH:mm:ssZZ"),
				deletedBy: null,
				deletedTime: null,
				id: uuid.data,
				isDeleted: 0,
				name: info.name,
				syncedStatus: 0,
				syncedTime: moment().format("YYYY-MM-DD[T]HH:mm:ssZZ"),
				updatedBy: currentUser.name,
				updatedTime: moment().format("YYYY-MM-DD[T]HH:mm:ssZZ"),
				version: 0
			},
		});

		if (!response.data.success) {
			throw new Error(response.data.message);
		}

		yield put(createVehicleTypeSuccess());
		yield put(fetchAllVehicleTypeStart());
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearVehicleTypeError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(createVehicleTypeFailure(errorMessage));
	}
}

export function* onCreateVehicleTypeStart() {
	yield takeLatest(
		VehicleTypeActionTypes.CREATE_VEHICLE_TYPE_START, createVehicleType
	);
}

export function* deleteVehicleType({ payload }) {
	const id = payload;
	try {
		const response = yield axios({
			method: "DELETE",
			url: `${BASE_URL_API}/vehicle-types/${id}`,
		});

		if (!response.data.success) {
			throw new Error(response.data.message);
		}

		yield put(deleteVehicleTypeSuccess());
		yield put(fetchAllVehicleTypeStart());
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearVehicleTypeError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(deleteVehicleTypeFailure(errorMessage));
	}
}

export function* onDeleteVehicleTypeStart() {
	yield takeLatest(
		VehicleTypeActionTypes.DELETE_VEHICLE_TYPE_START, deleteVehicleType
	);
}

export function* updateVehicleType({ payload }) {
	const { id, info, version } = payload;
	const currentUser = yield select(selectCurrentUser);
	const company = yield select(selectCurrentUserCompany);
	try {
		const response = yield axios({
			method: "PUT",
			url: `${BASE_URL_API}/vehicle-types/${id}`,
			data: {
				companyId: company.id,
				companyName: company.name,
				name: info.name,
				updatedBy: currentUser.username,
				updatedTime: moment().format("YYYY-MM-DD[T]HH:mm:ssZZ"),
				id: id,
				version: version, 
			},
		});

		if (!response.data.success) {
			throw new Error(response.data.message);
		}

		yield put(updateVehicleTypeSuccess());
		yield put(fetchAllVehicleTypeStart());
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearVehicleTypeError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(updateVehicleTypeFailure(errorMessage));
	}
}

export function* onUpdateVehicleTypeStart() {
	yield takeLatest(
		VehicleTypeActionTypes.UPDATE_VEHICLE_TYPE_START, updateVehicleType
	);
}

export function* vehicleTypeSagas() {
	yield all([
		call(onFetchAllVehicleTypeStart),
		call(onCreateVehicleTypeStart),
		call(onDeleteVehicleTypeStart),
		call(onUpdateVehicleTypeStart),
	]);
}
