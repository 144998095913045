import { Grid, Paper, Typography, Box, Divider, CardMedia, CardHeader, Avatar } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { connect } from "react-redux";
import { useStyles } from "./RoutePlanning.styles";
import CustomDatePicker from "../../components/CustomDatePicker/CustomDatePicker";
import { openSnackbar } from "../../redux/snackbar/snackbar.action";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import { Skeleton } from "@material-ui/lab";
import PillButton from "../../components/PillButton/PillButton";
import moment from "moment";
import RoutePlanningForm from "../RoutePlanningForm/RoutePlanningForm";
import { Doughnut, Line } from "react-chartjs-2";
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import { Fragment } from "react";
import TruckPhoto from "../../assets/img/truck.png"
import AvatarPhoto from "../../assets/img/avatar.jpg"
import KirimOverlay from "../KirimOverlay/KirimOverlay";
import NewRoutePlan from "../NewRoutePlan/NewRoutePlan";

const optionBaris = {text: "All", value:"All"};

const RoutePlanning = (props) => {
	const classes = useStyles();

	const {
		company,
		// activityPlan,
		// fetchActivitiesPlanStart,
		// fetchBlocksStart,
		// fetchBlocksByIdStart,
		blocks,
		// blockSingle,
		// isBlockLoading,
		// isVehicleLoading,
		// vehicle,
		// fetchAllVehicleStart,
		// createRoutePlanStart,
		openSnackbar,
	} = props;


	const {register, handleSubmit, control, watch, setValue} = useForm();
	const [myArray, setMyArray] = useState([optionBaris]);
	const planDate = watch("planDate",null);
	const block = watch("block", "");
	const activity = watch("activityPlan", "");
	const truck = watch("vehicle", "");
	const tph = watch("tph", "");

    const opsiList = [{ id: 'a', value: 'a', name: 'Pengangkutan Buah' },
    { id: 'b', value: 'b', name: 'Pengangkutan Pupuk' }]

	const opsiBlok = [
	{ id: 'G1', value: 'G1', name: 'G1' },
    { id: 'G2', value: 'G2', name: 'G2' },
    { id: 'G3', value: 'G3', name: 'G3' },
	{ id: 'G4', value: 'G4', name: 'G4' },
	{ id: 'G5', value: 'G5', name: 'G5' },
	{ id: 'G6', value: 'G6', name: 'G6' }
	]

	const opsiTruk = [
		{ id: 'G1', value: 'G1', name: 'B 0234 XXX' },
		{ id: 'G2', value: 'G2', name: 'B 0034 ZZZ' },
	]

	const opsiTPH = [
		{ id: 'G1', value: 'G1', name: 'All' },
		{ id: 'G2', value: 'G2', name: '1' },
		{ id: 'G3', value: 'G3', name: '2' },
		{ id: 'G4', value: 'G4', name: '3' },
		]

	const tphDropdown =() =>{
		
		var arr = [optionBaris];
		// for (var i=1; i <= blockSingle.tphCount; i++) {
		// 	//optionBaris.push({text: i.toString(),value: i.toString()});
		// 	arr.push({text: i.toString(),value: i.toString()});
		// }
		setMyArray(arr);
	};

	const resetAll =() =>{
		setValue("planDate", null);
		setValue("block", "");
		setValue("activityPlan", "");
		setValue("vehicle", "");
		setValue("tph", "");
	}

	const submit = () =>
	{
		
	}

	let vehicleDataDummyEmpty = ["A", "B", "C"]
	let vehicleDataDummy = ["C", "D", "E", "F"]

	const [isOverlayOpen, setOverlayOpen] = useState(false)
	const [newRoutePlan, setRoutePlan] = useState(false)

	const handleRoutePlan = () =>{
		setRoutePlan(true)
	}

	const handleOverlayClose = () => {
		setOverlayOpen(false)
	}

	const handleOverlayOpen = () => {
		setOverlayOpen(true)
	}

	return (
		<>
		{!newRoutePlan ?(
			<>
			<Grid
				container
				// className={classes.produksiBulananContainer}
				spacing={2}		
			>
				
			<Grid
				item xs={12}
				container
				direction="row"
				justifyContent="center"
				alignItems="center"
			>
				<KirimOverlay
					open={isOverlayOpen}
					onClose={handleOverlayClose}
				/>

				<Grid container justifyContent="flex-start">
					<Box m={2}>
						<Typography variant="h6">
							Buat Route Plan Baru
						</Typography>
						
					</Box>		
				</Grid>
				<Grid container justifyContent="flex-start" style={{marginLeft: "18px", marginRight: "18px"}}>
					<Paper
						component="form"
						// sx={{ p: '2px 4px', display: 'flex', alignItems: 'center'}}
						variant="outlined"
						// sx={{ ml: 1, flex: 1 }}
					>
						<IconButton type="submit" sx={{ pt: '10px', pb: "10px" }} aria-label="search">
							<SearchIcon />
						</IconButton>
						<InputBase
							sx={{ ml: 1, flex: 1 }}
							placeholder="Cari kendaraan, driver atau lainnya"
							inputProps={{ 'aria-label': 'Cari kendaraan, driver atau lainnya' }}
						/>	
					</Paper>
				</Grid>
					

				<Grid
					item xs={12}
					container
					direction="row"
					justifyContent="space-between"
					alignItems="center"
				>
					{vehicleDataDummyEmpty.map((_) => {
						return (
							<Fragment key={"frag"+_}>
								<Box m={2}>
									<Paper elevation={3} className={classes.paper}>
										<Grid container>
											<Grid item xs={5} className={classes.avatar} container direction="row" alignItems="center" justifyContent="center">
												<Grid item xs={12} className={classes.vehicleDetail} alignItems="center">
												<CardMedia
													component="img"
													height="130"
													// widht="100"
													image= {TruckPhoto}
													alt="Truck"
												/>
												</Grid>
												<Grid item xs={12} className={classes.vehicleDetail} alignItems="center">
													BK 2768 CW
												</Grid>
												<Grid item xs={12} className={classes.vehicleDetail}>
													DUMP TRUCK
												</Grid>
											</Grid>
											
											<Grid item xs={7} className={classes.driver} container direction="row" alignItems="center" justifyContent="center">
												<Grid item xs={10} className={classes.avatar}>
													<CardHeader
														avatar={
															<Avatar src={AvatarPhoto} alt="ava" />
														}
														title="Aggnar Otnay"
														subheader="+62213456789"
													/>
												</Grid>
												<Grid item xs={12} className={classes.detail}>
													<a className={classes.detail} onClick={handleRoutePlan}>BELUM ADA PLAN</a>
												</Grid>
											</Grid>
										</Grid>
									</Paper>
								</Box>					
							</Fragment>
						);
					})}
				</Grid>

				<Divider variant="middle" style={{width:'100%', backgroundColor:"#000000", marginBottom:"150px", visibility:"hidden"}}/>

				<Grid
					item xs={12}
					container
					direction="row"
					justifyContent="space-between"
					alignItems="center"
				>
					{vehicleDataDummy.map((_) => {
						return (
							<Fragment key={"frag"+_}>
								<Box m={2}>
									<Paper elevation={3} className={classes.paper}>
										<Grid container>
											<Grid item xs={5} className={classes.avatar} container direction="row" alignItems="center" justifyContent="center">
												<Grid item xs={12} className={classes.vehicleDetail} alignItems="center">
												<CardMedia
													component="img"
													height="130"
													// widht="100"
													image= {TruckPhoto}
													alt="Truck"
												/>
												</Grid>
												<Grid item xs={12} className={classes.vehicleDetail} alignItems="center">
													BK 2768 CW
												</Grid>
												<Grid item xs={12} className={classes.vehicleDetail}>
													DUMP TRUCK
												</Grid>
											</Grid>
											
											<Grid item xs={7} className={classes.driver} container direction="row" alignItems="center" justifyContent="center">
												<Grid item xs={12} className={classes.avatar}>
													<CardHeader
														avatar={
															<Avatar src={AvatarPhoto} alt="ava" />
														}
														title="Aggnar Otnay"
														subheader="+62213456789"
													/>
												</Grid>
												<Grid item xs={10} className={classes.detail}>
												<a className={classes.detail} onClick={handleOverlayOpen}>LIHAT DETAIL PLAN</a>
												</Grid>
											</Grid>
										</Grid>
									</Paper>
								</Box>					
							</Fragment>
						);
					})}
					
				</Grid>
			</Grid>
				{/* </Grid> */}
			</Grid>
			</>
		) : (
			<>
				<NewRoutePlan/>
			</>
		)}
		</>
	);
};

export default (RoutePlanning);