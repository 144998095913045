import RktActionTypes from "./rkt.types";


export const insertFileStart = (fileInfo) => ({
	type: RktActionTypes.INSERT_FILE_START,
	payload: fileInfo,
});

export const insertFileSuccess = () => ({
	type: RktActionTypes.INSERT_FILE_SUCCESS,
});

export const insertFileFailure = (error) => ({
	type: RktActionTypes.INSERT_FILE_FAILURE,
	payload: error,
});

export const clearFileError = () => ({
	type: RktActionTypes.CLEAR_ERROR,
});

export const fetchAllFileStart = () => ({
	type: RktActionTypes.FETCH_ALL_FILE_START,
});

export const fetchAllFileSuccess = (allUsers) => ({
	type: RktActionTypes.FETCH_ALL_FILE_SUCCESS,
	payload: allUsers,
});

export const fetchAllFileFailure = (error) => ({
	type: RktActionTypes.FETCH_ALL_FILE_FAILURE,
	payload: error,
});

export const importFileStart = (importInfo) => ({
	type: RktActionTypes.IMPORT_FILE_START,
	payload: importInfo,
});

export const importFileSuccess = (info) => ({
	type: RktActionTypes.IMPORT_FILE_SUCCESS,
	payload: info
});

export const importFileFailure = (error) => ({
	type: RktActionTypes.IMPORT_FILE_FAILURE,
	payload: error,
});

export const fetchAllImportedFileStart = () => ({
	type: RktActionTypes.FETCH_ALL_IMPORTED_FILE_START,
});

export const fetchAllImportedFileSuccess = (allUsers) => ({
	type: RktActionTypes.FETCH_ALL_IMPORTED_FILE_SUCCESS,
	payload: allUsers,
});

export const fetchAllImportedFileFailure = (error) => ({
	type: RktActionTypes.FETCH_ALL_IMPORTED_FILE_FAILURE,
	payload: error,
});

export const fetchImportedFileByIdStart = (userId) => ({
	type: RktActionTypes.FETCH_IMPORTED_FILE_BY_ID_START,
	payload: userId
})

export const fetchImportedFileByIdSuccess = (file) => ({
	type: RktActionTypes.FETCH_IMPORTED_FILE_BY_ID_SUCCESS,
	payload: file
})

export const fetchImportedFileByIdFailure = (error) =>({
	type: RktActionTypes.FETCH_IMPORTED_FILE_BY_ID_FAILURE,
	payload: error
})

export const fetchAllTemplateStart = (info) => ({
	type: RktActionTypes.FETCH_ALL_TEMPLATE_START,
	payload: info,
});

export const fetchAllTemplateSuccess = (allUsers) => ({
	type: RktActionTypes.FETCH_ALL_TEMPLATE_SUCCESS,
	payload: allUsers,
});

export const fetchAllTemplateFailure = (error) => ({
	type: RktActionTypes.FETCH_ALL_TEMPLATE_FAILURE,
	payload: error,
});

export const fetchTemplateByIdStart = (userId) => ({
	type: RktActionTypes.FETCH_TEMPLATE_BY_ID_START,
	payload: userId
})

export const fetchTemplateByIdSuccess = (file) => ({
	type: RktActionTypes.FETCH_TEMPLATE_BY_ID_SUCCESS,
	payload: file
})

export const fetchTemplateByIdFailure = (error) =>({
	type: RktActionTypes.FETCH_TEMPLATE_BY_ID_FAILURE,
	payload: error
})

export const fetchAllTemplateCategoryStart = () => ({
	type: RktActionTypes.FETCH_ALL_TEMPLATE_CATEGORY_START,
});

export const fetchAllTemplateCategorySuccess = (allUsers) => ({
	type: RktActionTypes.FETCH_ALL_TEMPLATE_CATEGORY_SUCCESS,
	payload: allUsers,
});

export const fetchAllTemplateCategoryFailure = (error) => ({
	type: RktActionTypes.FETCH_ALL_TEMPLATE_CATEGORY_FAILURE,
	payload: error,
});