import MapsActionTypes from "./maps.types";

export const setGeoJSON = (geoJSON) => ({
	type: MapsActionTypes.SET_GEO_JSON,
	payload: geoJSON,
});

export const clearMapsError = () => ({
	type: MapsActionTypes.CLEAR_ERROR,
});

export const setCompany = (company) => ({
	type: MapsActionTypes.SET_COMPANY,
	payload: company,
});

export const setEstate = (estate) => ({
	type: MapsActionTypes.SET_ESTATE,
	payload: estate,
});

export const setAfdeling = (afdeling) => ({
	type: MapsActionTypes.SET_AFDELING,
	payload: afdeling,
});

export const setBlock = (block) => ({
	type: MapsActionTypes.SET_BLOCK,
	payload: block,
});

export const setActivity = (activity) => ({
	type: MapsActionTypes.SET_ACTIVITY,
	payload: activity,
});

export const setStartDate = (startDate) => ({
	type: MapsActionTypes.SET_START_DATE,
	payload: startDate,
});

export const setEndDate = (endDate) => ({
	type: MapsActionTypes.SET_END_DATE,
	payload: endDate,
});

export const fetchCompaniesStart = () => ({
	type: MapsActionTypes.FETCH_MAP_COMPANIES_START,
});

export const fetchCompaniesSuccess = (companies) => ({
	type: MapsActionTypes.FETCH_MAP_COMPANIES_SUCCESS,
	payload: companies,
});

export const fetchCompaniesFailure = (error) => ({
	type: MapsActionTypes.FETCH_MAP_COMPANIES_FAILURE,
	payload: error,
});

export const fetchEstatesStart = (companyId) => ({
	type: MapsActionTypes.FETCH_MAP_ESTATES_START,
	payload: companyId,
});

export const fetchEstatesSuccess = (estates) => ({
	type: MapsActionTypes.FETCH_MAP_ESTATES_SUCCESS,
	payload: estates,
});

export const fetchEstatesFailure = (error) => ({
	type: MapsActionTypes.FETCH_MAP_ESTATES_FAILURE,
	payload: error,
});

export const fetchAfdelingsStart = (estateId) => ({
	type: MapsActionTypes.FETCH_MAP_AFDELINGS_START,
	payload: estateId,
});

export const fetchAfdelingsSuccess = (afdelings) => ({
	type: MapsActionTypes.FETCH_MAP_AFDELINGS_SUCCESS,
	payload: afdelings,
});

export const fetchAfdelingsFailure = (error) => ({
	type: MapsActionTypes.FETCH_MAP_AFDELINGS_FAILURE,
	payload: error,
});

export const fetchBlocksStart = (afdelingId) => ({
	type: MapsActionTypes.FETCH_MAP_BLOCKS_START,
	payload: afdelingId,
});

export const fetchBlocksSuccess = (blocks) => ({
	type: MapsActionTypes.FETCH_MAP_BLOCKS_SUCCESS,
	payload: blocks,
});

export const fetchBlocksFailure = (error) => ({
	type: MapsActionTypes.FETCH_MAP_BLOCKS_FAILURE,
	payload: error,
});

export const fetchTreesStart = (blockId) => ({
	type: MapsActionTypes.FETCH_TREES_START,
	payload: blockId,
});

export const fetchTreesSuccess = (trees) => ({
	type: MapsActionTypes.FETCH_TREES_SUCCESS,
	payload: trees,
});

export const fetchTreesFailure = (error) => ({
	type: MapsActionTypes.FETCH_TREES_FAILURE,
	payload: error,
});

export const fetchLandSummaryStart = ({ entity, entityId }) => ({
	type: MapsActionTypes.FETCH_LAND_SUMMARY_START,
	payload: { entity, entityId },
});

export const fetchLandSummarySuccess = (landSummary) => ({
	type: MapsActionTypes.FETCH_LAND_SUMMARY_SUCCESS,
	payload: landSummary,
});

export const fetchLandSummaryFailure = (error) => ({
	type: MapsActionTypes.FETCH_LAND_SUMMARY_FAILURE,
	payload: error,
});

export const resetMapsFilter = () => ({
	type: MapsActionTypes.RESET_MAPS_FILTER,
});

export const fetchLandActivitiesStart = ({ entity, entityId, activity }) => ({
	type: MapsActionTypes.FETCH_LAND_ACTIVITIES_START,
	payload: { entity, entityId, activity },
});

export const fetchLandActivitiesSuccess = (landActivities) => ({
	type: MapsActionTypes.FETCH_LAND_ACTIVITIES_SUCCESS,
	payload: landActivities,
});

export const fetchLandActivitiesFailure = (error) => ({
	type: MapsActionTypes.FETCH_LAND_ACTIVITIES_FAILURE,
	payload: error,
});
