import { createSelector } from "reselect";

const selectAfdeling = (state) => state.afdeling;

export const selectAfdelingSingular = createSelector(
	[selectAfdeling],
	(afdeling) => afdeling.singular
);

export const selectAllAfdeling = createSelector(
	[selectAfdeling],
	(afdeling) => afdeling.allAfdeling
);

export const selectAfdelingError = createSelector(
	[selectAfdeling],
	(afdeling) => afdeling.error
);

export const selectAfdelingLoading = createSelector(
	[selectAfdeling],
	(afdeling) => afdeling.loading
);

export const selectAfdelingPlural = createSelector(
	[selectAfdeling],
	(afdeling) => afdeling.plural
);

export const selectActiveAfdelingsTotal = createSelector(
	[selectAllAfdeling],
	(afdelings) => afdelings.length
);