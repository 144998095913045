import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
	estateContainer: {
		// paddingTop: "24px",
		[theme.breakpoints.down("sm")]: {
			paddingLeft: 0,
			paddingRight: 0,
		},
	},
	selectContainer: {
		minWidth: "200px",
		marginTop: "12px",
		marginBottom: "12px",
	},
	buttonContainer: {
		[theme.breakpoints.up("md")]: {
			display: "flex",
			justifyContent: "flex-end",
		},
	},
	paper: {
		position: "absolute",
		backgroundColor: theme.palette.background.paper,
		border: "1px solid #000",
		boxShadow: theme.shadows[5],
		padding: theme.spacing(4, 4, 4),
		top: "30%",
		left: "10%",
	},
	sectionTitle: {
		textAlign: "center",
		fontWeight: "600",
		marginTop: "24px",
	},
	pillButtonOnTable: {
		marginLeft: "12px",
		[theme.breakpoints.down("sm")]: {
			minWidth: "100px",
		},
	},
	buttonContainer: {
		display: "flex",
		justifyContent: "flex-end",
	},
    pillButton: {
		color: "black",
		backgroundColor: '#DED9C4',
		marginTop: "12px",
		// border: "1px solid rgba(185,153,9,255)",
		borderColor:"",

		[theme.breakpoints.down("sm")]: {
			width: "100%",
		},
	},
	dangerButton: {
		background: theme.colors.dangerButton,
	},
	sectionContainer: {
		marginTop: "12px",
		marginBottom: "12px",
	},
    vehicleDetail: {
		// margin: "20px",
		fontSize: "13px",
		fontWeight: "600",
		// padding: "5px",
		textAlign: "center",
	},
	avatar: {
		// margin: "20px",
		fontSize: "12px",
		fontWeight: "600",
		// padding: "5px",
		textAlign: "left",
	},
	detail: {
		// margin: "20px",
		fontSize: "12px",
		fontWeight: "600",
		padding: "5px",
		textAlign: "right",
		color: "#B9940D"
	},
    blok:{
        paddingTop: "20px",
        paddingBottom: "50px",
        paddingLeft: "15px",
        fontFamily: "Roboto",
        fontSize: "12px",
		textAlign: "left",
    },
    link:{
        paddingTop: "50px",
        paddingBottom: "20px",
        paddingRight: "5px",
        textAlign: "right",
		color: "#B9940D",
        // fontFamily: "Roboto",
        fontSize: "12px",
        fontWeight: "600"
    }
}));
