import { all, call } from "redux-saga/effects";
import { joinRequestSagas } from "./join-request/join-request.sagas";
import { userSagas } from "./user/user.sagas";
import { companySagas } from "./company/company.sagas";
import { memberSagas } from "./member/member.sagas";
import { harvestActivitySagas } from "./harvest-activity/harvest-activity.sagas";
import { blockSagas } from "./block/block.sagas";
import { taksasiActivitySagas } from "./taksasi-activity/taksasi-activity.sagas";
import { taksasiVsPanenActivitySagas } from "./taksasiVsPanen-activity/taksasiVsPanen-activity.sagas";
import { mutuAncakActivitySagas } from "./mutu-ancak-activity/mutu-ancak-activity.sagas";
import { estateSagas } from "./estate/estate.sagas";
import { afdelingSagas } from "./afdeling/afdeling.sagas";
import { activitiesCategoriesSagas } from "./activities-categories/activities-categories.sagas";
import { activitiesTypeSagas } from "./activities-type/activities-type.sagas";
import { harvesterSagas } from "./harvester/harvester.sagas";
import { mapsSagas } from "./maps/maps.sagas";
import { vehicleTypeSagas } from "./vehicle-type/vehicle-type.sagas";
import { vehicleSagas } from "./vehicle/vehicle.sagas";
import { monthlyActivityPlanSagas } from "./monthly-activity-plan/monthly-activity-plan.sagas";
import { loggingRawatSagas } from "./logging-rawat/logging-rawat.sagas";
import { loggingAngkutSagas } from "./logging-angkut/logging-angkut.sagas";
import { rktSagas } from "./rkt/rkt.sagas";

export default function* rootSaga() {
	yield all([
		call(userSagas),
		call(joinRequestSagas),
		call(companySagas),
		call(memberSagas),
		call(harvestActivitySagas),
		call(blockSagas),
		call(taksasiActivitySagas),
		call(mutuAncakActivitySagas),
		call(estateSagas),
		call(mapsSagas),
		call(afdelingSagas),
		call(activitiesCategoriesSagas),
		call(activitiesTypeSagas),
		call(taksasiVsPanenActivitySagas),
		call(harvesterSagas),
		call(vehicleTypeSagas),
		call(vehicleSagas),
		call(monthlyActivityPlanSagas),
		call(loggingAngkutSagas),
		call(loggingRawatSagas),
		call(rktSagas)
	]);
}
