import { makeStyles } from "@material-ui/core/styles";
const drawerWidth = 300;

export const useStyles = makeStyles((theme) => ({
	root: {
		position: "relative",
		marginTop: "120px",
		background: "#FFFFFF",
		zIndex: 0,
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
		whiteSpace: "nowrap",
		background: "#ded9c5",
	},
	drawerOpen: {
		width: drawerWidth,
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
		background: "#ded9c5",
	},
	drawerClose: {
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		overflow: "visible",
		width: theme.spacing(7) + 1,
		[theme.breakpoints.up("sm")]: {
			width: theme.spacing(8) + 1,
		},
		background: "#ded9c5",
	},
	toolbar: {
		marginTop: "100px",
		display: "flex",
		alignItems: "center",
		justifyContent: "flex-end",
		// necessary for content to be below app bar
		...theme.mixins.toolbar,
	},
	content: {
		display: "flex",
		position: "relative",
		background: "#FFFFFF",
		zIndex: 0,
		paddingBottom: "90px",
		paddingLeft: "90px",
		[theme.breakpoints.down("sm")]: {
			paddingLeft: 0,
			// marginTop: "50px",
		},
	},
	listItemIcon: {
		transition: "all 0.5s cubic-bezier(0.19, 1, 0.22, 1) !important",
		"&.active": {
			color: "white",
		},
	},
	listItemText: {
		transition: "all 0.5s cubic-bezier(0.19, 1, 0.22, 1)",
		"&.active": {
			fontWeight: "600 !important",
		},
		overflowWarp:"break-word",
	},
	listItem: {
		overflow: "hidden",
		transition: "all 0.5s cubic-bezier(0.19, 1, 0.22, 1) !important",
		"&.active": {
			backgroundColor: "rgba(183,149,16,255)", //theme.colors.primary,
			color: "white",
		},
		"&:hover": {
			[theme.breakpoints.up("md")]: {
				overflow: "visible",
				backgroundColor: `rgba(183,149,16,255) !important`,
				color: "white",
				borderRadius: ({ open }) => (open ? "0" : "0 6px 6px 0"),
				width: "300px",
				"& $listItemText": {
					fontWeight: "600 !important",
				},
				"& $listItemIcon": {
					color: "white",
				},
			},
		},
	},
}));
