import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router } from "react-router-dom";

import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import App from "./App";
import { persistor, store } from "./redux/store";

const hist = createBrowserHistory();

ReactDOM.render(
	<Provider store={store}>
		<PersistGate persistor={persistor}>
			<Router history={hist}>
				<App />
			</Router>
		</PersistGate>
	</Provider>,
	document.getElementById("root")
);
