import { Grid, Paper, Button, TextField, Typography, Box, Divider, CardMedia, CardHeader, Avatar } from "@material-ui/core";
import MaterialTable from "material-table";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import moment from "moment";
import { createStructuredSelector } from "reselect";
import { tableColumns } from "../../helpers/RoutePlanTable.helper";
import CustomModal from "../CustomModal/CustomModal";
import CustomSelect from "../CustomSelect/CustomSelect";
import { useStyles } from "./NewRoutePlan.styles";
import PillButton from "../PillButton/PillButton";
import CustomDatePicker from "../../components/CustomDatePicker/CustomDatePicker";
import { selectCurrentUserCompany } from "../../redux/company/company.selector";
import { selectAfdelingPlural } from "../../redux/afdeling/afdeling.selector";
import { fetchAfdelingByEstateStart } from "../../redux/afdeling/afdeling.action";
import {
	deleteEstateStart,
	fetchAllEstatesStart,
} from "../../redux/estate/estate.action";
import {
	selectAllEstates,
	selectEstateLoading,
} from "../../redux/estate/estate.selector";
import {
	selectBlockLoading,
	selectBlockPlural,
} from "../../redux/block/block.selector";
import {
	fetchBlocksStart,
} from "../../redux/block/block.action";
import { selectMembersCompanies } from "../../redux/member/member.selector";
import InputWorkDetailModal from "../InputWorkDetailModal/InputWorkDetailModal";
import UpdateWorkDetailModal from "../UpdateWorkDetailModal/UpdateWorkDetailModal";
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import { Fragment } from "react";
import TruckPhoto from "../../assets/img/truck.png"
import AvatarPhoto from "../../assets/img/avatar.jpg"
import NoDriver from "../../assets/img/no_driver.png"
import KirimOverlay from "../KirimOverlay/KirimOverlay";
import { AddBox } from "@material-ui/icons";
import DriverAssignOverlay from "../DriverAssignOverlay/DriverAssignOverlay";
import CreateRoutePlanModal from "../CreateRoutePlanModal/CreateRoutePlanModal";


const optionBaris = {text: "All", value:"All"};

const NewRoutePlan = (props) => {
	const classes = useStyles();

	const {
		company,
		// activityPlan,
		// fetchActivitiesPlanStart,
		// fetchBlocksByIdStart,
		blocks,
		estates,
		afdelings,
		// blockSingle,
		// isBlockLoading,
		// isVehicleLoading,
		// vehicle,
		// fetchAllVehicleStart,
		// createRoutePlanStart,
		fetchAfdelingByEstateStart,
    	fetchAllEstatesStart,
		fetchBlocksStart,
		openSnackbar,
	} = props;


	const {register, handleSubmit, control, watch, setValue} = useForm();
	const [myArray, setMyArray] = useState([optionBaris]);
	const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
	const [day, setDay] = useState("");
	const planDate = watch("planDate",null);
	const block = watch("block", "");
	const activity = watch("activityPlan", "");
	const truck = watch("vehicle", "");
	const tph = watch("tph", "");
	const idEstate = watch("estate", "");
	const afdeling = watch ("afdeling","");

	
	useEffect(() => {
		if (!!company) {
			// fetchAllVehicleTypeStart()
			fetchAllEstatesStart();
		}
	}, [company, fetchAllEstatesStart]);

	useEffect(() => {
		if (!!company) {
		  if (!!idEstate) {
			fetchAfdelingByEstateStart(idEstate);
		  } else {
			fetchAfdelingByEstateStart();
		  }
		}
	  }, [idEstate, company]);

	useEffect(() => {
		if (!!company) {
		  if (!!afdeling) {
			fetchBlocksStart({fltDeleted:0, fltAfdeling:afdeling});
		  } 
		}
	  }, [afdeling, company]);


    const opsiList = [{ id: 'a', value: 'a', name: 'Pengangkutan Buah' },
    { id: 'b', value: 'b', name: 'Pengangkutan Pupuk' }]

	const opsiBlok = [
	{ id: 'G1', value: 'G1', name: 'G1' },
    { id: 'G2', value: 'G2', name: 'G2' },
    { id: 'G3', value: 'G3', name: 'G3' },
	{ id: 'G4', value: 'G4', name: 'G4' },
	{ id: 'G5', value: 'G5', name: 'G5' },
	{ id: 'G6', value: 'G6', name: 'G6' }
	]

	const opsiTruk = [
		{ id: 'G1', value: 'G1', name: 'B 0234 XXX' },
		{ id: 'G2', value: 'G2', name: 'B 0034 ZZZ' },
	]

	const opsiTPH = [
		{ id: 'G1', value: 'G1', name: 'All' },
		{ id: 'G2', value: 'G2', name: '1' },
		{ id: 'G3', value: 'G3', name: '2' },
		{ id: 'G4', value: 'G4', name: '3' },
		]

	const resetAll =() =>{
		setValue("planDate", null);
		setValue("block", "");
		setValue("activityPlan", "");
		setValue("vehicle", "");
		setValue("tph", "");
		setDay("");
	}

	// let driverDummy = ["A"]
	let empDummy = ["C", "D", "E"];

	const opsiMaksud = [
		{ id: 'tbs', value: 'tbs', name: 'Angkut TBS' },
		{ id: 'tbs2', value: 'tbs2', name: 'Angkut TBS 2' },
		{ id: 'tbs3', value: 'tbs3', name: 'Angkut TBS 3' },
		{ id: 'tbs4', value: 'tbs4', name: 'Angkut TBS 4' },
		
	]

	const handleCreateModalOpen = () => {
		setIsCreateModalOpen(true);
	};

	const handleCreateModalClose = () => {
		setIsCreateModalOpen(false);
	};


	return (
		<Grid
			container
			// className={classes.produksiBulananContainer}
			spacing={2}		
		>
			
		<Grid
			item xs={12}
			container
			direction="row"
			justifyContent="center"
			alignItems="center"
		>
			<CreateRoutePlanModal
				open={isCreateModalOpen}
				onClose={handleCreateModalClose}
			/>
			
			<Box m={2}>
				<Typography variant="h6">
					Buat Route Plan Baru
				</Typography>
			</Box>

			<Grid
				item xs={12}
				container
				direction="row"
				// justifyContent="flex-start"
				// alignItems="center"
			>
				{/* <Box m={2}> */}
					{/* <Paper elevation={3} className={classes.paper}> */}
						<Grid container>
							<Grid item xs={2} className={classes.vehicle} container direction="row" alignItems="left" justifyContent="center">
								<Box >
									<CardMedia
										component="img"
										height="150"
										// widht="100"
										image= {TruckPhoto}
										alt="Truck"
									/>
								</Box>
							</Grid>
							<Grid item xs={2} className={classes.vehicle} container direction="column" alignItems="left" justifyContent="center">
								<Box m={0.5}>
									BK 2768 CW
								</Box>
								<Box m={0.5} >
									DUMP TRUCK
								</Box>
							</Grid>
							
							<Grid item xs={2} className={classes.vehicle} container direction="column" alignItems="right" justifyContent="center">
								<Box m={0.5} >
									ODOMETER
								</Box>
								<Box m={0.5} >
									SERVIS DALAM (KM)
								</Box>
							</Grid>

							<Grid item xs={2} className={classes.vehicle} container direction="column" alignItems="left" justifyContent="center">
								<Box m={0.5}>
									: 567223 KM
								</Box>
								<Box m={0.5} >
									: 2431 KM
								</Box>
							</Grid>

							<Grid item xs={2} className={classes.vehicle} container direction="row" alignItems="left" justifyContent="center">
								<Box m={0.5}>
									VALID STNK
								</Box>
							</Grid>

							<Grid item xs={1} className={classes.vehicle} container direction="row" alignItems="left" justifyContent="center">
								<Box m={0.5}>
									: NOV 2021
								</Box>
							</Grid>
						</Grid>
					{/* </Paper> */}
				{/* </Box> */}
			</Grid>

			<Grid container alignItems="center" justifyContent="center">
				<Grid item xs={12} className={classes.avatar} container direction="column" alignItems="center" justifyContent="center">
					<Grid item xs={12} className={classes.avatar}>
						<CardHeader
							avatar={
								<Avatar src={AvatarPhoto} alt="ava"/>
							}
							title="Aggnar Otnay"
							subheader="+62213456789"
							// sx={{align:"center"}}
						/>
					</Grid>
				</Grid>
			</Grid>

			<Divider variant="middle" style={{width:'100%', backgroundColor:"#000000", marginBottom:"20px"}}/>
			<Grid
				container
				item
				xs={12}
				justify="flex-start"
				className={classes.sectionContainer}
			>
				<Grid item xs={2} container justify="left" style={{paddingTop:"10px"}}>
					<h3>Pilih hari</h3>
				</Grid>
				<Grid item container xs={10}>
				<Grid item >
					<PillButton
						element="button"
						type="button"
						text="Senin"
						backgroundColor={day=="Senin" ? "#B9940D" : "white"}
						color={day=="Senin" ? "white" : "#B9940D"}
						onClick={() => setDay("Senin")}
						additionalClasses={[classes.pillButtonDays]}
					/>
				</Grid>
				<Grid item>
					<PillButton
						element="button"
						type="button"
						text="Selasa"
						backgroundColor={day=="Selasa" ? "#B9940D" : "white"}
						color={day=="Selasa" ? "white" : "#B9940D"}
						onClick={() => setDay("Selasa")}
						additionalClasses={[classes.pillButtonDays]}
					/>
				</Grid>
				<Grid item>
					<PillButton
						element="button"
						type="button"
						text="Rabu"
						backgroundColor={day=="Rabu" ? "#B9940D" : "white"}
						color={day=="Rabu" ? "white" : "#B9940D"}
						onClick={() => setDay("Rabu")}
						additionalClasses={[classes.pillButtonDays]}
					/>
				</Grid>
				<Grid item>
					<PillButton
						element="button"
						type="button"
						text="Kamis"
						backgroundColor={day=="Kamis" ? "#B9940D" : "white"}
						color={day=="Kamis" ? "white" : "#B9940D"}
						onClick={() => setDay("Kamis")}
						additionalClasses={[classes.pillButtonDays]}
					/>
				</Grid>
				<Grid item>
					<PillButton
						element="button"
						type="button"
						text="Jumat"
						backgroundColor={day=="Jumat" ? "#B9940D" : "white"}
						color={day=="Jumat" ? "white" : "#B9940D"}
						onClick={() => setDay("Jumat")}
						additionalClasses={[classes.pillButtonDays]}
					/>
				</Grid>
				<Grid item>
					<PillButton
						element="button"
						type="button"
						text="Sabtu"
						backgroundColor={day=="Sabtu" ? "#B9940D" : "white"}
						color={day=="Sabtu" ? "white" : "#B9940D"}
						onClick={() => setDay("Sabtu")}
						additionalClasses={[classes.pillButtonDays]}
					/>
				</Grid>
				<Grid item >
					<PillButton
						element="button"
						type="button"
						text="Minggu"
						backgroundColor={day=="Minggu" ? "#B9940D" : "white"}
						color={day=="Minggu" ? "white" : "#B9940D"}
						onClick={() => setDay("Minggu")}
						additionalClasses={[classes.pillButtonDays]}
					/>
				</Grid>
				</Grid>
			</Grid>

			<Grid
				container
				item
				xs={12}
				justify="flex-start"
				className={classes.sectionContainer}
			>
				<Grid item xs={2} container justify="left" style={{paddingTop:"10px"}}>
					<h3>Aktif sampai dengan</h3>
				</Grid>
				<Grid item xs={10}>
					<CustomDatePicker
                            //fullWidth
                            inputVariant="outlined"
                            id="planDate"
                            name="planDate"
                            control={control}
                            placeholder="dd-mm-yyyy"
                            format="dd-MM-yyyy"
                            label="Aktif sampai"
                            views={["year", "month", "date"]}
                            defaultValue={moment().add(1,'days')}
                            //required
                        />
				</Grid>
			</Grid>

			<Divider variant="middle" style={{width:'100%', backgroundColor:"#000000", marginBottom:"20px", marginTop:"15px"}}/>
			
			{/*<Grid
				container
				item
				xs={12}
				justify="flex-end"
				className={classes.sectionContainer}
			>
				<PillButton
					element="button"
					type="button"
					text="Tambah Plan"
					backgroundColor="#B9940D"
					color="white"
					onClick={handleCreateModalOpen}
					additionalClasses={[classes.pillButtonOnTable]}
				/>
			</Grid>*/}
			
			<Grid item xs={12} className={classes.tableContainer}>
				<MaterialTable
					title=""
					components={{
						Container: (props) => (
							<Paper {...props} elevation={0} />
						),
					}}
					columns={tableColumns({
						classes,
					})}
					data={[
					  { maksud: 'ANGKUT TBS', estate: 'ESTATE VII', afdeling: "AFD 28", block: "BLOK G1", tph: 'TPH 22' },
					  { maksud: 'ANGKUT TBS', estate: 'ESTATE VII', afdeling: "AFD 28", block: "BLOK G1", tph: 'TPH 23' }
					]}        
					options={{
						// filtering: true
						search: false,
						sorting: true,
						headerStyle: {
							fontWeight: "600",
						},
						actionsColumnIndex: -1
					}}
					
				/>
			</Grid>
			
			<Grid
				container
				item
				xs={12}
				justify="flex-start"
				className={classes.foot}
				spacing={1}
			>
				<Grid item xs={2}>
					<CustomSelect
						fullWidth
						variant="outlined"
						id="maksud"
						label="Maksud Perjalanan"
						name="maksud"
						options={opsiMaksud.map((maksud) => ({
							value: maksud.id,
							text: maksud.name,
						}))}
						control={control}
						required
					/>
				</Grid>
				<Grid item xs={2}>
					<CustomSelect
								fullWidth
								variant="outlined"
								id="estate"
								label="Estate"
								name="estate"
								options={estates.map((estate) => ({
									value: estate.id,
									text: estate.name,
								}))}
								control={control}
								required
							/>
				</Grid>
				<Grid item xs={2}>
						<CustomSelect
								fullWidth
								variant="outlined"
								id="afdeling"
								label="Afdeling/Divisi"
								name="afdeling"
								options={afdelings.map((afdeling) => ({
									value: afdeling.id,
									text: afdeling.name,
								}))}
								control={control}

							/>
				</Grid>
				<Grid item xs={1} md={2}>
						<CustomSelect
								fullWidth
								variant="outlined"
								id="block"
								label="Block"
								name="block"
								options={blocks.map((block) => ({
									value: block.id,
									text: block.Name,
								}))}
								control={control}
								required
							/>
				</Grid>
				<Grid item xs={1} md={2}>
					<CustomSelect
								fullWidth
								variant="outlined"
								id="tph"
								label="Opsi TPH"
								name="tph"
								options={opsiTPH.map((tph) => ({
									value: tph.id,
									text: tph.name,
								}))}
								control={control}
								required
							/>
				</Grid>
				<Grid item xs={1}>
						<PillButton
							element="button"
							type="button"
							text="Tambah"
							backgroundColor="#B9940D"
							color="white"
							additionalClasses={[classes.pillButtonOnTable]}
						/>
				</Grid>
			</Grid>

		</Grid>
			{/* </Grid> */}
		</Grid>
		);
};

const mapStateToProps = createStructuredSelector({
 	company: selectCurrentUserCompany,
	estates: selectAllEstates,
	afdelings: selectAfdelingPlural,
 	isEstateLoading: selectEstateLoading,
	blocks: selectBlockPlural,
});

const mapDispatchToProps = (dispatch) => ({
	fetchAllEstatesStart: (companyId) =>
		dispatch(fetchAllEstatesStart(companyId)),
	fetchAfdelingByEstateStart: (companyId) =>
    	dispatch(fetchAfdelingByEstateStart(companyId)),
	fetchBlocksStart: (filters) => dispatch(fetchBlocksStart(filters)),
// 	deleteEstateStart: (estateId) => dispatch(deleteEstateStart(estateId)),
});

export default connect(mapStateToProps, mapDispatchToProps) (NewRoutePlan);
