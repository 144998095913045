const RktActionTypes = {
	//INSERT DATA-DATA PENDUKUNG FILE
    INSERT_FILE_START: "INSERT_FILE_START",
	INSERT_FILE_SUCCESS: "INSERT_FILE_SUCCESS",
	INSERT_FILE_FAILURE: "INSERT_FILE_FAILURE",
	//FETCH ALL FILE
	FETCH_ALL_FILE_SUCCESS: "FETCH_ALL_FILE_SUCCESS",
	FETCH_ALL_FILE_START: "FETCH_ALL_FILE_START",
	FETCH_ALL_FILE_FAILURE: "FETCH_ALL_FILE_FAILURE",
	//CLEAR ERROR
	CLEAR_ERROR: "CLEAR_ERROR",
	// IMPORT USERS
	IMPORT_FILE_START: "IMPORT_FILE_START",
	IMPORT_FILE_SUCCESS: "IMPORT_FILE_SUCCESS",
	IMPORT_FILE_FAILURE: "IMPORT_FILE_FAILURE",
	//FETCH ALL IMPORTED FILE
	FETCH_ALL_IMPORTED_FILE_START: "FETCH_ALL_IMPORTED_FILE_START",
	FETCH_ALL_IMPORTED_FILE_SUCCESS: "FETCH_ALL_IMPORTED_FILE_SUCCESS",
	FETCH_ALL_IMPORTED_FILE_FAILURE: "FETCH_ALL_IMPORTED_FILE_FAILURE",
	//FETCH AN IMPORTED FILE
	FETCH_IMPORTED_FILE_BY_ID_START: "FETCH_IMPORTED_FILE_BY_ID_START",
	FETCH_IMPORTED_FILE_BY_ID_SUCCESS: "FETCH_IMPORTED_FILE_BY_ID_SUCCESS",
	FETCH_IMPORTED_FILE_BY_ID_FAILURE: "FETCH_IMPORTED_FILE_BY_ID_FAILURE",
	//FETCH ALL TEMPLATE
	FETCH_ALL_TEMPLATE_START: "FETCH_ALL_TEMPLATE_START",
	FETCH_ALL_TEMPLATE_SUCCESS: "FETCH_ALL_TEMPLATE_SUCCESS",
	FETCH_ALL_TEMPLATE_FAILURE: "FETCH_ALL_TEMPLATE_FAILURE",
	//FETCH A TEMPLATE
	FETCH_TEMPLATE_BY_ID_START: "FETCH_TEMPLATE_BY_ID_START",
	FETCH_TEMPLATE_BY_ID_SUCCESS: "FETCH_TEMPLATE_BY_ID_SUCCESS",
	FETCH_TEMPLATE_BY_ID_FAILURE: "FETCH_TEMPLATEE_BY_ID_FAILURE",
	//FETCH ALL TEMPLATE_CATEGORY
	FETCH_ALL_TEMPLATE_CATEGORY_START: "FETCH_ALL_TEMPLATE_CATEGORY_START",
	FETCH_ALL_TEMPLATE_CATEGORY_SUCCESS: "FETCH_ALL_TEMPLATE_CATEGORY_SUCCESS",
	FETCH_ALL_TEMPLATE_CATEGORY_FAILURE: "FETCH_ALL_TEMPLATE_CATEGORY_FAILURE",
}

export default RktActionTypes;