import {
	FormControl,
	FormHelperText,
	InputLabel,
	MenuItem,
	Select,
} from "@material-ui/core";
import React from "react";
import { Controller } from "react-hook-form";
import { useStyles } from "./CustomSelect.styles";

const CustomSelect = (props) => {
	const classes = useStyles();
	const {
		id,
		label,
		name,
		options,
		control,
		rules,
		language,
		error,
		helperText,
		selectClasses,
		emptyText,
		...otherSelectProps
	} = props;

	return (
		<FormControl
			key={id}
			className={classes.formControl}
			error={error}
			{...otherSelectProps}
		>
			<InputLabel id={`${id}Label`}>{label}</InputLabel>
			<Controller
				rules={{
					...rules,
				}}
				control={control}
				name={name}
				defaultValue={""}
				render={({ field }) => {
					return (
						<Select
							key={id}
							labelId={`${id}Label`}
							id={name}
							label={label}
							{...field}
							classes={selectClasses}
						>
							<MenuItem value="">
								<em>{!!emptyText ? emptyText : "None"}</em>
							</MenuItem>
							{options.map((option, idx) => (
								<MenuItem
									key={`${name}-option-${idx}`}
									value={option.value}
								>
									{option[`text`]}
								</MenuItem>
							))}
						</Select>
					);
				}}
			/>
			<FormHelperText>{helperText}</FormHelperText>
		</FormControl>
	);
};

export default CustomSelect;
