import { createSelector } from "reselect";

const selectVehicle = (state) => state.vehicle;

export const selectVehicleSingular = createSelector(
	[selectVehicle],
	(vehicle) => vehicle.singular
);

export const selectAllVehicle = createSelector(
	[selectVehicle],
	(vehicle) => vehicle.allVehicle
);

export const selectVehicleError = createSelector(
	[selectVehicle],
	(vehicle) => vehicle.error
);

export const selectVehicleLoading = createSelector(
	[selectVehicle],
	(vehicle) => vehicle.loading
);
