import axios from "axios";
import { takeLatest, put, all, call, select, take } from "redux-saga/effects";
import KeycloakLogout from "../../auth/Logout/KeycloakLogout";
import KeycloakRefreshToken from "../../auth/RefreshTokenSetup/KeycloakRefreshToken";
import AzureToken from "../../auth/TokenEndpointSetup/AzureToken";
import FacebookToken from "../../auth/TokenEndpointSetup/FacebookToken";
import GoogleToken from "../../auth/TokenEndpointSetup/GoogleToken";
import KeycloakToken from "../../auth/TokenEndpointSetup/KeycloakToken";
import AzureUser from "../../auth/UserDetails/AzureUser";
import FacebookUser from "../../auth/UserDetails/FacebookUser";
import GoogleUser from "../../auth/UserDetails/GoogleUser";
import KeycloakUser from "../../auth/UserDetails/KeycloakUser";
import { BASE_URL_API } from "../../configs/env";
import { openSnackbar } from "../snackbar/snackbar.action";
import {
	clearUserError,
	checkAccessTokenSuccess,
	getAccessTokenFailure,
	getAccessTokenSuccess,
	checkAccessTokenStart,
	logoutFailure,
	refreshAccessTokenSuccess,
	refreshAccessTokenFailure,
	refreshAccessTokenStart,
	fetchAllUsersSuccess,
	fetchAllUsersFailure,
	fetchUserSuccess,
	fetchUserFailure,
	fetchUserStart,
	logoutStart,
	forgotPasswordFailure,
	forgotPasswordSuccess,
	registerUserFailure,
	registerUserSuccess,
	updateUserSuccess,
	updateUserFailure,
	resetUserPasswordFailure,
	insertUserSuccess,
	insertUserFailure,
	importUsersSuccess,
	importUsersFailure,
	fetchAllUsersStart,
	deleteUserSuccess,
	deleteUserFailure,
	setUserRole,
} from "./user.action";
import {
	selectCurrentUser,
	selectUserLoading,
	selectUserProvider,
	selectUserRefreshToken,
	selectUserToken,
	selectUserIdToken,
} from "./user.selector";
import UserActionTypes from "./user.types";
import moment from "moment";
import { fetchCurrentUserCompanyStart } from "../company/company.action";
import { selectCurrentUserCompany } from "../company/company.selector";
import queryString from "query-string";
import { channel } from "@redux-saga/core";

const userChannel = channel();

export function* watchUserChannel() {
	while (true) {
		const action = yield take(userChannel);
		yield put(action);
	}
}

export function* isUserAuthenticated() {
	try {
		const currentUser = yield select(selectCurrentUser);
		const accessToken = yield select(selectUserToken);
		const provider = yield select(selectUserProvider);
		const isUserLoading = yield select(selectUserLoading);
		const refreshToken = yield select(selectUserRefreshToken);

		if (!!currentUser) {
			if (!!accessToken && !!provider) {
				yield put(checkAccessTokenStart({ accessToken, provider }));
			} else {
				if (!isUserLoading && !!refreshToken) {
					yield put(refreshAccessTokenStart(provider));
				} else {
					yield put(logoutStart());
				}
			}
		} else {
			if (!!accessToken && !!provider) {
				yield put(checkAccessTokenStart({ accessToken, provider }));
			} else {
				if (!isUserLoading && !!refreshToken) {
					yield put(refreshAccessTokenStart(provider));
				} else {
					yield put(logoutStart());
				}
			}
		}
	} catch (error) {}
}

export function* onCheckUserSession() {
	yield takeLatest(UserActionTypes.CHECK_USER_SESSION, isUserAuthenticated);
}

export function* fetchAllUsers() {
	const company = yield select(selectCurrentUserCompany);
	const query = queryString.stringify({
		fltCompanyId: company.id,
		pageSize: 0,
	});
	try {
		const response = yield axios({
			method: "GET",
			url: `${BASE_URL_API}/users?${query}`,
		});

		if (!response.data.success) {
			throw new Error(response.data.message);
		}

		const allUsers = response.data.records;
		yield put(fetchAllUsersSuccess(allUsers));
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearUserError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(fetchAllUsersFailure(errorMessage));
	}
}

export function* onFetchAllUsers() {
	yield takeLatest(UserActionTypes.FETCH_ALL_USERS_START, fetchAllUsers);
}

export function* getAccessTokenFromCode({ payload }) {
	const { provider, code } = payload;
	try {
		let res;
		switch (provider) {
			case "Microsoft":
				res = yield AzureToken(code);
				break;
			case "Google":
				res = yield GoogleToken(code);
				break;
			case "Facebook":
				res = yield FacebookToken(code);
				break;
			case "Keycloak":
				res = yield KeycloakToken(code);
				break;
			default:
				break;
		}
		let { accessToken, idToken, refreshToken } = res;
		yield put(getAccessTokenSuccess({ accessToken, idToken, provider, refreshToken }));
		yield put(checkAccessTokenStart({ accessToken, provider }));
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearUserError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(getAccessTokenFailure(errorMessage));
	}
}

export function* onGetAccessTokenStart() {
	yield takeLatest(
		UserActionTypes.GET_ACCESS_TOKEN_START,
		getAccessTokenFromCode
	);
}

export function* checkAccessToken({ payload }) {
	const refreshToken = yield select(selectUserRefreshToken);
	const { accessToken, provider } = payload;
	try {
		let response;

		switch (provider) {
			case "Microsoft":
				response = yield AzureUser(accessToken);
				break;
			case "Google":
				response = yield GoogleUser(accessToken);
				break;
			case "Facebook":
				response = yield FacebookUser(accessToken);
				break;
			case "Keycloak":
				response = yield KeycloakUser(accessToken);
				break;
			default:
				break;
		}
		if (!!response && Object.hasOwnProperty.call(response, "error")) {
			if (!!refreshToken) {
				yield put(refreshAccessTokenStart(provider));
			} else {
				console.log(response);
				throw new Error("Failed from check access token");
			}
		} else {
			axios.defaults.headers.common = {
				Authorization: `Bearer ${accessToken}`,
			};

			axios.interceptors.response.use(
				(response) => {
					return response;
				},
				(error) => {
					if (error.response.status === 401) {
						window.location.reload();
					} else {
						return Promise.reject(error);
					}
				}
			);

			yield put(setUserRole(!!response.groups ? response.groups : null));
			yield put(checkAccessTokenSuccess());
			yield put(fetchUserStart(response.sub));
		}
	} catch (error) {
		// const errorMessage =
		// 	(error.response && error.response.data.message) || error.message;
		// const snackbarData = {
		// 	message: errorMessage,
		// 	color: "error",
		// 	place: "bl",
		// 	dispatchActions: [clearUserError],
		// };
		if (!!refreshToken) {
			// HANDLE ERROR CORS
			yield put(refreshAccessTokenStart(provider));
		}

		// yield put(openSnackbar(snackbarData));
		// yield put(checkAccessTokenFailure(errorMessage));
	}
}

export function* onCheckAccessTokenStart() {
	yield takeLatest(
		UserActionTypes.CHECK_ACCESS_TOKEN_START,
		checkAccessToken
	);
}

export function* logout() {
	const provider = yield select(selectUserProvider);
	const idToken = yield select(selectUserIdToken);
	try {
		switch (provider) {
			case "Microsoft":
				break;
			case "Google":
				break;
			case "Facebook":
				break;
			case "Keycloak":
				window.location.href = KeycloakLogout(idToken);
				break;
			default:
				window.location.href = KeycloakLogout(idToken);
				break;
		}

		axios.defaults.headers.common = {
			Authorization: undefined,
		};
		axios.interceptors.response.use(
			(response) => {
				return response;
			},
			(error) => {
				return Promise.reject(error);
			}
		);
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearUserError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(logoutFailure(errorMessage));
	}
}

export function* onLogoutStart() {
	yield takeLatest(UserActionTypes.USER_LOGOUT_START, logout);
}

export function* getNewAccessToken({ payload }) {
	const provider = payload;
	const refreshTokenStore = yield select(selectUserRefreshToken);
	try {
		let response;
		switch (provider) {
			case "Microsoft":
				break;
			case "Google":
				break;
			case "Facebook":
				break;
			case "Keycloak":
				response = yield KeycloakRefreshToken(refreshTokenStore);
				break;
			default:
				throw new Error("Undefined Provider");
		}

		if (!!response && Object.hasOwnProperty.call(response, "error")) {
			console.log(response);
			// throw new Error("Failed to refresh token");
			yield put(logoutStart());
		} else {
			let { access_token, refresh_token } = response;

			axios.defaults.headers.common = {
				Authorization: `Bearer ${access_token}`,
			};
			yield put(
				refreshAccessTokenSuccess({
					accessToken: access_token,
					refreshToken: refresh_token,
				})
			);
			yield put(
				checkAccessTokenStart({ accessToken: access_token, provider })
			);
		}
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearUserError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(logoutStart());
		yield put(refreshAccessTokenFailure(errorMessage));
	}
}

export function* onRefreshToken() {
	yield takeLatest(
		UserActionTypes.REFRESH_ACCESS_TOKEN_START,
		getNewAccessToken
	);
}

export function* fetchUser({ payload }) {
	const userId = payload;
	try {
		const response = yield axios({
			method: "GET",
			url: `${BASE_URL_API}/users/${userId}`,
		});

		if (!response.data.success) {
			throw new Error(response.data.message);
		}

		const user = response.data.record;
		yield put(fetchUserSuccess(user));
		yield put(fetchCurrentUserCompanyStart());
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearUserError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(fetchUserFailure(errorMessage));
	}
}

export function* onFetchUserStart() {
	yield takeLatest(UserActionTypes.FETCH_USER_START, fetchUser);
}

export function* onForgotPassword({ payload }) {
	const { email, history } = payload;
	const data = queryString.stringify(email);
	try {
		const response = yield axios({
			method: "POST",
			url: `${BASE_URL_API}/users/reset-password`,
			data: data,
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
			},
		});
		if (!response.data.success) {
			throw new Error(response.data.message);
		}
		const snackbarData = {
			message: "Kode reset telah dikirim",
			color: "success",
			place: "bl",
			dispatchActions: [clearUserError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(forgotPasswordSuccess());

		history.push({
			pathname: "/forgot-password-success",
		});
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearUserError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(forgotPasswordFailure(errorMessage));
	}
}

export function* onForgotPasswordStart() {
	yield takeLatest(UserActionTypes.FORGOT_PASS_START, onForgotPassword);
}

export function* registerUser({ payload }) {
	const { userInfo, history } = payload;
	const data = {
		address: userInfo.companyAddress,
		addressExt: "",
		areaHa: userInfo.luas,
		city: userInfo.city,
		companyName: userInfo.companyName,
		contactEmail: userInfo.email,
		contactName: userInfo.username,
		contactPhone: userInfo.phoneNumber,
		country: userInfo.country,
		createdBy: userInfo.username,
		createdTime: moment().format("YYYY-MM-DD[T]HH:mm:ssZZ"),
		division: userInfo.division,
		fullname: userInfo.fullName,
		isDeleted: 0,
		numOfClerks: userInfo.kerani,
		numOfHarvesters: userInfo.pemanen,
        numOfSupervisors: userInfo.mandor,
		password: userInfo.password,
		phone: userInfo.companyPhoneNumber,
		position: userInfo.position,
		postalCode: userInfo.zipCode,
		province: userInfo.province,
		syncedStatus: 1,
		updatedBy: userInfo.username,
		updatedTime: moment().format("YYYY-MM-DD[T]HH:mm:ssZZ"),
		url: userInfo.companyWebsite,
		version: 1,
	};

	try {
		const response = yield axios({
			method: "POST",
			url: `${BASE_URL_API}/companies/signup`,
			data: data,
		});
		if (!response.data.success) {
			throw new Error(response.data.message);
		}
		yield put(registerUserSuccess());

		history.push({
			pathname: "/register-success",
			state: { email: userInfo.email },
		});
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearUserError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(registerUserFailure(errorMessage));
	}
}

export function* onRegisterUserStart() {
	yield takeLatest(UserActionTypes.REGISTER_USER_START, registerUser);
}

export function* updateUser({ payload }) {
	const userInfo = payload;
	const currentUser = yield select(selectCurrentUser);
	const company = yield select(selectCurrentUserCompany);

	const fullname = userInfo.fullname;
	const email = userInfo.email;
	const phone = userInfo.phone;
	const division = userInfo.division;
	const position = userInfo.position;
	const versionUser = userInfo.version;

	let userId = currentUser.id;
	let username = currentUser.username;
	let heightCm = currentUser.heightCm;
	let weightKg = currentUser.weightKg;
	let defaultEstateId = currentUser.defaultEstateId;
	let defaultAfdelingId = currentUser.defaultAfdelingId;

	if (userInfo.id) {
		userId = userInfo.id;
		username = userInfo.username;
		heightCm = userInfo.heightCm;
		weightKg = userInfo.weightKg;
		defaultEstateId = userInfo.defaultEstateId;
		defaultAfdelingId = userInfo.defaultAfdelingId;
		
	}

	const data = {
		companyId: company.id,
		companyName: company.companyName,
		createdBy: currentUser.createdBy,
		createdTime: currentUser.createdTime,
		defaultAfdelingId: defaultAfdelingId,
		defaultEstateId: defaultEstateId,
		deletedBy: null,
		deletedTime: null,
		enabled: currentUser.enabled,
		heightCm: heightCm,
		isDeleted: 0,
		isEmailVerified: currentUser.isEmailVerified,
		joinCompanyName: company.companyName,
		photoFileId: currentUser.photoFileId,
		syncedTime: moment().format("YYYY-MM-DD[T]HH:mm:ssZZ"),
		updatedBy: currentUser.username,
		updatedTime: moment().format("YYYY-MM-DD[T]HH:mm:ssZZ"),
		username: username,
		weightKg: weightKg,
		id: userId,
		email: email,
		fullname: fullname,
		syncedStatus: 1,
		phone: phone,
		division: division,
		position: position,
		password: null,
		version: versionUser,
	};

	try {
		// throw new Error(defaultAfdelingId);
		const response = yield axios({
			method: "PUT",
			url: `${BASE_URL_API}/users/${userId}`,
			data: data,
		});

		if (!response.data.success) {
			throw new Error(response.data.message);
		}

		yield put(updateUserSuccess());
		if (userInfo.id) {
			yield put(fetchAllUsersStart());
			if (userInfo.id === currentUser.id) {
				yield put(fetchUserStart(currentUser.id));
			}
		} else {
			yield put(fetchUserStart(currentUser.id));
		}
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearUserError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(updateUserFailure(errorMessage));
	}
}

export function* onUpdateUserStart() {
	yield takeLatest(UserActionTypes.UPDATE_USER_START, updateUser);
}

export function* resetUserPassword({ payload }) {
	const data = queryString.stringify({
		code: payload.code,
		password: payload.password,
		confirmPassword: payload.confirmPassword,
	});

	try {
		const response = yield axios({
			method: "PUT",
			url: `${BASE_URL_API}/users/reset-password`,
			data: data,
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
			},
		});

		if (!response.data.success) {
			throw new Error(response.data.message);
		}

		window.location.href = "/login";
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearUserError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(resetUserPasswordFailure(errorMessage));
	}
}

export function* onResetUserPasswordStart() {
	yield takeLatest(
		UserActionTypes.RESET_USER_PASSWORD_START,
		resetUserPassword
	);
}

export function* insertUser({ payload }) {
	const userInfo = payload;
	const company = yield select(selectCurrentUserCompany);

	try {
		const uuid = yield axios({
			method: "GET",
			url: "https://www.uuidgenerator.net/api/version1",
		});

		const response = yield axios({
			method: "POST",
			url: `${BASE_URL_API}/users`,
			data: {
				id: uuid.data,
				companyId: company.id,
				companyName: company.companyName,
				defaultEstateId: userInfo.defaultEstateId,
				defaultAfdelingId: userInfo.defaultAfdelingId,
				joinCompanyName: company.companyName,
				username: userInfo.username,
				fullname: userInfo.fullname,
				email: userInfo.email,
				phone: userInfo.phone,
				division: userInfo.division,
				position: userInfo.position,
				heightCm: userInfo.heightCm,
				weightKg: userInfo.weightKg,
				version: 1,
				createdTime: moment().format("YYYY-MM-DD[T]HH:mm:ssZZ"),
				updatedTime: moment().format("YYYY-MM-DD[T]HH:mm:ssZZ"),
			},
		});

		if (!response.data.success) {
			throw new Error(response.data.message);
		}

		yield put(insertUserSuccess());
		yield put(fetchAllUsersStart());
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearUserError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(insertUserFailure(errorMessage));
	}
}

export function* onInsertUserStart() {
	yield takeLatest(UserActionTypes.INSERT_USER_START, insertUser);
}

export function* importUsers({ payload }) {
	const importInfo = payload;

	const formData = new FormData();
	formData.append("content", importInfo.file[0]);

	try {
		const response = yield axios({
			method: "POST",
			url: `${BASE_URL_API}/users/import`,
			headers: {
				"Content-Type": "multipart/form-data",
			},
			data: formData,
		});

		if (!response.data.success) {
			throw new Error(response.data.message);
		}

		yield put(importUsersSuccess());
		yield put(fetchAllUsersStart());
		const successInfo = {
			message: "Import User Sukses!",
			color: "success",
			place: "bl",
			dispatchActions: [],
		};
		yield put(openSnackbar(successInfo));
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearUserError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(importUsersFailure(errorMessage));
	}
}

export function* onImportUsersStart() {
	yield takeLatest(UserActionTypes.IMPORT_USERS_START, importUsers);
}

export function* deleteUser({ payload }) {
	const userId = payload;

	try {
		const response = yield axios({
			method: "DELETE",
			url: `${BASE_URL_API}/users/${userId}`,
		});

		if (!response.data.success) {
			throw new Error(response.data.message);
		}

		yield put(deleteUserSuccess());
		yield put(fetchAllUsersStart());
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearUserError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(deleteUserFailure(errorMessage));
	}
}

export function* onDeleteUserStart() {
	yield takeLatest(UserActionTypes.DELETE_USER_START, deleteUser);
}

export function* userSagas() {
	yield all([
		call(onCheckUserSession),
		call(onFetchAllUsers),
		call(onGetAccessTokenStart),
		call(onCheckAccessTokenStart),
		call(onLogoutStart),
		call(onRefreshToken),
		call(onFetchUserStart),
		call(onForgotPasswordStart),
		call(onRegisterUserStart),
		call(onResetUserPasswordStart),
		call(onUpdateUserStart),
		call(onInsertUserStart),
		call(onImportUsersStart),
		call(watchUserChannel),
		call(onDeleteUserStart),
	]);
}
