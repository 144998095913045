import { createSelector } from "reselect";

const selectMember = (state) => state.member;

export const selectMemberPlural = createSelector(
	[selectMember],
	(member) => member.plural
);

export const selectMemberError = createSelector(
	[selectMember],
	(member) => member.error
);

export const selectMemberLoading = createSelector(
	[selectMember],
	(member) => member.loading
);

export const selectMembersTotal = createSelector(
	[selectMemberPlural],
	(members) => members.length
);

export const selectMembersCompanies = createSelector(
	[selectMemberPlural],
	(members) => {
		let tempRes = [];
		for (const member of members) {
			const isExist = tempRes.indexOf(member.companyName) >= 0;

			if (!isExist) {
				tempRes.push(member.companyName);
			}
		}
		return tempRes;
	}
);
