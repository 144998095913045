import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
	icon: {
		[theme.breakpoints.down("xs")]: {
			width: "32px",
			height: "32px",
		},
		
	},
	button: {
		border: "none",
		background: "transparent",
		cursor: "pointer",

		marginLeft: "12px",
		marginRight: "12px",
	},
	line: {
		fill: "none",
		stroke: "rgba(183,149,16,255)",
		strokeWidth: "8",
		transition:
			"stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1), stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1)",
	},
	line1: {
		strokeDasharray: "60 207",
		strokeWidth: "8",
		strokeLinecap: "round",
		"&.opened": {
			strokeDasharray: "90 207",
			strokeDashoffset: "-134",
			strokeWidth: "8",
		},
	},
	line2: {
		strokeDasharray: "60 60",
		strokeWidth: "8",
		strokeLinecap: "round",
		"&.opened": {
			strokeDasharray: "1 60",
			strokeDashoffset: "-30",
			strokeWidth: "8",
		},
	},
	line3: {
		strokeDasharray: "60 207",
		strokeWidth: "8",
		strokeLinecap: "round",
		"&.opened": {
			strokeDasharray: "90 207",
			strokeDashoffset: "-134",
			strokeWidth: "8",
		},
	},
}));
