import { Grid } from "@material-ui/core";
import PillButton from "../components/PillButton/PillButton";

export const tableColumns = (props) => {
  const { handleEditOpenModal, classes, handleOpenConfirmationModal } = props;
  return [
    { title: "Maksud Perjalanan", field: "maksud" },
    { title: "Tujuan Estate", field: "estate" },
    { title: "Tujuan Afdeling", field: "afdeling" },
    { title: "Tujuan Blok", field: "block" },
    { title: "Tujuan TPH", field: "tph" },
    {
      title: "Action",
      field: "",
      headerStyle: {
        width: 300,
        minWidth: 300,
      },
      cellStyle: {
        width: 300,
        minWidth: 300,
      },
      render: (rowData) => {
        return (
          <Grid container>
            <Grid item xs={12} className={classes.buttonContainer}>
              <PillButton
                element="button"
                type="button"
                text="HAPUS"
                backgroundColor="red"
                //onClick={() => handleOpenConfirmationModal(rowData)}
                additionalClasses={[classes.pillButton, classes.dangerButton]}
              />
            </Grid>
          </Grid>
        );
      },
    },
  ];
};
