import RktActionTypes from "./rkt.types";

const INITIAL_STATE = {
	// general
	error: null,
	loading: false,
	allFile: [],
    allImportedFile: [],
    file: null,
    success: null,
    allTemplate:[],
    template:null,
    category:[],
};

const rktReducer = (state = INITIAL_STATE, action) =>{
    switch (action.type){
        case RktActionTypes.INSERT_FILE_START:
        case RktActionTypes.FETCH_ALL_FILE_START:
        case RktActionTypes.IMPORT_FILE_START:
        case RktActionTypes.FETCH_ALL_IMPORTED_FILE_START:
        case RktActionTypes.FETCH_IMPORTED_FILE_BY_ID_START:
        case RktActionTypes.FETCH_ALL_TEMPLATE_START:
        case RktActionTypes.FETCH_ALL_TEMPLATE_CATEGORY_START:
        case RktActionTypes.FETCH_TEMPLATE_BY_ID_START:
            return{
                ...state,
                loading: true
            };
        case RktActionTypes.INSERT_FILE_FAILURE:
        case RktActionTypes.FETCH_ALL_FILE_FAILURE:
        case RktActionTypes.IMPORT_FILE_FAILURE:
        case RktActionTypes.FETCH_ALL_IMPORTED_FILE_FAILURE:
        case RktActionTypes.FETCH_IMPORTED_FILE_BY_ID_FAILURE:
        case RktActionTypes.FETCH_ALL_TEMPLATE_FAILURE:
        case RktActionTypes.FETCH_TEMPLATE_BY_ID_FAILURE:
        case RktActionTypes.FETCH_ALL_TEMPLATE_CATEGORY_FAILURE:
            return{
                ...state,
                error: action.payload,
                loading: false
            };
        case RktActionTypes.INSERT_FILE_SUCCESS:
        // case RktActionTypes.IMPORT_FILE_SUCCESS:
            return{
                ...state,
                loading: false,
            }
        case RktActionTypes.FETCH_ALL_FILE_SUCCESS:
            return{
                ...state,
                allFile: action.payload,
                loading: false
            }
        case RktActionTypes.FETCH_ALL_IMPORTED_FILE_SUCCESS:
            return{
                ...state,
                allImportedFile: action.payload,
                loading: false
            }
        case RktActionTypes.FETCH_IMPORTED_FILE_BY_ID_SUCCESS:
            return{
                ...state,
                file: action.payload,
                loading: false
            }
        case RktActionTypes.IMPORT_FILE_SUCCESS:
            return{
                ...state,
                success: action.payload,
                loading: false,
            }
        case RktActionTypes.FETCH_ALL_TEMPLATE_SUCCESS:
            return{
                ...state,
                loading: false,
                allTemplate: action.payload
            }
        case RktActionTypes.FETCH_TEMPLATE_BY_ID_SUCCESS:
            return{
                ...state,
                loading: false,
                template: action.payload
            }
        case RktActionTypes.FETCH_ALL_TEMPLATE_CATEGORY_SUCCESS:
                return{
                    ...state,
                    loading: false,
                    category: action.payload
                }
        case RktActionTypes.CLEAR_ERROR:
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
}

export default rktReducer;