import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import React from "react";
import { Controller } from "react-hook-form";
import DateFnsUtils from "@date-io/date-fns";
import { ThemeProvider } from "@material-ui/styles";
import { customTheme } from "./CustomDatePicker.styles";

const CustomDatePicker = (props) => {
	const { id, name, control, rules, ...otherProps} = props;
	return (
		<Controller
			control={control}
			name={name}
			rules={{
				...rules,
				validate: {
					value: (value) => {
						return (
							(value instanceof Date && !isNaN(value)) ||
							"Invalid date format"
						);
					},
				},
			}}
			defaultValue={null}
			render={({ field: { ref, ...otherField } }) => {
				return (
					<MuiPickersUtilsProvider utils={DateFnsUtils}>
						<ThemeProvider theme={customTheme}>
							<DatePicker
								variant="inline"
								inputRef={ref}
								{...otherField}
								id={id}
								{...otherProps}
							/>
						</ThemeProvider>
					</MuiPickersUtilsProvider>
				);
			}}
		/>
	);
};

export default CustomDatePicker;
