import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
	pillButton: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		borderRadius: "5px",
		// borderRadius: "78px",
		border: "none",
		fontSize: "14px",
		fontWeight: "600",
		lineHeight: "16px",
		textAlign: "center",
		padding: "15px 20px 15px 20px",
		cursor: "pointer",
		backgroundColor: ({ backgroundColor }) => backgroundColor,
		color: ({ color }) => color,

		"&.disabled": {
			cursor: "not-allowed",
			pointerEvents: "none",

			/*Button disabled - CSS color class*/
			color: "#c0c0c0",
			backgroundColor: "#EBEBEB",
		},
		"& img": {
			width: "25px",
			height: "25px",
			marginRight: "20px",
		},
	},
}));
