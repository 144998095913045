import { Grid, TextField, Paper, CardMedia, CardHeader, Avatar, Divider, Box } from "@material-ui/core";
import React, { useEffect } from "react";
import CustomModal from "../CustomModal/CustomModal";
import PillButton from "../PillButton/PillButton";
import MaskedInputCustom from "../MaskedInputCustom/MaskedInputCustom";
import { useStyles } from "./KirimOverlay.styles";
import TruckPhoto from "../../assets/img/truck.png"
import AvatarPhoto from "../../assets/img/avatar.jpg"
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';

const KirimOverlay = (props) =>{
    const classes = useStyles();
    const {open, onClose} = props;

    const handleClose = () => {
		onClose();
	};

    return (
        <CustomModal
            open={open}
            onClose={handleClose}
            width="35vw"
        >
            {/* <Paper elevation={3} className={classes.paper}> */}
                <Grid container>
                    <Grid item xs={5} className={classes.avatar} container direction="row" alignItems="center" justifyContent="center">
                        <Grid item xs={12} className={classes.vehicleDetail} alignItems="center">
                        <CardMedia
                            component="img"
                            height="150"
                            // widht="100"
                            image= {TruckPhoto}
                            alt="Truck"
                        />
                        </Grid>
                        <Grid item xs={12} className={classes.vehicleDetail} alignItems="center">
                            BK 2768 CW
                        </Grid>
                    </Grid>
                    
                    <Grid item xs={7} className={classes.avatar} container direction="row" alignItems="center" justifyContent="center">
                        <Grid item xs={10} className={classes.avatar}>
                            <CardHeader
                                avatar={
                                    <Avatar src={AvatarPhoto} alt="ava" />
                                }
                                title="Aggnar Otnay"
                                subheader="+62213456789"
                            />
                        </Grid>
                    </Grid>
                    <Divider style={{width:'100%', backgroundColor:"#000000", marginBottom:"10px", marginTop:"20px" }}/>
                    {/* <Box m={1}> */}
                        <Paper
                            component="form"
                            sx={{ display: 'flex', alignItems: 'center'}}
                        >
                            <IconButton type="submit" sx={{ p: '10px' }} aria-label="search">
                                <SearchIcon />
                            </IconButton>
                            <InputBase
                                sx={{ ml: 1, flex: 1 }}
                                placeholder="Cari rute"
                                inputProps={{ 'aria-label': 'Cari rute' }}
                            />	
                        </Paper>
                    {/* </Box> */}
                    <Divider style={{width:'100%', backgroundColor:"#000000", marginBottom:"10px", visibility:'hidden' }}/>
                    <Grid item xs={12} >
                        <Paper elevation={3}>
                            <Grid container>
                                <Grid item xs={5} className={classes.blok} container alignItems="center" justifyContent="center">
                                    <Grid item xs={12} alignItems="center">
                                        BLOK K46 TPH 22
                                    </Grid>
                                </Grid>
                                <Grid item xs={7} className={classes.link} container direction="row" alignItems="center" justifyContent="center">
                                    <Grid item xs={12} >
                                        <a className={classes.link}>BELUM DIAMBIL</a>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Divider style={{width:'100%', backgroundColor:"#000000", marginBottom:"10px", visibility:'hidden' }}/>
                    <Grid item xs={12} >
                        <Paper elevation={3}>
                            <Grid container>
                                <Grid item xs={5} className={classes.blok} container alignItems="center" justifyContent="center">
                                    <Grid item xs={12} alignItems="center">
                                        BLOK K46 TPH 22
                                    </Grid>
                                </Grid>
                                <Grid item xs={7} className={classes.link} container direction="row" alignItems="center" justifyContent="center">
                                    <Grid item xs={12} >
                                        <a className={classes.link}>DIJEMPUT : 15 Sept 2021 11:32</a>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid> 
                    <Divider style={{width:'100%', backgroundColor:"#000000", marginBottom:"10px", visibility:'hidden' }}/>
                    <Grid item xs={12} >
                        <Paper elevation={3}>
                            <Grid container>
                                <Grid item xs={5} className={classes.blok} container alignItems="center" justifyContent="center">
                                    <Grid item xs={12} alignItems="center">
                                        BLOK K46 TPH 22
                                    </Grid>
                                </Grid>
                                <Grid item xs={7} className={classes.link} container direction="row" alignItems="center" justifyContent="center">
                                    <Grid item xs={12} >
                                        <a className={classes.link}>DIJEMPUT : 15 Sept 2021 11:32</a>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid> 
                    <Divider style={{width:'100%', backgroundColor:"#000000", marginBottom:"10px", visibility:'hidden' }}/>
                    <Grid item xs={12} >
                        <Paper elevation={3}>
                            <Grid container>
                                <Grid item xs={5} className={classes.blok} container alignItems="center" justifyContent="center">
                                    <Grid item xs={12} alignItems="center">
                                        BLOK K46 TPH 22
                                    </Grid>
                                </Grid>
                                <Grid item xs={7} className={classes.link} container direction="row" alignItems="center" justifyContent="center">
                                    <Grid item xs={12} >
                                        <a className={classes.link}>DIJEMPUT : 15 Sept 2021 11:32</a>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>                    
                </Grid>
            {/* </Paper> */}
        </CustomModal>
    )
}

export default KirimOverlay;