import React, { lazy, useEffect } from "react";
import { useStyles } from "./TransportTabPanel.styles";
import { Grid, withWidth } from "@material-ui/core";
import { compose } from "redux";
import { CustomTab, CustomTabs } from "../../assets/jss/TabPanel.styles";
import CustomTabPanel from "../CustomTabPanel/CustomTabPanel";
import { a11yProps } from "../../helpers/App.helper";
import VehicleInputTable from "../VehicleInputTable/VehicleInputTable";
import WorkDetailTable from "../WorkDetailTable/WorkDetailTable";
import OperatorDetailTable from "../OperatorDetailTable/OperatorDetailTable";
import ReportTable from "../ReportTable/ReportTable";
import RoutePlanning from "../RoutePlanning/RoutePlanning";
import DashboardTransport from "../DashboardTransport/DashboardTransport";
import RequestTransport from "../RequestTransport/RequestTransport";
const AngkutTable = lazy(() => import("../AngkutTable/AngkutTable"));
const VehiclesTable = lazy(() => import("../VehiclesTable/VehiclesTable"));


const TransportTabPanel = (props) => {
	const { width, index } = props;
	const classes = useStyles();
	const [value, setValue] = React.useState(0);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	useEffect(() => {
		if (!!index) {
			setValue(parseInt(index));
		}
	}, [index]);

	let tabVariant;
	let centered;
	switch (width) {
		case "xl":
		case "lg":
		case "md":
		case "sm":
			tabVariant = "standard";
			centered = false;
			break;
		default:
			centered = false;
			tabVariant = "standard";
			break;
	}

	return (
		<Grid item xs={12} md={12} className={classes.root}>
			<CustomTabs
				value={value}
				onChange={handleChange}
				scrollButtons="auto"
				variant={tabVariant}
				aria-label="userpage tab panel"
				centered={centered}
			>
				<CustomTab
					label="DASHBOARD"
					{...a11yProps({ index: 0, entity: "activities" })}
				/>
				<CustomTab
					label="INPUT KENDARAAN"
					{...a11yProps({ index: 1, entity: "activities" })}
				/>
				<CustomTab
					label="DETAIL PEKERJAAN"
					{...a11yProps({ index: 2, entity: "activities" })}
				/>
				<CustomTab
					label="DETAIL OPERATOR"
					{...a11yProps({ index: 3, entity: "activities" })}
				/>
				<CustomTab
					label="REPORT"
					{...a11yProps({ index: 4, entity: "activities" })}
				/>
				{/* <CustomTab
					label="AKTIVITAS"
					{...a11yProps({ index: 5, entity: "activities" })}
				/>
				<CustomTab
					label="VEHICLE"
					{...a11yProps({ index: 6, entity: "master-data" })}
				/> */}
				<CustomTab
					label="ROUTE PLANNING"
					{...a11yProps({ index: 4, entity: "master-data" })}
				/>
				<CustomTab
					label="REQUEST TRANSPORT"
					{...a11yProps({ index: 5, entity: "master-data" })}
				/>
				
			</CustomTabs>
			<CustomTabPanel value={value} index={0} entity="activities">
				<DashboardTransport />
			</CustomTabPanel>
			<CustomTabPanel value={value} index={1} entity="activities">
				<VehicleInputTable />
			</CustomTabPanel>
			<CustomTabPanel value={value} index={2} entity="activities">
				<WorkDetailTable />
			</CustomTabPanel>
			<CustomTabPanel value={value} index={3} entity="activities">
				<OperatorDetailTable />
			</CustomTabPanel>
			 <CustomTabPanel value={value} index={4} entity="activities">
				<ReportTable />
			</CustomTabPanel>
			{/*<CustomTabPanel value={value} index={5} entity="activities">
				<AngkutTable />
			</CustomTabPanel>
			<CustomTabPanel value={value} index={6} entity="master-data">
				<VehiclesTable />
			</CustomTabPanel> */}
			<CustomTabPanel value={value} index={5} entity="master-data">
				<RoutePlanning />
			</CustomTabPanel>
			<CustomTabPanel value={value} index={6} entity="master-data">
				<RequestTransport />
			</CustomTabPanel>
		</Grid>
	);
};

export default compose(withWidth())(TransportTabPanel);
