import { Grid } from "@material-ui/core";
import moment from "moment";
import PillButton from "../components/PillButton/PillButton";
import { numberWithCommas } from "./App.helper";

export const tableColumns = (props) => {
	const { classes, handleUpdateModalOpen, handleConfirmationModalOpen } =
		props;
	return [
		{
			title: "No. Transaksi",
			field: "",
		},
		{
			title: "Nama Karyawan",
			field: "",
		},
		{
			title: "Posisi",
			field: "",
		},
        {
			title: "Upah (Rp)",
			field: "",
		},
        {
			title: "Premi (Rp)",
			field: "",
		},
        {
			title: "Denda (Rp)",
			field: "",
		},
		{
			title: "Aksi",
			field: "",
			headerStyle: {
				width: 300,
				minWidth: 300,
			},
			cellStyle: {
				width: 300,
				minWidth: 300,
			},
			render: (rowData) => {
				return (
					<Grid container>
						<Grid item xs={6} className={classes.buttonContainer}>
							<PillButton
								element="button"
								type="button"
								text="Ubah"
								backgroundColor="#B9940D"
								onClick={() => handleUpdateModalOpen(rowData)}
								additionalClasses={[classes.pillButton]}
							/>
						</Grid>
						<Grid item xs={6} className={classes.buttonContainer}>
							<PillButton
								element="button"
								type="button"
								text="Hapus"
								backgroundColor="red"
								onClick={() =>
									handleConfirmationModalOpen(rowData)
								}
								additionalClasses={[
									classes.pillButton,
									classes.dangerButton,
								]}
							/>
						</Grid>
					</Grid>
				);
			},
		},
	];
};
