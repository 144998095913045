import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
	selectSnackbarOpen,
	selectSnackbarMessage,
	selectSnackbarPlace,
	selectSnackbarColor,
	selectSnackbarTimer,
	selectSnackbarOnClose,
	selectSnackbarDispatchActions,
} from "../../redux/snackbar/snackbar.selector";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { closeSnackbar } from "../../redux/snackbar/snackbar.action";

const CustomSnackbar = (props) => {
	const {
		open,
		message,
		place,
		color,
		timer,
		closeSnackbar,
		onClose,
		dispatchActions,
		dispatchManyActions,
	} = props;

	const handleOnClose = () => {
		if (!!onClose) {
			onClose();
		}

		if (dispatchActions.length > 0 && !!dispatchActions) {
			dispatchManyActions(dispatchActions);
		}
		closeSnackbar();
	};

	if (!open) return null;
	return (
		<Snackbar
			anchorOrigin={{
				vertical: place.indexOf("t") === -1 ? "bottom" : "top",
				horizontal:
					place.indexOf("l") !== -1
						? "left"
						: place.indexOf("c") !== -1
						? "center"
						: "right",
			}}
			open={open}
			autoHideDuration={timer || 5000}
			onClose={handleOnClose}
		>
			<Alert onClose={handleOnClose} severity={color}>
				{message}
			</Alert>
		</Snackbar>
	);
};

const mapStateToProps = createStructuredSelector({
	open: selectSnackbarOpen,
	message: selectSnackbarMessage,
	place: selectSnackbarPlace,
	color: selectSnackbarColor,
	timer: selectSnackbarTimer,
	onClose: selectSnackbarOnClose,
	dispatchActions: selectSnackbarDispatchActions,
});

const mapDispatchToProps = (dispatch) => ({
	closeSnackbar: () => dispatch(closeSnackbar()),
	dispatchManyActions: (dispatchActions) => {
		for (const dispatchAction of dispatchActions) {
			if (!!dispatchAction) dispatch(dispatchAction());
		}
	},
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomSnackbar);
