import { createSelector } from "reselect";

const selectBlock = (state) => state.block;

export const selectBlockPlural = createSelector(
	[selectBlock],
	(block) => block.plural
);

export const selectBlockError = createSelector(
	[selectBlock],
	(block) => block.error
);

export const selectBlockLoading = createSelector(
	[selectBlock],
	(block) => block.loading
);

export const selectBlockSingular = createSelector(
	[selectBlock],
	(block) => block.singular
);

export const selectActiveBlocksTotal = createSelector(
	[selectBlockPlural],
	(blocks) => blocks.length
);
