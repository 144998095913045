import React from "react";
import { Tab, Tabs } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

export const CustomTab = withStyles((theme) => ({
	root: {
		borderRadius: "5px 5px 0px 0px",
		textTransform: "none",
		fontSize: "16px",
		lineHeight: "16px",
		color: "#333333",
		minHeight: "60px",
		"&:hover": {
			color: "#333333 !important",
			fontWeight: "600",
			opacity: 1,
		},
		"&$selected": {
			color: "#FFFFFF !important",
			fontWeight: "600",
			backgroundColor: "rgba(183,149,16,255)",
		},
		"&:focus": {
			color: "#FFFFFF !important",
			fontWeight: "600",
		},
		[theme.breakpoints.down("sm")]: {
			fontSize: "12px",
			marginRight: "24px",
		},
	},
	wrapper: {
		alignContent: "center",
		flexDirection: "row-reverse",
	},
	selected: {},
}))((props) => <Tab disableRipple {...props} />);

export const CustomTabs = withStyles({
	indicator: {
		display: "none",
		// justifyContent: "center",
		// backgroundColor: "#2E7D32",
	},
})((props) => <Tabs {...props} />);

export const CustomTabVertical = withStyles((theme) => ({
	root: {
		borderRadius: "0px 5px 5px 0px",
		textTransform: "none",
		fontSize: "16px",
		lineHeight: "16px",
		color: "#333333",
		
		"&:hover": {
			color: "#333333 !important",
			fontWeight: "600",
			opacity: 1,
		},
		"&$selected": {
			color: "#FFFFFF !important",
			fontWeight: "600",
			backgroundColor: "rgba(183,149,16,255)",
		},
		"&:focus": {
			color: "#FFFFFF !important",
			fontWeight: "600",
		},
		[theme.breakpoints.down("sm")]: {
			fontSize: "12px",
			marginRight: "24px",
		},
	},
	wrapper: {
		alignContent: "left",
		flexDirection: "row-reverse",
	},
	selected: {},
}))((props) => <Tab disableRipple {...props} />);

export const CustomTabsVertical = withStyles({
	indicator: {
		display: "none",
		// justifyContent: "center",
		// backgroundColor: "#2E7D32",
	},
	root:{
		display: "flex",
		flexDirection: "column",
		left:"0",
		borderRight: 1, 
		borderColor: 'black',
		height:"100%",
	}
})((props) => <Tabs {...props} />);

