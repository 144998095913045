const MapsActionTypes = {
	CLEAR_ERROR: "CLEAR_ERROR",
	// SET GEO_JSON
	SET_GEO_JSON: "SET_GEO_JSON",
	// SET COMPANY
	SET_COMPANY: "SET_COMPANY",
	// SET ESTATE
	SET_ESTATE: "SET_ESTATE",
	// SET AFDELING
	SET_AFDELING: "SET_AFDELING",
	// SET BLOCK
	SET_BLOCK: "SET_BLOCK",
	// SET ACTIVITY
	SET_ACTIVITY: "SET_ACTIVITY",
	// SET START_DATE
	SET_START_DATE: "SET_START_DATE",
	// SET END_DATE
	SET_END_DATE: "SET_END_DATE",
	// RESET FILTER
	RESET_MAPS_FILTER: "RESET_MAPS_FILTER",

	//SET MAPS OF BLOCK
	SET_BLCOK_MAP: "SET_BLOCK_MAP",

	// Fetch COMPANIES
	FETCH_MAP_COMPANIES_START: "FETCH_MAP_COMPANIES_START",
	FETCH_MAP_COMPANIES_SUCCESS: "FETCH_MAP_COMPANIES_SUCCESS",
	FETCH_MAP_COMPANIES_FAILURE: "FETCH_MAP_COMPANIES_FAILURE",
	// Fetch ESTATES
	FETCH_MAP_ESTATES_START: "FETCH_MAP_ESTATES_START",
	FETCH_MAP_ESTATES_SUCCESS: "FETCH_MAP_ESTATES_SUCCESS",
	FETCH_MAP_ESTATES_FAILURE: "FETCH_MAP_ESTATES_FAILURE",
	// Fetch AFDELINGS
	FETCH_MAP_AFDELINGS_START: "FETCH_MAP_AFDELINGS_START",
	FETCH_MAP_AFDELINGS_SUCCESS: "FETCH_MAP_AFDELINGS_SUCCESS",
	FETCH_MAP_AFDELINGS_FAILURE: "FETCH_MAP_AFDELINGS_FAILURE",
	// Fetch BLOCKS
	FETCH_MAP_BLOCKS_START: "FETCH_MAP_BLOCKS_START",
	FETCH_MAP_BLOCKS_SUCCESS: "FETCH_MAP_BLOCKS_SUCCESS",
	FETCH_MAP_BLOCKS_FAILURE: "FETCH_MAP_BLOCKS_FAILURE",
	// Fetch TREES
	FETCH_TREES_START: "FETCH_TREES_START",
	FETCH_TREES_SUCCESS: "FETCH_TREES_SUCCESS",
	FETCH_TREES_FAILURE: "FETCH_TREES_FAILURE",
	// Fetch LAND_SUMMARY
	FETCH_LAND_SUMMARY_START: "FETCH_LAND_SUMMARY_START",
	FETCH_LAND_SUMMARY_SUCCESS: "FETCH_LAND_SUMMARY_SUCCESS",
	FETCH_LAND_SUMMARY_FAILURE: "FETCH_LAND_SUMMARY_FAILURE",
	// FETCH LAND ACTIVITIES
	FETCH_LAND_ACTIVITIES_START: "FETCH_LAND_ACTIVITIES_START",
	FETCH_LAND_ACTIVITIES_SUCCESS: "FETCH_LAND_ACTIVITIES_SUCCESS",
	FETCH_LAND_ACTIVITIES_FAILURE: "FETCH_LAND_ACTIVITIES_FAILURE",
};

export default MapsActionTypes;
