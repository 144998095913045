import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
	buttonContainer: {
		[theme.breakpoints.up("md")]: {
			display: "flex",
			justifyContent: "flex-end",
		},
	},
    paper: {
		position: "absolute",
		backgroundColor: theme.palette.background.paper,
		border: "1px solid #000",
		boxShadow: theme.shadows[5],
		padding: theme.spacing(4, 4, 4),
		top: "160%",
		left: "20%"
	},
	pillButton: {
		color: "white",
		margin: "4px",
		padding: "12px 24px",
		minWidth: "130px",

		[theme.breakpoints.down("sm")]: {
			minWidth: "100px",
		},
	},
    produksiBulananContainer: {
		padding: "12px 24px",
	},
}));
