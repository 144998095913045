import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
	modal: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		overflowY: "scroll",
	},
	closeIcon: {
		position: "absolute",
		right: "28px",
		top: "28px",
		cursor: "pointer",
	},
	modalContentContainer: {
		width: ({ modalWidth }) => modalWidth,
		height: ({ modalHeight }) => modalHeight,
		backgroundColor: "#FFFFFF",
		boxShadow: "0px 1px 8px rgba(112, 126, 141, 0.35)",
		borderRadius: "16px",
		padding: "24px 48px 24px 48px",
		position: "absolute",
		top: "20vh",
		[theme.breakpoints.down("sm")]: {
			width: "90vw !important",
			top: "10vh",
		},
		outline: "none",
	},
	modalTitle: {
		fontSize: "20px",
		lineHeight: "28px",
		fontWeight: "600",
		textAlign: "center",
	},
	modalContent: {
		marginTop: "36px",
		height: "80%",
	},
	modalFooter: {
		marginTop: "24px",
	},
}));
