import { Grid, TextField, Paper, CardMedia, CardHeader, Avatar, Divider, Box } from "@material-ui/core";
import React, { useEffect } from "react";
import CustomModal from "../CustomModal/CustomModal";
import PillButton from "../PillButton/PillButton";
import MaskedInputCustom from "../MaskedInputCustom/MaskedInputCustom";
import { useStyles } from "./DriverAssignOverlay.styles";
import TruckPhoto from "../../assets/img/truck.png"
import AvatarPhoto from "../../assets/img/avatar.jpg"
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';

const DriverAssignOverlay = (props) =>{
    const classes = useStyles();
    const {open, onClose, driverDummy, onSubmit} = props;

    const handleClose = () => {
		onClose();
	};

    const handleSubmitDriver = () => {
        onSubmit();
        onClose();
    }

    return (
        <CustomModal
            open={open}
            onClose={handleClose}
            width="30vw"
        >
            {/* <Paper elevation={3} className={classes.paper}> */}
                <Grid container>
                    <Grid item xs={12} className={classes.avatar} container direction="row" alignItems="left" justifyContent="flex-start">
                        <Grid item xs={5} className={classes.avatar}>
                            <CardHeader
                                avatar={
                                    <Avatar src={AvatarPhoto} alt="ava" />
                                }
                                title="Aggnar Otnay"
                                subheader="+62213456789 
                                NIK 203992"                            
                            />
                        </Grid>
                    </Grid>
                    <Divider style={{width:'100%', backgroundColor:"#000000", marginBottom:"10px", marginTop:"20px" }}/>
                    {/* <Box m={1}> */}
                        <Paper
                            component="form"
                            sx={{ display: 'flex', alignItems: 'center',}}
                        >
                            <IconButton type="submit" sx={{ p: '10px' }} aria-label="search">
                                <SearchIcon />
                            </IconButton>
                            <InputBase
                                sx={{ ml: 1, flex: 1 }}
                                placeholder="Penugasan Sebelumnya"
                                inputProps={{ 'aria-label': 'Penugasan Sebelumnya' }}
                            />	
                        </Paper>
                    {/* </Box> */}
                    <Divider style={{width:'100%', backgroundColor:"#000000", marginBottom:"10px", visibility:'hidden' }}/>
                    <Grid item xs={12} >
                        <Paper elevation={3}>
                            <Grid container direction="column">
                                <Grid item xs={12} className={classes.blok} container alignItems="center" justifyContent="center">
                                    <Grid item xs={12} alignItems="center">
                                        BK 2728 YT 
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} className={classes.link} container direction="row" alignItems="center" justifyContent="center">
                                    <Grid item xs={12} >
                                        28 SEPTEMBER 2021
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid> 
                    <Divider style={{width:'100%', backgroundColor:"#000000", marginBottom:"10px", visibility:'hidden' }}/>
                    <Grid item xs={12} >
                        <Paper elevation={3}>
                            <Grid container direction="column">
                                <Grid item xs={12} className={classes.blok} container alignItems="center" justifyContent="center">
                                    <Grid item xs={12} alignItems="center">
                                        BK 2768 CW
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} className={classes.link} container direction="row" alignItems="center" justifyContent="center">
                                    <Grid item xs={12} >
                                        28 SEPTEMBER 2021 - 3 OKTOBER 2021
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>   
                    <Divider style={{width:'100%', backgroundColor:"#000000", marginBottom:"50px", visibility:'hidden' }}/>
                    <Grid item xs={12} >
                        <Grid container direction="column">
                            <Grid item xs={12} className={classes.blok} container alignItems="center" justifyContent="center">
                                <PillButton
                                    element="button"
                                    // type="submit"
                                    text="+ SET SEBAGAI PENGEMUDI"
                                    backgroundColor="#B9940D"
                                    color="white"
                                    additionalClasses={[classes.pillButton]}
                                    onClick={handleSubmitDriver}
                                />
                            </Grid>
                        </Grid>
                    </Grid>                       
                </Grid>
            {/* </Paper> */}
        </CustomModal>
    )
}

export default DriverAssignOverlay;