export const genders = [
	{
		text: "Laki-Laki",
		value: "MALE",
	},
	{
		text: "Perempuan",
		value: "FEMALE",
	},
];

export const timeFilters = [
	{
		text: "Kemarin",
		value: "yesterday",
	},
	{
		text: "Hari Ini",
		value: "today",
	},
	{
		text: "Minggu Ini",
		value: "weekly",
	},
	{
		text: "Bulan Ini",
		value: "monthly",
	},
];

// format date limited to only these part dd, mm, yyyy, hh, ii, ss, sm, lm
// use moment if need more complex formatting or date/time processing
// sm = short month
// lm = long month
export const formatDate = (date, format, language) => {
	const months = [
		{
			shortEN: "Jan",
			longEN: "January",
			shortID: "Jan",
			longID: "Januari",
		},
		{
			shortEN: "Feb",
			longEN: "February",
			shortID: "Feb",
			longID: "Febuari",
		},
		{
			shortEN: "Mar",
			longEN: "March",
			shortID: "Mar",
			longID: "Maret",
		},
		{
			shortEN: "Apr",
			longEN: "April",
			shortID: "Apr",
			longID: "April",
		},
		{
			shortEN: "May",
			longEN: "May",
			shortID: "Mei",
			longID: "Mei",
		},
		{
			shortEN: "Jun",
			longEN: "June",
			shortID: "Jun",
			longID: "Juni",
		},
		{
			shortEN: "Jul",
			longEN: "July",
			shortID: "Jul",
			longID: "Juli",
		},
		{
			shortEN: "Aug",
			longEN: "August",
			shortID: "Agu",
			longID: "Agustus",
		},
		{
			shortEN: "Sep",
			longEN: "September",
			shortID: "Sep",
			longID: "September",
		},
		{
			shortEN: "Oct",
			longEN: "October",
			shortID: "Okt",
			longID: "Oktober",
		},
		{
			shortEN: "Nov",
			longEN: "November",
			shortID: "Nov",
			longID: "November",
		},
		{
			shortEN: "Dec",
			longEN: "December",
			shortID: "Des",
			longID: "Desember",
		},
	];
	let res = format;
	const parts = {
		dd: () => String(date.getDate()).padStart(2, "0"),
		mm: () => String(date.getMonth() + 1).padStart(2, "0"),
		yyyy: () => date.getFullYear(),
		hh: () => String(date.getHours()).padStart(2, "0"),
		ii: () => String(date.getMinutes()).padStart(2, "0"),
		ss: () => String(date.getSeconds()).padStart(2, "0"),
		sm: () => months[date.getMonth()][`short${language}`],
		lm: () => months[date.getMonth()][`long${language}`],
	};

	const partsName = ["dd", "mm", "yyyy", "hh", "ii", "ss", "sm"];
	let formatRecognized = false;
	partsName.forEach((part) => {
		let needReplacement = format.includes(part);
		if (needReplacement) {
			res = res.replace(part, parts[part]());
			formatRecognized = true;
		}
	});

	return formatRecognized ? res : null;
};

// return to component with number separated by commas (.)
export const numberWithCommas = (n) => {
	const splittedString = n.toString().split(".");

	if (splittedString.length > 1) {
		return `${splittedString[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".")},${
			splittedString[1]
		}`;
	} else {
		return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
	}
};

// return number with padded 0 infront
export const paddedNumber = (n, width, z) => {
	z = z || "0";
	n = n + "";
	return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
};

export const roundNumber = (n, roundValue) => {
	var num = Number(n); // The Number() only visualizes the type and is not needed
	var roundedString = num.toFixed(roundValue);
	var rounded = Number(roundedString);
	return rounded;
};

export const numberWithSuffixOrdinal = (n) => {
	return ["st", "nd", "rd"][((((n + 90) % 100) - 10) % 10) - 1] || "th";
};

export const generateDayChartLabel = (month, year) => {
	const totalDays = new Date(year, month, 0).getDate();

	let res = [];
	for (let i = 0; i < totalDays; i++) {
		res.push(i + 1);
	}
	return res;
};

export const generateDummyData = (month, year) => {
	const totalDays = new Date(year, month, 0).getDate();
	let temp = 2;
	let res = [];
	for (let i = 0; i < totalDays; i++) {
		res.push(temp);
		temp += 2;
	}
	return res;
};

// PROVINCES AND CITIES
export const provinces = [
	{
		name: "Aceh",
		iso_code: "AC",
	},
	{
		name: "Bali",
		iso_code: "BA",
	},
	{
		name: "Banten",
		iso_code: "BT",
	},
	{
		name: "Bengkulu",
		iso_code: "BE",
	},
	{
		name: "DKI Jakarta",
		iso_code: "JK",
	},
	{
		name: "Gorontalo",
		iso_code: "GO",
	},
	{
		name: "Jambi",
		iso_code: "JA",
	},
	{
		name: "Jawa Barat",
		iso_code: "JB",
	},
	{
		name: "Jawa Tengah",
		iso_code: "JT",
	},
	{
		name: "Jawa Timur",
		iso_code: "JI",
	},
	{
		name: "Kalimantan Barat",
		iso_code: "KB",
	},
	{
		name: "Kalimantan Selatan",
		iso_code: "KS",
	},
	{
		name: "Kalimantan Tengah",
		iso_code: "KT",
	},
	{
		name: "Kalimantan Timur",
		iso_code: "KI",
	},
	{
		name: "Kalimantan Utara",
		iso_code: "KU",
	},
	{
		name: "Kepulauan Bangka Belitung",
		iso_code: "BB",
	},
	{
		name: "Kepulauan Riau",
		iso_code: "KR",
	},
	{
		name: "Lampung",
		iso_code: "LA",
	},
	{
		name: "Maluku",
		iso_code: "MA",
	},
	{
		name: "Maluku Utara",
		iso_code: "MU",
	},
	{
		name: "Nusa Tenggara Barat",
		iso_code: "NB",
	},
	{
		name: "Nusa Tenggara Timur",
		iso_code: "NT",
	},
	{
		name: "Papua",
		iso_code: "PA",
	},
	{
		name: "Papua Barat",
		iso_code: "PB",
	},
	{
		name: "Riau",
		iso_code: "RI",
	},
	{
		name: "Sulawesi Barat",
		iso_code: "SR",
	},
	{
		name: "Sulawesi Selatan",
		iso_code: "SN",
	},
	{
		name: "Sulawesi Tengah",
		iso_code: "ST",
	},
	{
		name: "Sulawesi Tenggara",
		iso_code: "SG",
	},
	{
		name: "Sulawesi Utara",
		iso_code: "SA",
	},
	{
		name: "Sumatera Barat",
		iso_code: "SB",
	},
	{
		name: "Sumatera Selatan",
		iso_code: "SS",
	},
	{
		name: "Sumatera Utara",
		iso_code: "SU",
	},
	{
		name: "Yogyakarta",
		iso_code: "YO",
	},
];

export const cities = {
	Aceh: [
		"Kabupaten Simeulue",
		"Kabupaten Aceh Singkil",
		"Kabupaten Aceh Selatan",
		"Kabupaten Aceh Tenggara",
		"Kabupaten Aceh Timur",
		"Kabupaten Aceh Tengah",
		"Kabupaten Aceh Barat",
		"Kabupaten Aceh Besar",
		"Kabupaten Pidie",
		"Kabupaten Bireuen",
		"Kabupaten Aceh Utara",
		"Kabupaten Aceh Barat Daya",
		"Kabupaten Gayo Lues",
		"Kabupaten Aceh Tamiang",
		"Kabupaten Nagan Raya",
		"Kabupaten Aceh Jaya",
		"Kabupaten Bener Meriah",
		"Kabupaten Pidie Jaya",
		"Kota Banda Aceh",
		"Kota Sabang",
		"Kota Langsa",
		"Kota Lhokseumawe",
		"Kota Subulussalam",
	],

	// Bali
	Bali: [
		"Kabupaten Jembrana",
		"Kabupaten Tabanan",
		"Kabupaten Badung",
		"Kabupaten Gianyar",
		"Kabupaten Klungkung",
		"Kabupaten Bangli",
		"Kabupaten Karang Asem",
		"Kabupaten Buleleng",
		"Kota Denpasar",
	],

	// Banten
	Banten: [
		"Kabupaten Pandeglang",
		"Kabupaten Lebak",
		"Kabupaten Tangerang",
		"Kabupaten Serang",
		"Kota Tangerang",
		"Kota Cilegon",
		"Kota Serang",
		"Kota Tangerang Selatan",
	],

	// Bengkulu
	Bengkulu: [
		"Kabupaten Bengkulu Selatan",
		"Kabupaten Rejang Lebong",
		"Kabupaten Bengkulu Utara",
		"Kabupaten Kaur",
		"Kabupaten Seluma",
		"Kabupaten Mukomuko",
		"Kabupaten Lebong",
		"Kabupaten Kepahiang",
		"Kabupaten Bengkulu Tengah",
		"Kota Bengkulu",
	],

	// DKI Jakarta
	"DKI Jakarta": [
		"Kabupaten Kepulauan Seribu",
		"Kota Jakarta Selatan",
		"Kota Jakarta Timur",
		"Kota Jakarta Pusat",
		"Kota Jakarta Barat",
		"Kota Jakarta Utara",
	],

	// Gorontalo
	Gorontalo: [
		"Kabupaten Boalemo",
		"Kabupaten Gorontalo",
		"Kabupaten Pohuwato",
		"Kabupaten Bone Bolango",
		"Kabupaten Gorontalo Utara",
		"Kota Gorontalo",
	],

	// Jambi
	Jambi: [
		"Kabupaten Kerinci",
		"Kabupaten Merangin",
		"Kabupaten Sarolangun",
		"Kabupaten Batang Hari",
		"Kabupaten Muaro Jambi",
		"Kabupaten Tanjung Jabung Timur",
		"Kabupaten Tanjung Jabung Barat",
		"Kabupaten Tebo",
		"Kabupaten Bungo",
		"Kota Jambi",
		"Kota Sungai Penuh",
	],

	// Jawa Barat
	"Jawa Barat": [
		"Kabupaten Bogor",
		"Kabupaten Sukabumi",
		"Kabupaten Cianjur",
		"Kabupaten Bandung",
		"Kabupaten Garut",
		"Kabupaten Tasikmalaya",
		"Kabupaten Ciamis",
		"Kabupaten Kuningan",
		"Kabupaten Cirebon",
		"Kabupaten Majalengka",
		"Kabupaten Sumedang",
		"Kabupaten Indramayu",
		"Kabupaten Subang",
		"Kabupaten Purwakarta",
		"Kabupaten Karawang",
		"Kabupaten Bekasi",
		"Kabupaten Bandung Barat",
		"Kabupaten Pangandaran",
		"Kota Bogor",
		"Kota Sukabumi",
		"Kota Bandung",
		"Kota Cirebon",
		"Kota Bekasi",
		"Kota Depok",
		"Kota Cimahi",
		"Kota Tasikmalaya",
		"Kota Banjar",
	],

	// Jawa Tengah
	"Jawa Tengah": [
		"Kabupaten Cilacap",
		"Kabupaten Banyumas",
		"Kabupaten Purbalingga",
		"Kabupaten Banjarnegara",
		"Kabupaten Kebumen",
		"Kabupaten Purworejo",
		"Kabupaten Wonosobo",
		"Kabupaten Magelang",
		"Kabupaten Boyolali",
		"Kabupaten Klaten",
		"Kabupaten Sukoharjo",
		"Kabupaten Wonogiri",
		"Kabupaten Karanganyar",
		"Kabupaten Sragen",
		"Kabupaten Grobogan",
		"Kabupaten Blora",
		"Kabupaten Rembang",
		"Kabupaten Pati",
		"Kabupaten Kudus",
		"Kabupaten Jepara",
		"Kabupaten Demak",
		"Kabupaten Semarang",
		"Kabupaten Temanggung",
		"Kabupaten Kendal",
		"Kabupaten Batang",
		"Kabupaten Pekalongan",
		"Kabupaten Pemalang",
		"Kabupaten Tegal",
		"Kabupaten Brebes",
		"Kota Magelang",
		"Kota Surakarta",
		"Kota Salatiga",
		"Kota Semarang",
		"Kota Pekalongan",
		"Kota Tegal",
	],

	// Jawa Timur
	"Jawa Timur": [
		"Kabupaten Pacitan",
		"Kabupaten Ponorogo",
		"Kabupaten Trenggalek",
		"Kabupaten Tulungagung",
		"Kabupaten Blitar",
		"Kabupaten Kediri",
		"Kabupaten Malang",
		"Kabupaten Lumajang",
		"Kabupaten Jember",
		"Kabupaten Banyuwangi",
		"Kabupaten Bondowoso",
		"Kabupaten Situbondo",
		"Kabupaten Probolinggo",
		"Kabupaten Pasuruan",
		"Kabupaten Sidoarjo",
		"Kabupaten Mojokerto",
		"Kabupaten Jombang",
		"Kabupaten Nganjuk",
		"Kabupaten Madiun",
		"Kabupaten Magetan",
		"Kabupaten Ngawi",
		"Kabupaten Bojonegoro",
		"Kabupaten Tuban",
		"Kabupaten Lamongan",
		"Kabupaten Gresik",
		"Kabupaten Bangkalan",
		"Kabupaten Sampang",
		"Kabupaten Pamekasan",
		"Kabupaten Sumenep",
		"Kota Kediri",
		"Kota Blitar",
		"Kota Malang",
		"Kota Probolinggo",
		"Kota Pasuruan",
		"Kota Mojokerto",
		"Kota Madiun",
		"Kota Surabaya",
		"Kota Batu",
	],

	// Kalimantan Barat
	"Kalimantan Barat": [
		"Kabupaten Sambas",
		"Kabupaten Bengkayang",
		"Kabupaten Landak",
		"Kabupaten Mempawah",
		"Kabupaten Sanggau",
		"Kabupaten Ketapang",
		"Kabupaten Sintang",
		"Kabupaten Kapuas Hulu",
		"Kabupaten Sekadau",
		"Kabupaten Melawi",
		"Kabupaten Kayong Utara",
		"Kabupaten Kubu Raya",
		"Kota Pontianak",
		"Kota Singkawang",
	],

	// Kalimantan Selatan
	"Kalimantan Selatan": [
		"Kabupaten Tanah Laut",
		"Kabupaten Kota Baru",
		"Kabupaten Banjar",
		"Kabupaten Barito Kuala",
		"Kabupaten Tapin",
		"Kabupaten Hulu Sungai Selatan",
		"Kabupaten Hulu Sungai Tengah",
		"Kabupaten Hulu Sungai Utara",
		"Kabupaten Tabalong",
		"Kabupaten Tanah Bumbu",
		"Kabupaten Balangan",
		"Kota Banjarmasin",
		"Kota Banjar Baru",
	],

	// Kalimantan Tengah
	"Kalimantan Tengah": [
		"Kabupaten Kotawaringin Barat",
		"Kabupaten Kotawaringin Timur",
		"Kabupaten Kapuas",
		"Kabupaten Barito Selatan",
		"Kabupaten Barito Utara",
		"Kabupaten Sukamara",
		"Kabupaten Lamandau",
		"Kabupaten Seruyan",
		"Kabupaten Katingan",
		"Kabupaten Pulang Pisau",
		"Kabupaten Gunung Mas",
		"Kabupaten Barito Timur",
		"Kabupaten Murung Raya",
		"Kota Palangka Raya",
	],

	// Kalimantan Timur
	"Kalimantan Timur": [
		"Kabupaten Paser",
		"Kabupaten Kutai Barat",
		"Kabupaten Kutai Kartanegara",
		"Kabupaten Kutai Timur",
		"Kabupaten Berau",
		"Kabupaten Penajam Paser Utara",
		"Kabupaten Mahakam Hulu",
		"Kota Balikpapan",
		"Kota Samarinda",
		"Kota Bontang",
	],

	// Kalimantan Utara
	"Kalimantan Utara": [
		"Kabupaten Malinau",
		"Kabupaten Bulungan",
		"Kabupaten Tana Tidung",
		"Kabupaten Nunukan",
		"Kota Tarakan",
	],

	// Kepulauan Bangka Belitung
	"Kepulauan Bangka Belitung": [
		"Kabupaten Bangka",
		"Kabupaten Belitung",
		"Kabupaten Bangka Barat",
		"Kabupaten Bangka Tengah",
		"Kabupaten Bangka Selatan",
		"Kabupaten Belitung Timur",
		"Kota Pangkal Pinang",
	],

	// Kepulauan Riau
	"Kepulauan Riau": [
		"Kabupaten Karimun",
		"Kabupaten Bintan",
		"Kabupaten Natuna",
		"Kabupaten Lingga",
		"Kabupaten Kepulauan Anambas",
		"Kota Batam",
		"Kota Tanjung Pinang",
	],

	// Lampung
	Lampung: [
		"Kabupaten Lampung Barat",
		"Kabupaten Tanggamus",
		"Kabupaten Lampung Selatan",
		"Kabupaten Lampung Timur",
		"Kabupaten Lampung Tengah",
		"Kabupaten Lampung Utara",
		"Kabupaten Way Kanan",
		"Kabupaten Tulangbawang",
		"Kabupaten Pesawaran",
		"Kabupaten Pringsewu",
		"Kabupaten Mesuji",
		"Kabupaten Tulang Bawang Barat",
		"Kabupaten Pesisir Barat",
		"Kota Bandar Lampung",
		"Kota Metro",
	],

	// Maluku
	Maluku: [
		"Kabupaten Maluku Tenggara Barat",
		"Kabupaten Maluku Tenggara",
		"Kabupaten Maluku Tengah",
		"Kabupaten Buru",
		"Kabupaten Kepulauan Aru",
		"Kabupaten Seram Bagian Barat",
		"Kabupaten Seram Bagian Timur",
		"Kabupaten Maluku Barat Daya",
		"Kabupaten Buru Selatan",
		"Kota Ambon",
		"Kota Tual",
	],

	// Maluku Utara
	"Maluku Utara": [
		"Kabupaten Halmahera Barat",
		"Kabupaten Halmahera Tengah",
		"Kabupaten Kepulauan Sula",
		"Kabupaten Halmahera Selatan",
		"Kabupaten Halmahera Utara",
		"Kabupaten Halmahera Timur",
		"Kabupaten Pulau Morotai",
		"Kabupaten Pulau Taliabu",
		"Kota Ternate",
		"Kota Tidore Kepulauan",
	],

	// Nusa Tenggara Barat
	"Nusa Tenggara Barat": [
		"Kabupaten Lombok Barat",
		"Kabupaten Lombok Tengah",
		"Kabupaten Lombok Timur",
		"Kabupaten Sumbawa",
		"Kabupaten Dompu",
		"Kabupaten Bima",
		"Kabupaten Sumbawa Barat",
		"Kabupaten Lombok Utara",
		"Kota Mataram",
		"Kota Bima",
	],

	// Nusa Tenggara Timur
	"Nusa Tenggara Timur": [
		"Kabupaten Sumba Barat",
		"Kabupaten Sumba Timur",
		"Kabupaten Kupang",
		"Kabupaten Timor Tengah Selatan",
		"Kabupaten Timor Tengah Utara",
		"Kabupaten Belu",
		"Kabupaten Alor",
		"Kabupaten Lembata",
		"Kabupaten Flores Timur",
		"Kabupaten Sikka",
		"Kabupaten Ende",
		"Kabupaten Ngada",
		"Kabupaten Manggarai",
		"Kabupaten Rote Ndao",
		"Kabupaten Manggarai Barat",
		"Kabupaten Sumba Tengah",
		"Kabupaten Sumba Barat Daya",
		"Kabupaten Nagekeo",
		"Kabupaten Manggarai Timur",
		"Kabupaten Sabu Raijua",
		"Kabupaten Malaka",
		"Kota Kupang",
	],

	// Papua
	Papua: [
		"Kabupaten Merauke",
		"Kabupaten Jayawijaya",
		"Kabupaten Jayapura",
		"Kabupaten Nabire",
		"Kabupaten Kepulauan Yapen",
		"Kabupaten Biak Numfor",
		"Kabupaten Paniai",
		"Kabupaten Puncak Jaya",
		"Kabupaten Mimika",
		"Kabupaten Boven Digoel",
		"Kabupaten Mappi",
		"Kabupaten Asmat",
		"Kabupaten Yahukimo",
		"Kabupaten Pegunungan Bintang",
		"Kabupaten Tolikara",
		"Kabupaten Sarmi",
		"Kabupaten Keerom",
		"Kabupaten Waropen",
		"Kabupaten Supiori",
		"Kabupaten Mamberamo Raya",
		"Kabupaten Nduga",
		"Kabupaten Lanny Jaya",
		"Kabupaten Mamberamo Tengah",
		"Kabupaten Yalimo",
		"Kabupaten Puncak",
		"Kabupaten Dogiyai",
		"Kabupaten Intan Jaya",
		"Kabupaten Deiyai",
		"Kota Jayapura",
	],

	// Papua Barat
	"Papua Barat": [
		"Kabupaten Fakfak",
		"Kabupaten Kaimana",
		"Kabupaten Teluk Wondama",
		"Kabupaten Teluk Bintuni",
		"Kabupaten Manokwari",
		"Kabupaten Sorong Selatan",
		"Kabupaten Sorong",
		"Kabupaten Raja Ampat",
		"Kabupaten Tambrauw",
		"Kabupaten Maybrat",
		"Kabupaten Manokwari Selatan",
		"Kabupaten Pegunungan Arfak",
		"Kota Sorong",
	],

	// Riau
	Riau: [
		"Kabupaten Kuantan Singingi",
		"Kabupaten Indragiri Hulu",
		"Kabupaten Indragiri Hilir",
		"Kabupaten Pelalawan",
		"Kabupaten Siak",
		"Kabupaten Kampar",
		"Kabupaten Rokan Hulu",
		"Kabupaten Bengkalis",
		"Kabupaten Rokan Hilir",
		"Kabupaten Kepulauan Meranti",
		"Kota Pekanbaru",
		"Kota Dumai",
	],

	// Sulawesi Barat
	"Sulawesi Barat": [
		"Kabupaten Majene",
		"Kabupaten Polewali Mandar",
		"Kabupaten Mamasa",
		"Kabupaten Mamuju",
		"Kabupaten Mamuju Utara",
		"Kabupaten Mamuju Tengah",
	],

	// Sulawesi Selatan
	"Sulawesi Selatan": [
		"Kabupaten Kepulauan Selayar",
		"Kabupaten Bulukumba",
		"Kabupaten Bantaeng",
		"Kabupaten Jeneponto",
		"Kabupaten Takalar",
		"Kabupaten Gowa",
		"Kabupaten Sinjai",
		"Kabupaten Maros",
		"Kabupaten Pangkajene Dan Kepulauan",
		"Kabupaten Barru",
		"Kabupaten Bone",
		"Kabupaten Soppeng",
		"Kabupaten Wajo",
		"Kabupaten Sidenreng Rappang",
		"Kabupaten Pinrang",
		"Kabupaten Enrekang",
		"Kabupaten Luwu",
		"Kabupaten Tana Toraja",
		"Kabupaten Luwu Utara",
		"Kabupaten Luwu Timur",
		"Kabupaten Toraja Utara",
		"Kota Makassar",
		"Kota Parepare",
		"Kota Palopo",
	],

	// Sulawesi Tengah
	"Sulawesi Tengah": [
		"Kabupaten Banggai Kepulauan",
		"Kabupaten Banggai",
		"Kabupaten Morowali",
		"Kabupaten Poso",
		"Kabupaten Donggala",
		"Kabupaten Toli-toli",
		"Kabupaten Buol",
		"Kabupaten Parigi Moutong",
		"Kabupaten Tojo Una-una",
		"Kabupaten Sigi",
		"Kabupaten Banggai Laut",
		"Kabupaten Morowali Utara",
		"Kota Palu",
	],

	// Sulawesi Tenggara
	"Sulawesi Tenggara": [
		"Kabupaten Buton",
		"Kabupaten Muna",
		"Kabupaten Konawe",
		"Kabupaten Kolaka",
		"Kabupaten Konawe Selatan",
		"Kabupaten Bombana",
		"Kabupaten Wakatobi",
		"Kabupaten Kolaka Utara",
		"Kabupaten Buton Utara",
		"Kabupaten Konawe Utara",
		"Kabupaten Kolaka Timur",
		"Kabupaten Konawe Kepulauan",
		"Kabupaten Muna Barat",
		"Kabupaten Buton Tengah",
		"Kabupaten Buton Selatan",
		"Kota Kendari",
		"Kota Baubau",
	],

	// Sulawesi Utara
	"Sulawesi Utara": [
		"Kabupaten Bolaang Mongondow",
		"Kabupaten Minahasa",
		"Kabupaten Kepulauan Sangihe",
		"Kabupaten Kepulauan Talaud",
		"Kabupaten Minahasa Selatan",
		"Kabupaten Minahasa Utara",
		"Kabupaten Bolaang Mongondow Utara",
		"Kabupaten Siau Tagulandang Biaro",
		"Kabupaten Minahasa Tenggara",
		"Kabupaten Bolaang Mongondow Selatan",
		"Kabupaten Bolaang Mongondow Timur",
		"Kota Manado",
		"Kota Bitung",
		"Kota Tomohon",
		"Kota Kotamobagu",
	],

	// Sumatera Barat
	"Sumatera Barat": [
		"Kabupaten Kepulauan Mentawai",
		"Kabupaten Pesisir Selatan",
		"Kabupaten Solok",
		"Kabupaten Sijunjung",
		"Kabupaten Tanah Datar",
		"Kabupaten Padang Pariaman",
		"Kabupaten Agam",
		"Kabupaten Lima Puluh Kota",
		"Kabupaten Pasaman",
		"Kabupaten Solok Selatan",
		"Kabupaten Dharmasraya",
		"Kabupaten Pasaman Barat",
		"Kota Padang",
		"Kota Solok",
		"Kota Sawah Lunto",
		"Kota Padang Panjang",
		"Kota Bukittinggi",
		"Kota Payakumbuh",
		"Kota Pariaman",
	],

	// Sumatera Selatan
	"Sumatera Selatan": [
		"Kabupaten Ogan Komering Ulu",
		"Kabupaten Ogan Komering Ilir",
		"Kabupaten Muara Enim",
		"Kabupaten Lahat",
		"Kabupaten Musi Rawas",
		"Kabupaten Musi Banyuasin",
		"Kabupaten Banyu Asin",
		"Kabupaten Ogan Komering Ulu Selatan",
		"Kabupaten Ogan Komering Ulu Timur",
		"Kabupaten Ogan Ilir",
		"Kabupaten Empat Lawang",
		"Kabupaten Penukal Abab Lematang Ilir",
		"Kabupaten Musi Rawas Utara",
		"Kota Palembang",
		"Kota Prabumulih",
		"Kota Pagar Alam",
		"Kota Lubuklinggau",
	],

	// Sumatera Utara
	"Sumatera Utara": [
		"Kabupaten Nias",
		"Kabupaten Mandailing Natal",
		"Kabupaten Tapanuli Selatan",
		"Kabupaten Tapanuli Tengah",
		"Kabupaten Tapanuli Utara",
		"Kabupaten Toba Samosir",
		"Kabupaten Labuhan Batu",
		"Kabupaten Asahan",
		"Kabupaten Simalungun",
		"Kabupaten Dairi",
		"Kabupaten Karo",
		"Kabupaten Deli Serdang",
		"Kabupaten Langkat",
		"Kabupaten Nias Selatan",
		"Kabupaten Humbang Hasundutan",
		"Kabupaten Pakpak Bharat",
		"Kabupaten Samosir",
		"Kabupaten Serdang Bedagai",
		"Kabupaten Batu Bara",
		"Kabupaten Padang Lawas Utara",
		"Kabupaten Padang Lawas",
		"Kabupaten Labuhan Batu Selatan",
		"Kabupaten Labuhan Batu Utara",
		"Kabupaten Nias Utara",
		"Kabupaten Nias Barat",
		"Kota Sibolga",
		"Kota Tanjung Balai",
		"Kota Pematang Siantar",
		"Kota Tebing Tinggi",
		"Kota Medan",
		"Kota Binjai",
		"Kota Padangsidimpuan",
		"Kota Gunungsitoli",
	],

	// Yogyakarta
	Yogyakarta: [
		"Kabupaten Kulon Progo",
		"Kabupaten Bantul",
		"Kabupaten Gunung Kidul",
		"Kabupaten Sleman",
		"Kota Yogyakarta",
	],
};

export const a11yProps = ({ entity, index }) => {
	return {
		id: `${entity}-tab-${index}`,
		"aria-controls": `${entity}-tabpanel-${index}`,
	};
};

export const masterActivities = [
	{ text: "Panen", value: "harvesting" },
	{ text: "Pemupukan", value: "fertilizing" },
	{ text: "Chemist", value: "chemist" },
];
