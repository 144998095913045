import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
	estateContainer: {
		// paddingTop: "24px",
		[theme.breakpoints.down("sm")]: {
			paddingLeft: 0,
			paddingRight: 0,
		},
	},
	selectContainer: {
		minWidth: "200px",
		marginTop: "12px",
		marginBottom: "12px",
	},
	buttonContainer: {
		[theme.breakpoints.up("md")]: {
			display: "flex",
			justifyContent: "flex-end",
		},
	},
	paper: {
		position: "absolute",
		backgroundColor: theme.palette.background.paper,
		border: "1px solid #000",
		boxShadow: theme.shadows[5],
		padding: theme.spacing(4, 4, 4),
		top: "30%",
		left: "10%",
	},
	sectionTitle: {
		textAlign: "center",
		fontWeight: "600",
		marginTop: "24px",
	},
	pillButtonOnTable: {
		marginLeft: "12px",
	},
	buttonContainer: {
		display: "flex",
		justifyContent: "flex-end",
	},
	pillButton: {
		color: "black",
		margin: "4px",
		padding: "12px 24px",
		minWidth: "130px",

		[theme.breakpoints.down("sm")]: {
			minWidth: "100px",
		},
	},
	dangerButton: {
		background: theme.colors.dangerButton,
	},
}));
