import { Grid, Paper, Button, TextField, Typography, Box, Divider, CardMedia, CardHeader, Avatar } from "@material-ui/core";
import MaterialTable from "material-table";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { tableColumns } from "../../helpers/WorkDetailTable.helper";
import CustomModal from "../CustomModal/CustomModal";
import CustomSelect from "../CustomSelect/CustomSelect";
import { useStyles } from "./DriverAssign.styles";
import PillButton from "../PillButton/PillButton";
import { selectCurrentUserCompany } from "../../redux/company/company.selector";
import {
	deleteEstateStart,
	fetchAllEstatesStart,
} from "../../redux/estate/estate.action";
import {
	selectAllEstates,
	selectEstateLoading,
} from "../../redux/estate/estate.selector";
import { selectMembersCompanies } from "../../redux/member/member.selector";
import InputWorkDetailModal from "../InputWorkDetailModal/InputWorkDetailModal";
import UpdateWorkDetailModal from "../UpdateWorkDetailModal/UpdateWorkDetailModal";
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import { Fragment } from "react";
import TruckPhoto from "../../assets/img/truck.png"
import AvatarPhoto from "../../assets/img/avatar.jpg"
import NoDriver from "../../assets/img/no_driver.png"
import KirimOverlay from "../KirimOverlay/KirimOverlay";
import { AddBox } from "@material-ui/icons";
import DriverAssignOverlay from "../DriverAssignOverlay/DriverAssignOverlay";
import CloseIcon from '@mui/icons-material/Close';

const optionBaris = {text: "All", value:"All"};

const DriverAssign = (props) => {
	const classes = useStyles();

	const {
		company,
		// activityPlan,
		// fetchActivitiesPlanStart,
		// fetchBlocksStart,
		// fetchBlocksByIdStart,
		blocks,
		// blockSingle,
		// isBlockLoading,
		// isVehicleLoading,
		// vehicle,
		// fetchAllVehicleStart,
		// createRoutePlanStart,
		openSnackbar,
	} = props;


	const {register, handleSubmit, control, watch, setValue} = useForm();
	const [myArray, setMyArray] = useState([optionBaris]);
	const planDate = watch("planDate",null);
	const block = watch("block", "");
	const activity = watch("activityPlan", "");
	const truck = watch("vehicle", "");
	const tph = watch("tph", "");

    const opsiList = [{ id: 'a', value: 'a', name: 'Pengangkutan Buah' },
    { id: 'b', value: 'b', name: 'Pengangkutan Pupuk' }]

	const opsiBlok = [
	{ id: 'G1', value: 'G1', name: 'G1' },
    { id: 'G2', value: 'G2', name: 'G2' },
    { id: 'G3', value: 'G3', name: 'G3' },
	{ id: 'G4', value: 'G4', name: 'G4' },
	{ id: 'G5', value: 'G5', name: 'G5' },
	{ id: 'G6', value: 'G6', name: 'G6' }
	]

	const opsiTruk = [
		{ id: 'G1', value: 'G1', name: 'B 0234 XXX' },
		{ id: 'G2', value: 'G2', name: 'B 0034 ZZZ' },
	]

	const opsiTPH = [
		{ id: 'G1', value: 'G1', name: 'All' },
		{ id: 'G2', value: 'G2', name: '1' },
		{ id: 'G3', value: 'G3', name: '2' },
		{ id: 'G4', value: 'G4', name: '3' },
		]

	const tphDropdown =() =>{
		
		var arr = [optionBaris];
		// for (var i=1; i <= blockSingle.tphCount; i++) {
		// 	//optionBaris.push({text: i.toString(),value: i.toString()});
		// 	arr.push({text: i.toString(),value: i.toString()});
		// }
		setMyArray(arr);
	};

	const resetAll =() =>{
		setValue("planDate", null);
		setValue("block", "");
		setValue("activityPlan", "");
		setValue("vehicle", "");
		setValue("tph", "");
	}

	// let driverDummy = ["A"]
	let empDummy = ["C", "D", "E"]

	const [isOverlayOpen, setOverlayOpen] = useState(false)
	const [driverDummy, setDriverDummy] = useState([])

	const handleSubmitDriver = () =>
	{	
		setDriverDummy([...driverDummy, ...["A"]])
		// console.log('driverdummy', driverDummy)
	}

	const handleDeleteDriver = () =>
	{	
		setDriverDummy([])
		// console.log('driverdummy', driverDummy)
	}

	const handleOverlayClose = () => {
		setOverlayOpen(false)
	}

	const handleOverlayOpen = () => {
		setOverlayOpen(true)
	}

	return (
		<Grid
			container
			// className={classes.produksiBulananContainer}
			spacing={2}		
		>
			
		<Grid
			item xs={12}
			container
			direction="row"
			justifyContent="center"
			alignItems="center"
		>
			<DriverAssignOverlay
				open={isOverlayOpen}
				onClose={handleOverlayClose}
				driverDummy={driverDummy}
				onSubmit={handleSubmitDriver}
			/>

			<Box m={2}>
				<Typography variant="h6">
					Penetapan Pengemudi dan Asisten
				</Typography>
			</Box>

			<Grid
				item xs={12}
				container
				direction="row"
				// justifyContent="flex-start"
				// alignItems="center"
			>
				{/* <Box m={2}> */}
					{/* <Paper elevation={3} className={classes.paper}> */}
						<Grid container>
							<Grid item xs={2} className={classes.vehicle} container direction="row" alignItems="left" justifyContent="center">
								<Box >
									<CardMedia
										component="img"
										height="150"
										// widht="100"
										image= {TruckPhoto}
										alt="Truck"
									/>
								</Box>
							</Grid>
							<Grid item xs={2} className={classes.vehicle} container direction="column" alignItems="left" justifyContent="center">
								<Box m={0.5}>
									BK 2768 CW
								</Box>
								<Box m={0.5} >
									DUMP TRUCK
								</Box>
							</Grid>
							
							<Grid item xs={2} className={classes.vehicle} container direction="column" alignItems="right" justifyContent="center">
								<Box m={0.5} >
									ODOMETER
								</Box>
								<Box m={0.5} >
									SERVIS DALAM (KM)
								</Box>
							</Grid>

							<Grid item xs={2} className={classes.vehicle} container direction="column" alignItems="left" justifyContent="center">
								<Box m={0.5}>
									: 567223 KM
								</Box>
								<Box m={0.5} >
									: 2431 KM
								</Box>
							</Grid>

							<Grid item xs={2} className={classes.vehicle} container direction="row" alignItems="center" justifyContent="center">
								<Box m={0.5}>
									VALID STNK
								</Box>
							</Grid>

							<Grid item xs={1} className={classes.vehicle} container direction="row" alignItems="center" justifyContent="center">
								<Box m={0.5}>
									: NOV 2021
								</Box>
							</Grid>
						</Grid>
					{/* </Paper> */}
				{/* </Box> */}
			</Grid>

			<Divider variant="middle" style={{width:'100%', backgroundColor:"#000000", marginBottom:"20px"}}/>

			<Grid
				item xs={12}
				container
				direction="row"
				justifyContent="center"
				alignItems="center"
			>
				{driverDummy.map((_) => {
					return (
						<Fragment key={"frag"+_}>
							<Box m={2}>
								<Paper elevation={3} className={classes.paper}>
									<Grid container >
										{/* <Grid item xs={12} container direction="row" alignItems="right" justifyContent="flex-end" style={{marginRight:"5px", marginTop:"5px"}}>
											
										</Grid> */}
										<Grid item xs={12} className={classes.avatar} container direction="row" alignItems="center" justifyContent="center">
											<Grid item xs={10} className={classes.avatar}>
												<CardHeader
													avatar={
														<Avatar src={AvatarPhoto} alt="ava"/>
													}
													title="Aggnar Otnay"
													subheader="+62213456789 Pengemudi"
													// sx={{align:"center"}}
												/>
											</Grid>
											<Grid item xs={2} className={classes.close}>
												<CloseIcon
													onClick={handleDeleteDriver}
												/>
											</Grid>
										</Grid>
									</Grid>
								</Paper>
							</Box>					
						</Fragment>
					);
				})}
				
			</Grid>

			<Divider variant="middle" style={{width:'100%', backgroundColor:"#000000", marginBottom:"20px"}}/>

			<Paper
				component="form"
				sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400}}
			>
				<IconButton type="submit" sx={{ p: '10px' }} aria-label="search">
					<SearchIcon />
				</IconButton>
				<InputBase
					sx={{ ml: 1, flex: 1 }}
					placeholder="Cari nama employee, nomor telepon ataui NIK"
					inputProps={{ 'aria-label': 'Cari nama employee, nomor telepon ataui NIK' }}
				/>	
			</Paper>

			<Grid
				item xs={12}
				container
				direction="row"
				justifyContent="flex-start"
				alignItems="center"
			>
				{empDummy.map((_) => {
					return (
						<Fragment key={"frag"+_}>
							<Box m={2}>
								<Paper elevation={3} className={classes.paper} onClick={handleOverlayOpen}>
									<Grid container >
										<Grid item xs={12} className={classes.avatar} container direction="row" alignItems="center" justifyContent="center">
											<Grid item xs={10} className={classes.avatar}>
												<CardHeader
													avatar={
														<Avatar src={AvatarPhoto} alt="ava"/>
													}
													title="Aggnar Otnay"
													subheader="+62213456789"
													// sx={{align:"center"}}
												/>
											</Grid>
										</Grid>
									</Grid>
								</Paper>
							</Box>					
						</Fragment>
					);
				})}
				
			</Grid>
		</Grid>
			{/* </Grid> */}
		</Grid>
		);
};

// const mapStateToProps = createStructuredSelector({
// 	company: selectCurrentUserCompany,
// 	estates: selectAllEstates,
// 	isEstateLoading: selectEstateLoading,
// });

// const mapDispatchToProps = (dispatch) => ({
// 	fetchAllEstatesStart: (companyId) =>
// 		dispatch(fetchAllEstatesStart(companyId)),
// 	deleteEstateStart: (estateId) => dispatch(deleteEstateStart(estateId)),
// });

export default DriverAssign;
