import { createSelector } from "reselect";

const selectVehicleType = (state) => state.vehicleType;

export const selectVehicleTypeSingular = createSelector(
	[selectVehicleType],
	(vehicleType) => vehicleType.singular
);

export const selectAllVehicleType = createSelector(
	[selectVehicleType],
	(vehicleType) => vehicleType.allVehicleType
);

export const selectVehicleTypeError = createSelector(
	[selectVehicleType],
	(vehicleType) => vehicleType.error
);

export const selectVehicleTypeLoading = createSelector(
	[selectVehicleType],
	(vehicleType) => vehicleType.loading
);
