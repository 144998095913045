import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
	footerContainer: {
		background: "rgba(183,149,16,255)",
		borderRadius: "0px 15px 0px 0px",
		width: "100%",
		height: "40px",
		position: "absolute",
		bottom: "0",
	},
}));
