import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
	buttonContainer: {
		[theme.breakpoints.up("md")]: {
			display: "flex",
			justifyContent: "flex-end",
		},
	},
    paper: {
		// position: "absolute",
		// backgroundColor: theme.palette.background.paper,
		// border: "1px solid #000",
		// boxShadow: theme.shadows[5],
		// padding: theme.spacing(4, 4, 4),
		// top: "160%",
		// left: "20%"
		// minWidth: "500px"
		width: "250px"
	},
	pillButton: {
		color: "white",
		margin: "4px",
		padding: "12px 24px",
		minWidth: "130px",

		[theme.breakpoints.down("sm")]: {
			minWidth: "100px",
		},
	},
    produksiBulananContainer: {
		padding: "12px 24px",
	},
	cardTitle: {
		borderRadius: "0 0 4px 4px",
		padding: "12px 24px",
		background: theme.colors.cardTitle,
		color: "black",
		fontSize: "18px",
		fontWeight: "600",
		textAlign: "center",
	  },
	vehicleChartContainer: {
		padding: "12px 24px ",
		textAlign: "center",
		minWidth: "100px"
	  },
	frequencyChartContainer: {
		padding: "12px 24px ",
		textAlign: "center",
		minWidth: "500px"
	  },
	chartContainer: {
		justify: "center",
		direction: "row",
		alignItems: "center"
	},
	vehicleDetail: {
		// margin: "20px",
		fontSize: "13px",
		fontWeight: "600",
		// padding: "5px",
		textAlign: "center",
	},
	avatar: {
		// margin: "20px",
		fontSize: "15px",
		fontWeight: "600",
		// padding: "5px",
		textAlign: "left",
		// minWidth: "100px"
	},
	close: {
		marginTop: "-30px",
		fontSize: "15px",
		fontWeight: "600",
		// padding: "5px",
		textAlign: "left",
		// minWidth: "100px"
	},
	vehicle: {
		// margin: "20px",
		fontSize: "12px",
		fontWeight: "600",
		// padding: "5px",
		textAlign: "left",
		// minWidth: "100px"
	},
	detail: {
		// margin: "20px",
		fontSize: "12px",
		fontWeight: "600",
		padding: "5px",
		textAlign: "right",
		color: "#B9940D"
	},
}));
