import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
	navigation: {
		fontSize: "16px",
		fontWeight: "400",
		textAlign: "center",
	},
	listItemText: {
		transition: "all 0.5s cubic-bezier(0.19, 1, 0.22, 1)",
		"&.active": {
			fontWeight: "600 !important",
		},
		overflowWarp:"break-word",
	},
	navLinkItem: {
		marginLeft: "56px",
		display: "flex",
		flexDirection: "column",
		"&:nth-child(1)": {
			marginLeft: "0px",
		},
		[theme.breakpoints.down("sm")]: {
			marginTop: "15px",
			marginLeft: "0px",
		},
		
	},
	listItemIcon: {
		transition: "all 0.5s cubic-bezier(0.19, 1, 0.22, 1) !important",
		"&.active": {
			color: "white",
		},
	},
	listItem: {
		overflow: "hidden",
		transition: "all 0.5s cubic-bezier(0.19, 1, 0.22, 1) !important",
		"&.active": {
			backgroundColor: "rgba(183,149,16,255)",//theme.colors.primary,
			color: "white",
		},
		"&:hover": {
			[theme.breakpoints.up("md")]: {
				overflow: "visible",
				backgroundColor: `rgba(183,149,16,255) !important`,
				color: "white",
				borderRadius: ({ open }) => (open ? "0" : "0 6px 6px 0"),
				width: "300px",
				"& $listItemText": {
					fontWeight: "600 !important",
				},
				"& $listItemIcon": {
					color: "white",
				},
			},
		},
	},
}));
