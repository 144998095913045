import { FormGroup, Grid, TextField, FormControlLabel, Checkbox, FormLabel } from "@material-ui/core";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { createVehicleStart } from "../../redux/vehicle/vehicle.action";
import CustomSelect from "../CustomSelect/CustomSelect";
import CustomModal from "../CustomModal/CustomModal";
import CustomDatePicker from "../CustomDatePicker/CustomDatePicker";
import PillButton from "../PillButton/PillButton";
import MaskedInputCustom from "../MaskedInputCustom/MaskedInputCustom";
import { useStyles } from "./CreateRoutePlanModal.styles";
import { selectCurrentUserCompany } from "../../redux/company/company.selector";
import { fetchAllEstatesStart } from "../../redux/estate/estate.action";
import { fetchAllAfdelingStart } from "../../redux/afdeling/afdeling.action";
import { selectAllVehicleType } from "../../redux/vehicle-type/vehicle-type.selector";
import { selectAllEstates } from "../../redux/estate/estate.selector";
import { selectAllAfdeling } from "../../redux/afdeling/afdeling.selector";
import {
	selectBlockLoading,
	selectBlockPlural,
} from "../../redux/block/block.selector";
import {
	fetchBlocksStart,
	deleteBlocksStart,
} from "../../redux/block/block.action";

const CreateRoutePlanModal = (props) => {
	const classes = useStyles();
	const {
		company,
		open,
		onClose,
		createVehicleStart,
		allEstates,
		fetchAllEstatesStart,
		allAfdeling,
		fetchAllAfdelingStart,
		allBlocks,
        fetchBlocksStart,
	} = props;

	const {
		register,
		formState: { errors },
		control,
		reset,
		watch,
		handleSubmit,
	} = useForm({
		mode: "onChange",
	});

	const acquisitionYear = watch("acquisitionYear", "");
	const tareWeight = watch("tareWeight", "");

	const handleClose = () => {
		onClose();
		reset({
			type: "",
			code: "",
			name: "",
			description: "",
			acquisitionYear: "",
			ownership: "",
			tareWeight: "",
			frameNo: "",
			engineNo: "",
			estateId: "",
			afdelingId: "",
		});
	};

	const createVehicle = (data) => {
		console.log(data);
		createVehicleStart(data);
		handleClose();
	};

	useEffect(() => {
		if (!!company) {
			// fetchAllVehicleTypeStart()
			fetchAllEstatesStart();
			fetchAllAfdelingStart();
		}
	}, [company, fetchAllEstatesStart, fetchAllAfdelingStart]);

	const opsiTPH = [
		{ id: 'G1', value: 'G1', name: '22' },
		{ id: 'G2', value: 'G2', name: '23' },
		{ id: 'G3', value: 'G3', name: '24' },
		{ id: 'G4', value: 'G4', name: '25' },
	]

	const opsiMaksud = [
		{ id: 'tbs', value: 'tbs', name: 'Angkut TBS' },
		{ id: 'tbs2', value: 'tbs2', name: 'Angkut TBS 2' },
		{ id: 'tbs3', value: 'tbs3', name: 'Angkut TBS 3' },
		{ id: 'tbs4', value: 'tbs4', name: 'Angkut TBS 4' },
		
	]

	return (
		<CustomModal
			open={open}
			onClose={handleClose}
			width="40vw"
			title="Tambah Route Plan Baru"
		>
			<Grid container>
				<form>
					<Grid
						container
						justify="center"
						alignContent="center"
						spacing={3}
					>
						<Grid item xs={12}>
							<CustomSelect
								fullWidth
								id="maksud"
								label="Maksud Perjalanan"
								name="maksud"
								options={opsiMaksud.map((maksud) => ({
									value: maksud.id,
									text: maksud.name,
								}))}
								control={control}
								required
							/>
						</Grid>

						<Grid item xs={12}>
							<CustomSelect
								fullWidth
								id="estateId"
								label="Estate"
								name="estateId"
								options={allEstates.map((estate) => ({
									value: estate.id,
									text: estate.name,
								}))}
								control={control}
								required
							/>
						</Grid>

						<Grid item xs={12}>
							<CustomSelect
								fullWidth
								id="afdelingId"
								label="Afdeling/Divisi"
								name="afdelingId"
								options={allAfdeling.map((afdeling) => ({
									value: afdeling.id,
									text: afdeling.name,
								}))}
								control={control}

							/>
						</Grid>

						<Grid item xs={12}>
							<CustomSelect
								fullWidth
								id="blockId"
								label="Block"
								name="blockId"
								options={allBlocks.map((block) => ({
									value: block.id,
									text: block.blockName,
								}))}
								control={control}
								required
							/>
						</Grid>

						<Grid item xs={12}>
							<CustomSelect
								fullWidth
								id="tph"
								label="TPH"
								name="tph"
								options={opsiTPH.map((tph) => ({
									value: tph.id,
									text: tph.name,
								}))}
								control={control}
								required
							/>
						</Grid>

						<Grid item xs={12}>
							{/* <CustomSelect
								fullWidth
								id="day"
								label="Pilih Hari"
								name="day"
								options={opsiDays.map((day) => ({
									value: day.id,
									text: day.name,
								}))}
								control={control}
								required
							/> */}
							<FormLabel component="legend">Pilih Hari</FormLabel>
							<FormGroup>
								<FormControlLabel
									control={<Checkbox size="small" />}
									label="Senin"
								/>
								<FormControlLabel
									control={<Checkbox size="small" />}
									label="Selasa"
								/>
								<FormControlLabel
									control={<Checkbox size="small" />}
									label="Rabu"
								/>
								<FormControlLabel
									control={<Checkbox size="small"/>}
									label="Kamis"
								/>
								<FormControlLabel
									control={<Checkbox size="small"/>}
									label="Jumat"
								/>
								<FormControlLabel
									control={<Checkbox size="small"/>}
									label="Sabtu"
								/>
								<FormControlLabel
									control={<Checkbox size="small"/>}
									label="Minggu"
								/>
							</FormGroup>
						</Grid>

						<Grid item xs={12}>
							<CustomDatePicker
								fullWidth
								autoOk
								// inputVariant="outlined"
								id="startDate"
								name="startDate"
								control={control}
								placeholder="dd-mm-yyyy"
								format="dd-MM-yyyy"
								label="Aktif Sampai"
								views={["year", "month", "date"]}
								required
							/>
						</Grid>

						<Grid item xs={12} className={classes.buttonContainer}>
							<PillButton
								element="button"
								// type="submit"
								text="Save"
								backgroundColor="#DED9C4"
								color="black"
								additionalClasses={[classes.pillButton]}
							/>
						</Grid>
						
					</Grid>
				</form>
			</Grid>
		</CustomModal>
	);
};

const mapStateToProps = createStructuredSelector({
	company: selectCurrentUserCompany,
	allEstates: selectAllEstates,
	allAfdeling: selectAllAfdeling,
	allBlocks: selectBlockPlural
});

const mapDispatchToProps = (dispatch) => ({
	createVehicleStart: (info) => dispatch(createVehicleStart(info)),
	fetchAllEstatesStart: () => dispatch(fetchAllEstatesStart()),
	fetchAllAfdelingStart: () => dispatch(fetchAllAfdelingStart()),
	fetchBlocksStart: (filters) => dispatch(fetchBlocksStart(filters)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateRoutePlanModal);
