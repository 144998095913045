import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";

const CustomTabPanel = (props) => {
	const { children, value, index, entity, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`${entity}-tabpanel-${index}`}
			aria-labelledby={`${entity}-tab-${index}`}
			{...other}
		>
			{value === index && <Grid container>{children}</Grid>}
		</div>
	);
};

CustomTabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
	entity: PropTypes.any.isRequired,
};

export default CustomTabPanel;
