import { Grid, TextField, Typography } from "@material-ui/core";
import React from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { createEstateStart } from "../../redux/estate/estate.action";
import { selectEstateLoading } from "../../redux/estate/estate.selector";
import CustomModal from "../CustomModal/CustomModal";
import PillButton from "../PillButton/PillButton";
import { useStyles } from "./InputReportModal.styles";
import CustomSelect from "../CustomSelect/CustomSelect";
import CustomDatePicker from "../CustomDatePicker/CustomDatePicker";

const InputReportModal = (props) => {
	const classes = useStyles();
	const { open, onClose, createEstateStart, isEstateLoading } = props;

	const {
		register,
		formState: { errors },
		control,
		reset,
		handleSubmit,
	} = useForm({
		mode: "onChange",
	});

	const handleClose = () => {
		onClose();
		reset({
			// estateName: "",
			// area: "",
			// description: "",
		});
	};

	const inputVehicle = (data) => {
		if (!isEstateLoading) {
			createEstateStart(data);
			handleClose();
		}
	};

    const opsiList = [{ id: 'a', value: 'a', name: 'A' },
    { id: 'b', value: 'b', name: 'B' },
    { id: 'c', value: 'c', name: 'C' }]

	return (
		<CustomModal
			open={open}
			onClose={handleClose}
			width="40vw"
			title="Input Kendaraan"
		>
			<Grid container>
				<form >
					<Grid
						container
						justify="center"
						alignContent="center"
						spacing={3}
					>
					    <Grid item xs={12} >
                            <CustomSelect
								fullWidth
								variant="outlined"
								id=""
								label="Unit"
								name=""
								options={opsiList.map((opt) => ({
									value: opt.id,
									text: opt.name,
								}))}
								control={control}
								// required
							/>
						</Grid>
						<Grid item xs={12} >
                            <CustomSelect
								fullWidth
								variant="outlined"
								id=""
								label="Jenis Kendaraan"
								name=""
								options={opsiList.map((opt) => ({
									value: opt.id,
									text: opt.name,
								}))}
								control={control}
								// required
							/>
						</Grid>
                        <Grid item xs={12} >
                            <CustomSelect
								fullWidth
								variant="outlined"
								id=""
								label="Alokasi"
								name=""
								options={opsiList.map((opt) => ({
									value: opt.id,
									text: opt.name,
								}))}
								control={control}
								// required
							/>
						</Grid>
						<Grid item xs={12} md={5}>
                            <CustomDatePicker
                                fullWidth
                                inputVariant="outlined"
                                id=""
                                name=""
                                control={control}
                                format="dd-MM-yyyy"
                                placeholder="dd-mm-yyyy"
                                label="Tanggal"
                                views={["year", "month", "date"]}
                            />
						</Grid>
						<Grid item xs={12} md={1} alignItems="center">
                            <Typography >
								S/D
							</Typography>
						</Grid>
						<Grid item xs={12} md={5}>
                            <CustomDatePicker
                                fullWidth
                                inputVariant="outlined"
                                id=""
                                name=""
                                control={control}
                                format="dd-MM-yyyy"
                                placeholder="dd-mm-yyyy"
                                label="Tanggal"
                                views={["year", "month", "date"]}
                            />
						</Grid>
                        <Grid item xs={12}>
							<TextField
								fullWidth
								id=""
                                variant="outlined"
								label="No. Akun"
								name=""
								inputProps={{
									...register("countbbm", {
										// required: {
										// 	value: true,
										// 	message: "Luas Area dibutuhkan",
										// },
										// pattern: {
										// 	value: /^-?\d*\.?\d*$/,
										// 	message:
										// 		"Jumlah BBM harus dalam bentuk angka",
										// },
									}),
								}}
								// error={!!errors.countbbm}
								// helperText={errors.countbbm && errors.countbbm.message}
							/>
						</Grid>
						<Grid item xs={12} className={classes.buttonContainer}>
							<PillButton
								element="button"
								type="submit"
								text="Simpan"
								// backgroundColor="#2E7D32"
								color="black"
								additionalClasses={[classes.pillButton]}
							/>
						</Grid>
					</Grid>
				</form>
			</Grid>
		</CustomModal>
	);
};

const mapStateToProps = createStructuredSelector({
	isEstateLoading: selectEstateLoading,
});

const mapDispatchToProps = (dispatch) => ({
	createEstateStart: (estateInfo) => dispatch(createEstateStart(estateInfo)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InputReportModal);
