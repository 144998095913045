// return entity level
export const entityLevelOrders = [
	"company",
	"estate",
	"afdeling",
	"block",
	"tree",
];

export const entityLevelOrderPlural = [
	"companies",
	"estates",
	"afdelings",
	"blocks",
	"trees",
];

export const generateGeoJSON = ({ data, dataBound, filterLevel, boundaryJson }) => {
	// console.log('databound', dataBound)
	let initialPoint = [101.03872539000001, 0.215340066];
	// let initialPoint = [101.03872539000001, 0.5];
	let strecthRightLeft = 0.005;
	let strecthTopBottom = 0.0009;
	let marginBottom = 0.001;
	let geometryType = filterLevel === "tree" ? "Point" : "Polygon";

	let features = [];

	if(dataBound){
		data = dataBound
	}

	// console.log('boundary json', boundaryJson)

	// if(check){

	// }
	if(boundaryJson){
		let geometry = {
			type: geometryType,
			coordinates: [],
		};
		// console.log('geometri coordinates', JSON.parse(boundaryJson.data.record.boundaryJson).coordinates)
		geometry = boundaryJson
		// console.log('geometri coordinates', geometry.coordinates)
		// initialPoint = [
		// 	initialPoint[0],
		// 	initialPoint[1] - 2 * strecthTopBottom - marginBottom,
		// ];
		let feature = {
			type: "Feature",
			properties: {
				...data,
			},
			geometry,
		};

		features.push(feature);
	}
	else{
		data.forEach((data) => {
			// console.log('data', data)
			let geometry = {
				type: geometryType,
				coordinates: [],
			};
	
			if (geometryType == "Point") {
				geometry.coordinates = [data.longitude, data.latitude];
	
				 geometry.coordinates = [
					 initialPoint[0],
					 initialPoint[1] - marginBottom,
				];
	
				 initialPoint = [
					 initialPoint[0],
					initialPoint[1] - 2 * marginBottom,
				 ];

				 let feature = {
					type: "Feature",
					properties: {
						...data,
					},
					geometry,
				};
	
				console.log('feature', feature)
		
				features.push(feature);
			} else {
				// console.log('check boundary', data)

				if(data.boundaryJson){
					if(Array.isArray(data.boundaryJson)){
						data.boundaryJson.forEach(bound =>{
							let jsonParseBound = JSON.parse(bound)
							// console.log('jsonparse bound', jsonParseBound.coordinates)
							jsonParseBound.coordinates.forEach(pars =>{
								// console.log('parse', pars)
								// pars.forEach(p =>{
									geometry.coordinates.push(pars)
								// })								
							})
							// geometry.coordinates.push(jsonParseBound.coordinates)
							// console.log('geometry', geometry)
						})
						// console.log('geometry', geometry.coordinates.length, geometry.coordinates)
						let coordinateTemp = []
						for(let i in geometry.coordinates){
							for(let j in geometry.coordinates[i]){
								coordinateTemp.push(geometry.coordinates[i][j])
							}
						}
						geometry.coordinates = []
						geometry.coordinates.push(coordinateTemp)
						// console.log('geometry', geometry.coordinates.length, geometry.coordinates)
						let feature = {
							type: "Feature",
							properties: {
								...data,
							},
							geometry,
						};
			
						// console.log('feature', feature)
				
						features.push(feature);
					}
					else{
						let coordinatesObj = !!data.boundaryJson
						? JSON.parse(data.boundaryJson)
						: null;
					
						// console.log('coordinates json', coordinatesObj)
		
						let hasCoordinatesObj = coordinatesObj !== null;
						if (hasCoordinatesObj) {
							// console.log('check boundary', coordinatesObj)
							geometry = coordinatesObj;				
						} 
						else {
							geometry.coordinates = [
								[
									[
										initialPoint[0] - strecthRightLeft,
										initialPoint[1] + strecthTopBottom,
									],
									[
										initialPoint[0] + strecthRightLeft,
										initialPoint[1] + strecthTopBottom,
									],
									[
										initialPoint[0] + strecthRightLeft,
										initialPoint[1] - strecthTopBottom,
									],
									[
										initialPoint[0] - strecthRightLeft,
										initialPoint[1] - strecthTopBottom,
									],
								],
							];
			
							initialPoint = [
								initialPoint[0],
								initialPoint[1] - 2 * strecthTopBottom - marginBottom,
							];
						}

						let feature = {
							type: "Feature",
							properties: {
								...data,
							},
							geometry,
						};
			
						// console.log('feature', feature)
				
						features.push(feature);
					}
				}
				else {
					geometry.coordinates = [
						[
							[
								initialPoint[0] - strecthRightLeft,
								initialPoint[1] + strecthTopBottom,
							],
							[
								initialPoint[0] + strecthRightLeft,
								initialPoint[1] + strecthTopBottom,
							],
							[
								initialPoint[0] + strecthRightLeft,
								initialPoint[1] - strecthTopBottom,
							],
							[
								initialPoint[0] - strecthRightLeft,
								initialPoint[1] - strecthTopBottom,
							],
						],
					];
	
					initialPoint = [
						initialPoint[0],
						initialPoint[1] - 2 * strecthTopBottom - marginBottom,
					];

					let feature = {
						type: "Feature",
						properties: {
							...data,
						},
						geometry,
					};
		
					// console.log('feature', feature)
			
					features.push(feature);
				}
			}
	
			// let feature = {
			// 	type: "Feature",
			// 	properties: {
			// 		...data,
			// 	},
			// 	geometry,
			// };

			// console.log('feature', feature)
	
			// features.push(feature);
		});
	}

	const geoJSON = {
		type: "FeatureCollection",
		features: features,
	};

	// console.log('geojson', geoJSON)

	return geoJSON;
};
