import { createMuiTheme } from "@material-ui/core";

export const customTheme = createMuiTheme({
	overrides: {
		// MuiPickersToolbar: {
		// 	toolbar: {
		// 		backgroundColor: "#FFDD28",
		// 	},
		// },
		// MuiPickersDay: {
		// 	daySelected: {
		// 		backgroundColor: "#FFDD28",
		// 	},
		// 	dayHover: {
		// 		backgroundColor: "#FFDD28",
		// 	},
		// },
	},
});
