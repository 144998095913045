import axios from "axios";
import { takeLatest, put, all, call, select, take } from "redux-saga/effects";
import KeycloakLogout from "../../auth/Logout/KeycloakLogout";
import KeycloakRefreshToken from "../../auth/RefreshTokenSetup/KeycloakRefreshToken";
import AzureToken from "../../auth/TokenEndpointSetup/AzureToken";
import FacebookToken from "../../auth/TokenEndpointSetup/FacebookToken";
import GoogleToken from "../../auth/TokenEndpointSetup/GoogleToken";
import KeycloakToken from "../../auth/TokenEndpointSetup/KeycloakToken";
import AzureUser from "../../auth/UserDetails/AzureUser";
import FacebookUser from "../../auth/UserDetails/FacebookUser";
import GoogleUser from "../../auth/UserDetails/GoogleUser";
import KeycloakUser from "../../auth/UserDetails/KeycloakUser";
import { BASE_URL_API } from "../../configs/env";
import { openSnackbar } from "../snackbar/snackbar.action";
import{
    clearFileError,
    insertFileStart,
    insertFileSuccess,
    insertFileFailure,
    fetchAllFileStart,
    fetchAllFileSuccess,
    fetchAllFileFailure,
	fetchAllTemplateSuccess,
	fetchAllTemplateFailure,
	fetchTemplateByIdSuccess,
	fetchTemplateByIdFailure,
	fetchAllTemplateCategorySuccess,
	fetchAllTemplateCategoryFailure,
	importFileStart,
	importFileSuccess,
	importFileFailure,
	fetchAllImportedFileStart,
	fetchAllImportedFileSuccess,
	fetchAllImportedFileFailure,
	fetchImportedFileByIdFailure,
	fetchImportedFileByIdStart,
	fetchImportedFileByIdSuccess
} from "./rkt.action";
import RktActionTypes from "./rkt.types";
import moment from "moment";
import queryString from "query-string";
import { channel } from "@redux-saga/core";
import { selectCurrentUserCompany } from "../company/company.selector";
import { selectCurrentUser } from "../user/user.selector";

export function* insertRktFile({ payload }) {
	const rktInfo = payload;
	const company = yield select(selectCurrentUserCompany);
    const currentUser = yield select(selectCurrentUser);


	try {
		const response = yield axios({
			method: "POST",
			url: `${BASE_URL_API}/import-files`,
            // headers: {
			// 	"Content-Type": "multipart/form-data",
			// },
			data: {
                afdelingId: rktInfo.afdelingId,
                // afdelingName: "string",
                createdBy: currentUser.username,
                // createdTime: "2021-11-08",
                // "deletedBy": "string",
                // "deletedTime": "2021-10-08",
                documentTemplateCategoryId: rktInfo.category,
                documentTemplateId: rktInfo.name,
                // documentTemplateName: "string",
                estateId: rktInfo.estateId,
                // estateName: "string",
                fileContentId: rktInfo.idFile,
                // fileContentOriginalName: "string",
                // id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
                isDeleted: 0,
                //name: rktInfo.name,
                quarter: rktInfo.quarter,
                revision: 0,
                // syncedStatus: 0,
                // "syncedTime: "2021-10-08",
                updatedBy: currentUser.username,
                // updatedTime: "2021-11-08",
                version: 1,
                year: new Date(rktInfo.year).getFullYear()
			},
		});

		if (!response.data.success) {
			throw new Error(response.data.message);
		}

		yield put(insertFileSuccess());
		// yield put(fetchAllStart());
        const successInfo = {
			message: "Submit File RKT Sukses!",
			color: "success",
			place: "bl",
			dispatchActions: [],
		};
		yield put(openSnackbar(successInfo));
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearFileError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(insertFileFailure(errorMessage));
	}
}

export function* onInsertFileStart() {
	yield takeLatest(RktActionTypes.INSERT_FILE_START, insertRktFile);
}

export function* fetchAllFile() {
	const company = yield select(selectCurrentUserCompany);
	const query = queryString.stringify({
		fltCompanyId: company.id,
		pageSize: 0,
	});
	try {
		const response = yield axios({
			method: "GET",
			url: `${BASE_URL_API}/import-files?${query}`,
		});

		if (!response.data.success) {
			throw new Error(response.data.message);
		}

		const allFile = response.data.records;
        // const allFile = [{"abc": 1, "def": 2}]
		yield put(fetchAllFileSuccess(allFile));
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearFileError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(fetchAllFileFailure(errorMessage));
	}
}

export function* onFetchImportedFileStart() {
	yield takeLatest(
		RktActionTypes.FETCH_IMPORTED_FILE_BY_ID_START,
		fetchImportedFileById
	);
}

export function* fetchImportedFileById(payload) {
	const userId = payload
	// const company = yield select(selectCurrentUserCompany);
	// const query = queryString.stringify({
	// 	fltCompanyId: company.id,
	// 	pageSize: 0,
	// });
	try {
		const response = yield axios({
			method: "GET",
			url: `${BASE_URL_API}/file-contents?${userId}`,
		});

		if (!response.data.success) {
			throw new Error(response.data.message);
		}

		const file = response.data.records;
        // const allFile = [{"abc": 1, "def": 2}]
		yield put(fetchImportedFileByIdSuccess(file));
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearFileError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(fetchImportedFileByIdFailure(errorMessage));
	}
}

export function* onFetchAllFileStart() {
	yield takeLatest(
		RktActionTypes.FETCH_ALL_FILE_START,
		fetchAllFile
	);
}

export function* importRktFile({ payload }) {
	const importInfo = payload;

	const currentUser = yield select(selectCurrentUser);

	const formData = new FormData();
	formData.append("content", importInfo.file);
	formData.append('userId', currentUser.id);
	formData.append('fileName', importInfo.file[0].name);

	try {

		// console.log('formdata', formData.getAll('id'))

		const response = yield axios({
			method: "POST",
			url: `${BASE_URL_API}/file-contents/upload`,
			headers: {
				"Content-Type": "multipart/form-data",
			},
			data: formData,
		});

		// console.log('upload response', response)

		if (!response.data.success) {
			throw new Error(response.data.message);
		}

		const file = response.data.record;
		// yield put(fetchAllFileStart());
		const successInfo = {
			message: "Import User Sukses!",
			color: "success",
			place: "bl",
			dispatchActions: [],
		};
		yield put(openSnackbar(successInfo));
		yield put(importFileSuccess(file));
		
		yield put(insertFileStart({...importInfo, idFile: file.id}))

	} catch (error) {
		const errorMessage = 
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearFileError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(importFileFailure(errorMessage));
	}
}

export function* onImportFileStart() {
	yield takeLatest(RktActionTypes.IMPORT_FILE_START, importRktFile);
}

export function* fetchAllTemplate({payload}) {
	const info = payload;
	const company = yield select(selectCurrentUserCompany);
	const query = queryString.stringify({
		fltCompanyId: company.id,
		pageSize: 0,
		...info
	});
	try {
		const response = yield axios({
			method: "GET",
			url: `${BASE_URL_API}/document-templates?${query}`,
		});

		if (!response.data.success) {
			throw new Error(response.data.message);
		}

		const allFile = response.data.records;
        // const allFile = [{"abc": 1, "def": 2}]
		yield put(fetchAllTemplateSuccess(allFile));
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearFileError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(fetchAllTemplateFailure(errorMessage));
	}
}

export function* onFetchAllTemplateStart() {
	yield takeLatest(
		RktActionTypes.FETCH_ALL_TEMPLATE_START,
		fetchAllTemplate
	);
}

export function* fetchAllTemplateCategory() {
	const company = yield select(selectCurrentUserCompany);
	const query = queryString.stringify({
		fltCompanyId: company.id,
		pageSize: 0,
	});
	try {
		const response = yield axios({
			method: "GET",
			url: `${BASE_URL_API}/document-template-categories?${query}`,
		});

		if (!response.data.success) {
			throw new Error(response.data.message);
		}

		const allFile = response.data.records;
        // const allFile = [{"abc": 1, "def": 2}]
		yield put(fetchAllTemplateCategorySuccess(allFile));
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearFileError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(fetchAllTemplateCategoryFailure(errorMessage));
	}
}

export function* onFetchAllTemplateCategoryStart() {
	yield takeLatest(
		RktActionTypes.FETCH_ALL_TEMPLATE_CATEGORY_START,
		fetchAllTemplateCategory
	);
}

export function* rktSagas() {
    yield all([
		call(onInsertFileStart),
        call(onFetchAllFileStart),
		call(onImportFileStart),
		call(onFetchImportedFileStart),
		call(onFetchAllTemplateStart),
		call(onFetchAllTemplateCategoryStart)
	]);
}