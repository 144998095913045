import { Grid, Paper, Typography, Box, Divider, CardMedia, CardHeader, Avatar } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { connect } from "react-redux";
import { useStyles } from "./DashboardTransport.styles";
import CustomDatePicker from "../../components/CustomDatePicker/CustomDatePicker";
import { openSnackbar } from "../../redux/snackbar/snackbar.action";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import { Skeleton } from "@material-ui/lab";
import PillButton from "../../components/PillButton/PillButton";
import moment from "moment";
import RoutePlanningForm from "../RoutePlanningForm/RoutePlanningForm";
import { Doughnut, Line } from "react-chartjs-2";
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import { Fragment } from "react";
import TruckPhoto from "../../assets/img/truck.png"
import AvatarPhoto from "../../assets/img/avatar.jpg"
import KirimOverlay from "../KirimOverlay/KirimOverlay";

const optionBaris = {text: "All", value:"All"};

const DashboardTransport = (props) => {
	const classes = useStyles();

	const data = {
		// labels: ["1" , "2"], 
		text: "95%",
		datasets: [
		  {
			label:"Pemakaian Kendaraan",
			data: [95, 5], 
			/*Object.values(taksasiChartData).map((taksasi) => {
			  return taksasi;
			}),*/
			backgroundColor: [
				'#597cf0',
				'#d17728',
		 	 ],
			// weight: 0.5
		  },
		],
	  };
	
	  const options = {
		//  tooltips: {
		//   callbacks: {
		// 	label: function(tooltipItem, data) {
		// 	  const dataset = data.datasets[tooltipItem.datasetIndex];
		// 	  const currentValue = dataset.data[tooltipItem.index];
		// 	  let total = 0;
		// 	  for (let i = 0; i < data.datasets.length; i++) {
		// 		total += data.datasets[i].data[tooltipItem.index];
		// 	  }
		// 	  const percentage = (currentValue / total * 100).toFixed(0);
		// 	  return `${currentValue} (${percentage}%)`;
		// 	},
		// 	title: function(tooltipItem, data) {
		// 	  return data.labels[tooltipItem[0].index];
		// 	}
		//   }
		// },
	  }
	  
	  const dataLine = {
        labels: ["Angkut TBS",
			"Angkut Bibit",
			"Angkut BBM",
			"Angkut Kayu",
			"Angkut Pupuk",
			"Angkut Karyawan",
			"Timbun Jalan",
			"Angkut Solid",
			"Janjang Kosong",
			"Angkut Abu Broiler",
			"Angkut Fiber",
			"Angkut CPO", 
			"Angkut Kernel",
			"Lainnya"
        ],
        datasets: [{
            label: "-",
            data: [  3 ,
                1 ,
                2 ,
                1 ,
                5 ,
                2 ,
                1 ,
                3 ,
                2 ,
				3 ,
				2 ,
				2 ,
				0
               ], 
            /*Object.values(harvestChartData).map((harvestCount) => {
                return harvestCount;
            }),*/
            backgroundColor: ["#597cf0"],
            borderColor: ["#597cf0"],
            order: 1,
            fill: false,
		}],
    };

	let vehicleDataDummy = ["A", "B", "C", "D", "E", "F", "G", "H", "I"]

	const [isOverlayOpen, setOverlayOpen] = useState(false)

	const handleOverlayClose = () => {
		setOverlayOpen(false)
	}

	const handleOverlayOpen = () => {
		setOverlayOpen(true)
	}

	const plugins = [{
		beforeDraw: function(chart) {
		 var width = chart.width,
			 height = chart.height,
			 ctx = chart.ctx;
			 ctx.restore();
			 var fontSize = (height / 140).toFixed(2);
			// var fontSize = 20
			 ctx.font = "bold 20pt Courier";
			 ctx.fontWeight = 700
			 ctx.textBaseline = "top";
			 var text = data.text,
			 textX = Math.round((width - ctx.measureText(text).width) / 2),
			 textY = height / 2;
			 ctx.fillText(text, textX, textY);
			 ctx.save();
		} 
	  }]

	const options1 = {
		responsive: true,
		legend: {
			display: false,
			position: "bottom",
			labels: {
			fontSize: 18,
			// fontWeight: 700,
			fontColor: "#6D7278",
			fontFamily: "kanit light"
			}
		},
		cutout: "60%",
		radius: "70%"
	};

	return (
        <Grid
			container
			// className={classes.produksiBulananContainer}
			spacing={2}		
		>
			<Box m={4}>
				<Typography variant="h6">
					Minggu ke-5 Desember
				</Typography>
			</Box>
			
        <Grid
            item xs={12}
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
        >
			<KirimOverlay
				open={isOverlayOpen}
				onClose={handleOverlayClose}
			/>
			<Box m={1.5}>
				<Grid container item xs={12}>
					<Grid 
						item xs={12} md={3}
						// alignItems = "left"
						// justifyContent="left"
					> 
						{/* <Box m={6}> */}
							<Grid container direction="column" alignItems="center" justifyContent="center">
								<Grid item xs={12} md={9} className={classes.vehicleChartContainer} container>
									<Doughnut data={data} options={options1} plugins={plugins}/>
								</Grid>
								{/* <Grid item xs={12} md={9} className={classes.vehicleText} container >
									<Typography variant="h6" >
										Pemakaian Kendaraan
									</Typography>
								</Grid> */}
								
							</Grid>
						{/* </Box>  */}
					</Grid>
					<Grid 
						item xs={12} md={9}
						// alignItems = "right"
					>
						<Grid container direction="column" alignItems="center" justifyContent="center">
							<Grid item xs={12} md={9} className={classes.frequencyChartContainer} container>
								<Line data={dataLine}  height="90px"/>
							</Grid>
							{/* <Typography variant="h6">
								Frekuensi Request Kendaraan
							</Typography> */}
							
						</Grid>
					</Grid>
				</Grid>

				<Grid container item xs={12}>
					<Grid 
						item xs={12} md={3}
					> 
						<Grid container direction="column" alignItems="center" justifyContent="center" className={classes.vehicleText}>
							<Typography variant="h6" >
								Pemakaian Kendaraan
							</Typography>
						</Grid>
					</Grid>
					<Grid 
						item xs={12} md={9}
					>
						<Grid container direction="column" alignItems="center" justifyContent="center" className={classes.frequencyText}>
							<Typography variant="h6">
								Frekuensi Request Kendaraan
							</Typography>
						</Grid>
					</Grid>
				</Grid>

			</Box>
				{/* <Grid item xs={12} md={12}> */}
					{/* <RoutePlanningForm/> */}
				{/* </Grid> */}
			<Divider variant="middle" style={{width:'100%', backgroundColor:"#000000", marginBottom:"20px"}}/>

			<Paper
				component="form"
				sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400}}
				variant="outlined"
			>
				<IconButton type="submit" sx={{ p: '10px' }} aria-label="search">
					<SearchIcon />
				</IconButton>
				<InputBase
					sx={{ ml: 1, flex: 1 }}
					placeholder="Cari kendaraan, driver atau lainnya"
					inputProps={{ 'aria-label': 'Cari kendaraan, driver atau lainnya' }}
				/>	
			</Paper>

			<Grid
				item xs={12}
				container
				direction="row"
				justifyContent="space-between"
				// alignItems="flex-start"
				alignContent="flex-start"
			>
				{vehicleDataDummy.map((_) => {
					return (
						<Fragment key={"frag"+_}>
							<Box m={1}>
								<Paper elevation={3} className={classes.paper}>
									<Grid container>
										<Grid item xs={5} className={classes.avatar} container direction="row" alignItems="center" justifyContent="center">
											<Grid item xs={12} className={classes.vehicleDetail} alignItems="center">
												<CardMedia
													component="img"
													height="130"
													// widht="100"
													image= {TruckPhoto}
													alt="Truck"
												/>
											</Grid>
											<Grid item xs={12} className={classes.vehicleDetail} alignItems="center">
												BK 2768 CW
											</Grid>
											<Grid item xs={12} className={classes.vehicleDetail}>
												DUMP TRUCK
											</Grid>
										</Grid>
										
										<Grid item xs={7} className={classes.driver} container direction="row" alignItems="center" justifyContent="center">
											<Grid item xs={10} className={classes.avatar}>
												<CardHeader
													avatar={
														<Avatar src={AvatarPhoto} alt="ava" />
													}
													title="Aggnar Otnay"
													subheader="+62213456789"
												/>
											</Grid>
											<Grid item xs={12} className={classes.detail}>
												<a className={classes.detail} onClick={handleOverlayOpen}>LIHAT DETAIL</a>
											</Grid>
										</Grid>
									</Grid>
								</Paper>
							</Box>					
						</Fragment>
					);
				})}
				
			</Grid>
		</Grid>
			{/* </Grid> */}
    	</Grid>
	);
};

export default (DashboardTransport);