import { Grid, Menu, MenuItem, Tooltip, Zoom } from "@material-ui/core";
import React, { useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectCurrentUser } from "../../redux/user/user.selector";
import { useStyles } from "./Header.styles";
// import { ReactComponent as NotificationIcon } from "../../assets/img/notification-icon.svg";
// import { ReactComponent as ActiveNotificationIcon } from "../../assets/img/notification-icon-active.svg";
import AvatarDefault from "@material-ui/icons/AccountCircle";
import SemaiLogo from "../../assets/img/semaiPro_h_white_bg_trans.png";
// import BurgerIcon from "../BurgerIcon/BurgerIcon";
import { logoutStart } from "../../redux/user/user.action";
import HomeIcon from "@material-ui/icons/Home";
import AssignmentIcon from "@material-ui/icons/Assignment";
import StorageIcon from "@material-ui/icons/Storage";

const Header = (props) => {
	const { currentUser, userLogoutStart } = props;
	const classes = useStyles();

	const [profileMenuAnchorEl, setProfileMenuAnchorEl] = useState(null);

	const handleClickProfileMenu = (event) => {
		setProfileMenuAnchorEl(event.currentTarget);
	};

	const handleCloseProfileMenu = () => {
		setProfileMenuAnchorEl(null);
	};

	const handleLogout = () => {
		setProfileMenuAnchorEl(null);
		userLogoutStart();
	};

	return (
		<header>
			<Grid
				container
				className={classes.headerContainer}
				justify="space-between"
				alignContent="center"
			>
				<Grid item xs={3}>
					<a href="/">
						<img
							src={SemaiLogo}
							alt="semai"
							className={classes.semaiLogo}
						/>
					</a>
				</Grid>
				<Grid
					item
					xs={7}
					sm={6}
					md={4}
					lg={3}
					container
					justify="flex-end"
					alignItems="center"
				>
					{!!currentUser && (
						<>
							<Grid item xs={2}>
								<a href="/">
									<Tooltip
										TransitionComponent={Zoom}
										arrow
										title="Homepage"
										aria-label="homepage"
									>
										<HomeIcon className={classes.icon} />
									</Tooltip>
								</a>
							</Grid>
							<Grid item xs={2}>
								<AvatarDefault
									className={classes.icon}
									aria-controls="profile-menu"
									aria-haspopup="true"
									onClick={handleClickProfileMenu}
								/>
								<Menu
									className={classes.menu}
									id="profile-menu"
									anchorEl={profileMenuAnchorEl}
									keepMounted
									open={Boolean(profileMenuAnchorEl)}
									onClose={handleCloseProfileMenu}
								>
									<a href="/profile">
										<MenuItem
											onClick={handleCloseProfileMenu}
										>
											Profile
										</MenuItem>
									</a>
									<MenuItem onClick={handleLogout}>
										Logout
									</MenuItem>
								</Menu>
							</Grid>
							<Grid item xs={4}>
								Halo,{currentUser.fullname}
							</Grid>
						</>
					)}

					{/* <BurgerIcon open={open} handleOpen={setOpen} /> */}
				</Grid>
			</Grid>
		</header>
	);
};

const mapStateToProps = createStructuredSelector({
	currentUser: selectCurrentUser,
});

const mapDispatchToProps = (dispatch) => ({
	userLogoutStart: () => dispatch(logoutStart()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
