import axios from "axios";
import { takeLatest, put, all,  call, select } from "redux-saga/effects";
import { BASE_URL_API } from "../../configs/env";
import MapsActionTypes from "./maps.types";
import { openSnackbar } from "../snackbar/snackbar.action";
import {
	clearMapsError,
	fetchAfdelingsFailure,
	fetchAfdelingsSuccess,
	fetchBlocksFailure,
	fetchBlocksSuccess,
	fetchCompaniesFailure,
	fetchCompaniesSuccess,
	fetchEstatesFailure,
	fetchEstatesSuccess,
	fetchLandActivitiesFailure,
	fetchLandActivitiesSuccess,
	fetchLandSummaryFailure,
	fetchLandSummarySuccess,
	fetchTreesFailure,
	fetchTreesSuccess,
	setCompany,
	setGeoJSON,
	// setBlockMap
} from "./maps.action";
import { generateGeoJSON } from "../../helpers/Maps.helper";
import { selectCurrentUserCompany } from "../../redux/company/company.selector";
import queryString from "query-string";

export function* fetchCompanies() {
	const company = yield select(selectCurrentUserCompany);
	try {
		const response = yield axios({
			method: "GET",
			url: `${BASE_URL_API}/companies`,
		});

		if (!response.data.success) {
			throw new Error(response.data.message);
		}

		const companies = response.data.records;
		yield put(
			setGeoJSON(
				generateGeoJSON({ data: companies, filterLevel: "company" })
			)
		);
		yield put(
			setCompany(company)
		);
		yield put(fetchCompaniesSuccess(companies));
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearMapsError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(fetchCompaniesFailure(errorMessage));
	}
}

export function* onFetchCompaniesStart() {
	yield takeLatest(MapsActionTypes.FETCH_MAP_COMPANIES_START, fetchCompanies);
}

export function* fetchEstates({ payload }) {
	const company = yield select(selectCurrentUserCompany);
	const filters = queryString.stringify({
		fltCompany: company.id,
		pageSize: 0,
	});
	try {
		const response = yield axios({
			method: "GET",
			url: `${BASE_URL_API}/estates?${filters}`,
		});

		if (!response.data.success) {
			throw new Error(response.data.message);
		}

		const estates = response.data.records;
		yield put(
			setGeoJSON(
				generateGeoJSON({ data: estates, filterLevel: "estate" })
			)
		);
		yield put(fetchEstatesSuccess(estates));
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearMapsError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(fetchEstatesFailure(errorMessage));
	}
}

export function* onFetchEstatesStart() {
	yield takeLatest(MapsActionTypes.FETCH_MAP_ESTATES_START, fetchEstates);
}

export function* fetchAfdelings({ payload }) {
	const estateId = payload
	const query = queryString.stringify({
		fltEstate: estateId,
		fltDeleted: 0,
		pageSize: 0,
	});
	try {
		const response = yield axios({
			method: "GET",
			url: `${BASE_URL_API}/afdelings?${query}`,
		});

		if (!response.data.success) {
			throw new Error(response.data.message);
		}

		const responseBlock = yield axios({
			method: "GET",
			url: `${BASE_URL_API}/blocks?${query}`,
		});

		if (!responseBlock.data.success) {
			throw new Error(responseBlock.data.message);
		}

		let blocks = responseBlock.data.records

		// console.log('responseBlock', blocks)

		// let boundaryJson
		// boundaryJson = yield axios({
		// 	method: "GET",
		// 	url: `${BASE_URL_API}/estates/${estateId}/afdeling-boundaries`
		// })

		let afdelings = response.data.records;
		// let fetchBoundaryJson = []
		// console.log('afdeling', afdelings)
		// for(let afdeling in afdelings){
	
		let boundaryJson
		boundaryJson = yield axios({
			method: "GET",
			url: `${BASE_URL_API}/estates/${estateId}/block-boundaries`
		})

		let boundaryJsonAfdeling = yield axios({
			method: "GET",
			url: `${BASE_URL_API}/estates/${estateId}/afdeling-boundaries`
		})

		if(boundaryJsonAfdeling.data.records){
			boundaryJsonAfdeling = boundaryJsonAfdeling.data.records

			for(let afdeling in afdelings){
				// console.log('afdeling', afdelings[afdeling])
				// console.log('boundary json', boundaryJson)
				let boundAfd = boundaryJsonAfdeling.filter(bound =>{
					// console.log('bound', bound)
					return bound.name == afdelings[afdeling].name
				})
				// console.log('boundAfd', boundAfd)

				// let boundAfdelingLength = boundAfdeling.length
				// afdelings[afdeling].boundaryJson = []
				// for(let i=0; i<boundAfdelingLength; i++){
					afdelings[afdeling].boundaryJson = boundAfd && Array.isArray(boundAfd) && boundAfd[0] ? boundAfd[0].boundaryJson : null
					afdelings[afdeling].color = 'rgba(0,0,0,0)'
					afdelings[afdeling].opacity = 1
					afdelings[afdeling]['outline-color'] = 'black'
			}
		}
		else{
			boundaryJsonAfdeling = null
		}

		// afdelings = afdelings.map(afdeling =>{
		// 	let afd = []
		// 	afd.boundaryJson = afdeling.boundaryJson
		// 	afd.color = afdeling.color
		// 	afd.opacity = afdeling.opacity
		// 	afd['outline-color'] = afdeling['outline-color']
		// 	return afd
		// })
	
		// 	// let blocks = response.data.records;
		// 	// console.log('blocks', blocks)
	
		// console.log('boundary json check', boundaryJson)
		if(boundaryJson.data.records){
			boundaryJson = boundaryJson.data.records
		// 	// boundaryJson = JSON.parse(boundaryJson).coordinates
		// }
		
			// console.log('boundary json jsonparse', boundaryJson)
			// let boundaryJson = []
			let colorArray = ['#FF6633', '#FFB399', '#FF33FF', '#FFFF99', '#00B3E6', 
				'#E6B333', '#3366E6', '#999966', '#99FF99', '#B34D4D',
				'#80B300', '#809900', '#E6B3B3', '#6680B3', '#66991A', 
				'#FF99E6', '#CCFF1A', '#FF1A66', '#E6331A', '#33FFCC',
				'#66994D', '#B366CC', '#4D8000', '#B33300', '#CC80CC', 
				'#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399',
				'#E666B3', '#33991A', '#CC9999', '#B3B31A', '#00E680', 
				'#4D8066', '#809980', '#E6FF80', '#1AFF33', '#999933',
				'#FF3380', '#CCCC00', '#66E64D', '#4D80CC', '#9900B3', 
				'#E64D66', '#4DB380', '#FF4D4D', '#99E6E6', '#6666FF'];
			for(let block in blocks){
				// console.log('afdeling', afdelings[afdeling])
				// console.log('boundary json', boundaryJson)
				let boundBlock = boundaryJson.filter(bound =>{
					// console.log('bound', bound)
					return bound.name == blocks[block].blockName
				})
				// console.log('boundBlock', boundBlock)

				// let boundAfdelingLength = boundAfdeling.length
				// afdelings[afdeling].boundaryJson = []
				// for(let i=0; i<boundAfdelingLength; i++){
					blocks[block].boundaryJson = boundBlock && Array.isArray(boundBlock) && boundBlock[0] ? boundBlock[0].boundaryJson : null
					if(blocks[block].plantedYear){
						let stringYear = blocks[block].plantedYear.toString()
						let yearLength = stringYear.length
						// console.log('year', stringYear, yearLength)
						stringYear = stringYear.substring(yearLength-2, yearLength);
						let intYear = parseInt(stringYear)
						blocks[block].color = colorArray[intYear]
					}
					blocks[block]['outline-color'] = 'white'
					blocks[block].opacity = 0.5
			}
		}
		else{
			boundaryJson = null
		}
		// console.log('boundary afdeling', afdelings)

		// afdelings = afdelings.map(afd => {
		// 	return afd.id == 'd674cf71-6db8-45dd-bcf1-dd9ecc03f47e'
		// })

		// blocks = blocks.map(block =>{
		// 	let b = []
		// 	b.boundaryJson = block.boundaryJson
		// 	b.color = block.color
		// 	b.opacity = block.opacity
		// 	b['outline-color'] = block['outline-color']
		// 	return b
		// })

		// console.log('afde', afdelings)
		blocks = blocks.concat(afdelings)

		yield put(
			setGeoJSON(
				// generateGeoJSON({ data: afdelings, filterLevel: "afdeling", boundaryJson })
				generateGeoJSON({ data: blocks, filterLevel: "afdeling"})
			)
		);
		yield put(fetchAfdelingsSuccess(afdelings));
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearMapsError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(fetchAfdelingsFailure(errorMessage));
	}
}

export function* onFetchAfdelingsStart() {
	yield takeLatest(MapsActionTypes.FETCH_MAP_AFDELINGS_START, fetchAfdelings);
}

 export function* fetchBlocks({ payload }) {
	//  console.log('test payload', payload)
 	 const afdelingId = payload;
	 const query = queryString.stringify({
		fltAfdeling: afdelingId,
		fltDeleted: 0,
		pageSize: 0,
	});
 	try {
 		const response = yield axios({
			method: "GET",
			url: `${BASE_URL_API}/blocks?${query}`,
 		});

		if (!response.data.success) {
 			throw new Error(response.data.message);
 		}

		// console.log('response blocks', response)

 		// const blocks = response.data.records;
		// console.log('blocks check', blocks)


		let boundaryJson
		boundaryJson = yield axios({
			method: "GET",
			url: `${BASE_URL_API}/afdelings/${afdelingId}/block-boundaries`
		})

		let blocks = response.data.records;
		// console.log('blocks', blocks)

		let colorArray = ['#FF6633', '#FFB399', '#FF33FF', '#FFFF99', '#00B3E6', 
				'#E6B333', '#3366E6', '#999966', '#99FF99', '#B34D4D',
				'#80B300', '#809900', '#E6B3B3', '#6680B3', '#66991A', 
				'#FF99E6', '#CCFF1A', '#FF1A66', '#E6331A', '#33FFCC',
				'#66994D', '#B366CC', '#4D8000', '#B33300', '#CC80CC', 
				'#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399',
				'#E666B3', '#33991A', '#CC9999', '#B3B31A', '#00E680', 
				'#4D8066', '#809980', '#E6FF80', '#1AFF33', '#999933',
				'#FF3380', '#CCCC00', '#66E64D', '#4D80CC', '#9900B3', 
				'#E64D66', '#4DB380', '#FF4D4D', '#99E6E6', '#6666FF'];
		// console.log('boundary json check', boundaryJson)
		if(boundaryJson.data.records){
			boundaryJson = boundaryJson.data.records
			// boundaryJson = JSON.parse(boundaryJson).coordinates

			// console.log('boundary json jsonparse', boundaryJson)

			blocks = blocks.map(block=>{
				let boundAfdeling = boundaryJson.filter(bound =>{
					return bound.name == block.blockName
				})
				// console.log('boundafdeling', boundAfdeling)
				if(block.plantedYear){
					let stringYear = block.plantedYear.toString()
					let yearLength = stringYear.length
					// console.log('year', stringYear, yearLength)
					stringYear = stringYear.substring(yearLength-2, yearLength);
					let intYear = parseInt(stringYear)
					block.color = colorArray[intYear]
					block['outline-color'] = 'white'
					block.opacity = 0.5
				}
				block.boundaryJson = boundAfdeling[0].boundaryJson

				return block
			})
		}
		else{
			boundaryJson = null
		}

		console.log('blocks', blocks)

 		yield put(
 			setGeoJSON(generateGeoJSON({ data: blocks, filterLevel: "block" }))
 		);
 		yield put(fetchBlocksSuccess(blocks));
 	} catch (error) {
 		const errorMessage =
 			(error.response && error.response.data.message) || error.message;
 		const snackbarData = {
 			message: errorMessage,
 			color: "error",
			place: "bl",
 			dispatchActions: [clearMapsError],
 		};
 		yield put(openSnackbar(snackbarData));
 		yield put(fetchBlocksFailure(errorMessage));
	}
}

 export function* onFetchBlocksStart() {
 	yield takeLatest(MapsActionTypes.FETCH_MAP_BLOCKS_START, fetchBlocks);
 }


export function* fetchTrees({ payload }) {
	const blockId = payload;
	try {
		const response = yield axios({
			method: "GET",
			url: `${BASE_URL_API}/blocks/${blockId}`,
		});

		if (!response.data.success) {
			throw new Error(response.data.message);
		}

		const trees = response.data.record;
		
		let boundaryJson
		boundaryJson = yield axios({
			method: "GET",
			url: `${BASE_URL_API}/blocks/${blockId}/boundaries`
		})

		// let blocks = response.data.records;
		// console.log('blocks', blocks)


		let colorArray = ['#FF6633', '#FFB399', '#FF33FF', '#FFFF99', '#00B3E6', 
				'#E6B333', '#3366E6', '#999966', '#99FF99', '#B34D4D',
				'#80B300', '#809900', '#E6B3B3', '#6680B3', '#66991A', 
				'#FF99E6', '#CCFF1A', '#FF1A66', '#E6331A', '#33FFCC',
				'#66994D', '#B366CC', '#4D8000', '#B33300', '#CC80CC', 
				'#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399',
				'#E666B3', '#33991A', '#CC9999', '#B3B31A', '#00E680', 
				'#4D8066', '#809980', '#E6FF80', '#1AFF33', '#999933',
				'#FF3380', '#CCCC00', '#66E64D', '#4D80CC', '#9900B3', 
				'#E64D66', '#4DB380', '#FF4D4D', '#99E6E6', '#6666FF'];

		// console.log('boundary json check', boundaryJson)
		if(boundaryJson.data.record){
			boundaryJson = boundaryJson.data.record.boundaryJson
			boundaryJson = JSON.parse(boundaryJson)

			// console.log('boundary json jsonparse', boundaryJson)
		}
		else{
			boundaryJson = null
		}

		if(trees.plantedYear){
			let stringYear = trees.plantedYear.toString()
			let yearLength = stringYear.length
			// console.log('year', stringYear, yearLength)
			stringYear = stringYear.substring(yearLength-2, yearLength);
			let intYear = parseInt(stringYear)
			trees.color = colorArray[intYear]
			trees['outline-color'] = 'white'
			trees.opacity = 0.5
		}
		
		yield put(
			setGeoJSON(generateGeoJSON({ data: trees, filterLevel: "tree", boundaryJson }))
		);
		yield put(fetchTreesSuccess(trees));
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearMapsError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(fetchTreesFailure(errorMessage));
	}
}

export function* onFetchTreesStart() {
	yield takeLatest(MapsActionTypes.FETCH_TREES_START, fetchTrees);
}

export function* fetchLandSummary({ payload }) {
	const { entity, entityId } = payload;
	try {
		const response = yield axios({
			method: "GET",
			url: `${BASE_URL_API}/${entity}/${entityId}/land-summary`,
		});

		if (!response.data.success) {
			throw new Error(response.data.message);
		}

		const landSummary = response.data.record;
		yield put(fetchLandSummarySuccess(landSummary));
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearMapsError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(fetchLandSummaryFailure(errorMessage));
	}
}

export function* onFetchLandSummaryStart() {
	yield takeLatest(
		MapsActionTypes.FETCH_LAND_SUMMARY_START,
		fetchLandSummary
	);
}

export function* fetchLandActivities({ payload }) {
	const { entity, entityId, activity } = payload;
	try {
		const response = yield axios({
			method: "GET",
			url: `${BASE_URL_API}/${entity}/${entityId}/land-summary`,
		});

		if (!response.data.success) {
			throw new Error(response.data.message);
		}

		const landSummary = response.data.record;
		yield put(fetchLandActivitiesSuccess(landSummary));
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearMapsError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(fetchLandActivitiesFailure(errorMessage));
	}
}

export function* onFetchLandActivitiesStart() {
	yield takeLatest(
		MapsActionTypes.FETCH_LAND_ACTIVITIES_START,
		fetchLandActivities
	);
}

export function* mapsSagas() {
	yield all([
		call(onFetchCompaniesStart),
		call(onFetchEstatesStart),
		call(onFetchAfdelingsStart),
		call(onFetchBlocksStart),
		call(onFetchTreesStart),
		call(onFetchLandSummaryStart),
		call(onFetchLandActivitiesStart)
	]);
}
