import axios from "axios";
import moment from "moment";
import { takeLatest, put, all, call, select } from "redux-saga/effects";
import { BASE_URL_API } from "../../configs/env";
import { openSnackbar } from "../snackbar/snackbar.action";
import { selectCurrentUser } from "../user/user.selector";
import {
	clearAngkutError,
	fetchAngkutSuccess,
	fetchAngkutFailure,
	fetchAngkutStart,
} from "./logging-angkut.action";
import ActivitiesActionTypes from "./logging-angkut.types";
import queryString from "query-string";
import { selectCurrentUserCompany } from "../company/company.selector";

export function* fetchAngkut({payload}) {
	const filter = payload;
	const company = yield select(selectCurrentUserCompany);
	const query = queryString.stringify({
		fltDeleted: 0,
		pageSize: 0,
		...filter
	});
	try {
		const response = yield axios({
			method: "GET",
			url: `${BASE_URL_API}/transports?${query}`,
		});

		if (!response.data.success) {
			throw new Error(response.data.message);
		}

		const activities = response.data.records;

		yield put(fetchAngkutSuccess(activities));
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearAngkutError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(fetchAngkutFailure(errorMessage));
	}
}

export function* onFetchAngkutStart() {
	yield takeLatest(
		ActivitiesActionTypes.FETCH_ANGKUT_START,
		fetchAngkut
	);
}

export function* loggingAngkutSagas() {
	yield all([
		call(onFetchAngkutStart),
	]);
}
