import { Grid, TextField } from "@material-ui/core";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { updateEstateStart } from "../../redux/estate/estate.action";
import { selectEstateLoading } from "../../redux/estate/estate.selector";
import CustomModal from "../CustomModal/CustomModal";
import PillButton from "../PillButton/PillButton";
import { useStyles } from "./UpdateWorkDetailModal.styles";
import CustomSelect from "../CustomSelect/CustomSelect";
import CustomDatePicker from "../CustomDatePicker/CustomDatePicker";

const UpdateWorkDetailModal = (props) => {
	const classes = useStyles();
	const { estateData, open, onClose, updateEstateStart, isEstateLoading } =
		props;

	const {
		register,
		formState: { errors },
		reset,
		handleSubmit,
		setValue,
	} = useForm({
		mode: "onChange",
	});

	const handleClose = () => {
		onClose();
		reset({
			// estateName: "",
			// area: "",
			// description: "",
		});
	};

	const updateVehicle = (data) => {
		if (!isEstateLoading) {
			// updateEstateStart({ ...data, id: estateData.id, version: estateData.version});
			handleClose();
		}
	};

    const opsiList = [{ id: 'a', value: 'a', name: 'A' },
    { id: 'b', value: 'b', name: 'B' },
    { id: 'c', value: 'c', name: 'C' }]

	useEffect(() => {
		if (!!estateData) {
			setValue("estateName", estateData.name);
			setValue("area", estateData.areaHa);
			setValue("description", estateData.description);
		}
	}, [estateData]);

    const {
		control,
	} = useForm({
		mode: "onChange",
	});

	return (
		<CustomModal
			open={open}
			onClose={handleClose}
			width="40vw"
			title="Update Detail Pekerjaan"
		>
			<Grid container>
				<form>
					<Grid
						container
						justify="center"
						alignContent="center"
						spacing={3}
					>
						 <Grid item xs={12} >
                            <CustomSelect
								fullWidth
								variant="outlined"
								id=""
								label="No. Transaksi"
								name=""
								options={opsiList.map((opt) => ({
									value: opt.id,
									text: opt.name,
								}))}
								control={control}
								// required
							/>
						</Grid>
						<Grid item xs={12} >
                            <CustomSelect
								fullWidth
								variant="outlined"
								id=""
								label="Jenis Pekerjaan"
								name=""
								options={opsiList.map((opt) => ({
									value: opt.id,
									text: opt.name,
								}))}
								control={control}
								// required
							/>
						</Grid>
                        <Grid item xs={12} >
                            <CustomSelect
								fullWidth
								variant="outlined"
								id=""
								label="Alokasi Biaya"
								name=""
								options={opsiList.map((opt) => ({
									value: opt.id,
									text: opt.name,
								}))}
								control={control}
								// required
							/>
						</Grid>
                        <Grid item xs={12} >
							<TextField
								fullWidth
								id="prestasikg"
                                variant="outlined"
								label="Prestasi /KG"
								name="prestasikg"
								inputProps={{
									...register("prestasikg", {
										// required: {
										// 	value: true,
										// 	message: "Luas Area dibutuhkan",
										// },
										pattern: {
											value: /^-?\d*\.?\d*$/,
											message:
												"Prestasi /KG harus dalam bentuk angka",
										},
									}),
								}}
								error={!!errors.prestasikg}
								helperText={errors.prestasikg && errors.prestasikg.message}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								fullWidth
								id="startkm"
                                variant="outlined"
								label="KM Awal"
								name="startkm"
								inputProps={{
									...register("startkm", {
										// required: {
										// 	value: true,
										// 	message: "Luas Area dibutuhkan",
										// },
										pattern: {
											value: /^-?\d*\.?\d*$/,
											message:
												"Km Awal harus dalam bentuk angka",
										},
									}),
								}}
								error={!!errors.startkm}
								helperText={errors.startkm && errors.startkm.message}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								fullWidth
								id="endkm"
                                variant="outlined"
								label="KM Akhir"
								name="endkm"
								inputProps={{
									...register("endkm", {
										// required: {
										// 	value: true,
										// 	message: "Luas Area dibutuhkan",
										// },
										pattern: {
											value: /^-?\d*\.?\d*$/,
											message:
												"Km Akhir harus dalam bentuk angka",
										},
									}),
								}}
								error={!!errors.endkm}
								helperText={errors.endkm && errors.endkm.message}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								fullWidth
								id="cost"
                                variant="outlined"
								label="Biaya"
								name="cost"
								inputProps={{
									...register("cost", {
										// required: {
										// 	value: true,
										// 	message: "Luas Area dibutuhkan",
										// },
										pattern: {
											value: /^-?\d*\.?\d*$/,
											message:
												"Biaya harus dalam bentuk angka",
										},
									}),
								}}
								error={!!errors.cost}
								helperText={errors.cost && errors.cost.message}
							/>
						</Grid>
						<Grid item xs={12} className={classes.buttonContainer}>
							<PillButton
								element="button"
								type="submit"
								text="Simpan"
								// backgroundColor="#2E7D32"
								color="black"
								additionalClasses={[classes.pillButton]}
							/>
						</Grid>
					</Grid>
				</form>
			</Grid>
		</CustomModal>
	);
};

const mapStateToProps = createStructuredSelector({
	isEstateLoading: selectEstateLoading,
});

const mapDispatchToProps = (dispatch) => ({
	updateEstateStart: (estateInfo) => dispatch(updateEstateStart(estateInfo)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdateWorkDetailModal);
