import { Grid, Paper, Button, TextField, Typography, Box, Divider, CardMedia, CardHeader, Avatar } from "@material-ui/core";
import MaterialTable from "material-table";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import moment from "moment";
import { createStructuredSelector } from "reselect";
import { tableColumns } from "../../helpers/RoutePlanTable.helper";
import CustomModal from "../CustomModal/CustomModal";
import CustomSelect from "../CustomSelect/CustomSelect";
import { useStyles } from "./RequestTransportNew.styles";
import PillButton from "../PillButton/PillButton";
import CustomDatePicker from "../../components/CustomDatePicker/CustomDatePicker";
import { selectCurrentUserCompany } from "../../redux/company/company.selector";
import { selectAfdelingPlural } from "../../redux/afdeling/afdeling.selector";
import { fetchAfdelingByEstateStart } from "../../redux/afdeling/afdeling.action";
import {
	deleteEstateStart,
	fetchAllEstatesStart,
} from "../../redux/estate/estate.action";
import {
	selectAllEstates,
	selectEstateLoading,
} from "../../redux/estate/estate.selector";
import {
	selectBlockLoading,
	selectBlockPlural,
} from "../../redux/block/block.selector";
import {
	fetchBlocksStart,
} from "../../redux/block/block.action";
import { selectMembersCompanies } from "../../redux/member/member.selector";
import InputWorkDetailModal from "../InputWorkDetailModal/InputWorkDetailModal";
import UpdateWorkDetailModal from "../UpdateWorkDetailModal/UpdateWorkDetailModal";
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import { Fragment } from "react";
import TruckPhoto from "../../assets/img/truck.png"
import AvatarPhoto from "../../assets/img/avatar.jpg"
import NoDriver from "../../assets/img/no_driver.png"
import KirimOverlay from "../KirimOverlay/KirimOverlay";
import { AddBox } from "@material-ui/icons";
import DriverAssignOverlay from "../DriverAssignOverlay/DriverAssignOverlay";
import CreateRoutePlanModal from "../CreateRoutePlanModal/CreateRoutePlanModal";
import RequestTransportRoute from "../../components/RequestTransportRoute/RequestTransportRoute";


const optionBaris = {text: "All", value:"All"};

const RequestTransportNew = (props) => {
	const classes = useStyles();

	const {
		company,
		// activityPlan,
		// fetchActivitiesPlanStart,
		// fetchBlocksByIdStart,
		blocks,
		estates,
		afdelings,
		// blockSingle,
		// isBlockLoading,
		// isVehicleLoading,
		// vehicle,
		// fetchAllVehicleStart,
		// createRoutePlanStart,
		fetchAfdelingByEstateStart,
    	fetchAllEstatesStart,
		fetchBlocksStart,
		openSnackbar,
	} = props;

	let vehicleDataDummyEmpty = ["A", "B", "C"]
	let vehicleDataDummy = ["A", "B", "C", "C", "D", "E", "F"]

	const {register, handleSubmit, control, watch, setValue} = useForm();
	const [myArray, setMyArray] = useState([optionBaris]);
	const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
	const [day, setDay] = useState("");
	const planDate = watch("planDate",null);
	const block = watch("block", "");
	const activity = watch("activityPlan", "");
	const truck = watch("vehicle", "");
	const tph = watch("tph", "");
	const idEstate = watch("estate", "");
	const afdeling = watch ("afdeling","");

	const [chosen, setTerpilih] = useState([])
	const [notChosen, setCancel] = useState(vehicleDataDummy);
	const [newRoutePlan, setRoutePlan] = useState(false);

	const handleRoutePlan = () =>{
		setRoutePlan(true)
	}

	
	useEffect(() => {
		if (!!company) {
			// fetchAllVehicleTypeStart()
			fetchAllEstatesStart();
			setCancel(vehicleDataDummy);

		}
	}, [company, fetchAllEstatesStart]);

	useEffect(() => {
		if (!!company) {
		  if (!!idEstate) {
			fetchAfdelingByEstateStart(idEstate);
		  } else {
			fetchAfdelingByEstateStart();
		  }
		}
	  }, [idEstate, company]);

	useEffect(() => {
		if (!!company) {
		  if (!!afdeling) {
			fetchBlocksStart({fltDeleted:0, fltAfdeling:afdeling});
		  } 
		}
	  }, [afdeling, company]);

	

	const resetAll =() =>{
		setValue("planDate", null);
		setValue("block", "");
		setValue("activityPlan", "");
		setValue("vehicle", "");
		setValue("tph", "");
		setDay("");
	}


	const opsiMaksud = [
		{ id: 'tbs', value: 'tbs', name: 'Angkut TBS' },
		{ id: 'tbs2', value: 'tbs2', name: 'Angkut TBS 2' },
		{ id: 'tbs3', value: 'tbs3', name: 'Angkut TBS 3' },
		{ id: 'tbs4', value: 'tbs4', name: 'Angkut TBS 4' },
	]

	const handlePilih = (idx) => {
		const temp = [...notChosen];
		temp.splice(idx, 1);
		setTerpilih([...chosen, "Z"]);
		setCancel(temp);
	};

	const handleCancel = (idx) => {
		const temp = [...chosen];
		temp.splice(idx, 1);
		setTerpilih(temp);
		setCancel([...notChosen, "Z"]);
	};

	const handleCreateModalOpen = () => {
		setIsCreateModalOpen(true);
	};

	const handleCreateModalClose = () => {
		setIsCreateModalOpen(false);
	};


	return (
		<>

		{!newRoutePlan ?(
			<>
		<Grid
			container
			// className={classes.produksiBulananContainer}
			spacing={3}		
		>
			
		<Grid
			item xs={12}
			container
			direction="row"
			justify="center"
			alignItems="center"
		>
			<CreateRoutePlanModal
				open={isCreateModalOpen}
				onClose={handleCreateModalClose}
			/>
			
			<Box m={2}>
				<Typography variant="h6">
					Pilih Kendaraan
				</Typography>
			</Box>

			<Grid
				item xs={12}
				container
				direction="row"
			>
				{/* <Box m={2}> */}
					{/* <Paper elevation={3} className={classes.paper}> */}
						<Grid container spacing={2} justify="center">
							<Grid item xs={2} className={classes.vehicle} container direction="column" alignItems="left" justify="center">
								<Box m={0.5}>
									REQUEST DARI:
								</Box>
								<Box m={0.5} >
									AFDELING :
								</Box>
								<Box m={0.5} >
									BLOK :
								</Box>
							</Grid>
							<Grid item xs={1} className={classes.vehicle} container direction="column" alignItems="left" justify="center">
								<Box m={0.5}>
									:
								</Box>
								<Box m={0.5} >
									:
								</Box>
								<Box m={0.5} >
									:
								</Box>
							</Grid>
							
							<Grid item xs={2} className={classes.vehicle} container direction="column" alignItems="right" justify="center">
								<Box m={0.5} >
									NAMA MANDOR
								</Box>
								<Box m={0.5} >
									VIII
								</Box>
								<Box m={0.5} >
									K21, K22, K23
								</Box>
							</Grid>
							

							<Grid item xs={2} className={classes.vehicle} container direction="column" alignItems="left" justify="center">
								<Box m={0.5}>
									TANGGAL REQUEST:
								</Box>
								<Box m={0.5} >
									JUMLAH MUATAN:
								</Box>
								<Box m={0.5} >
									JUMLAH KENDARAAN :
								</Box>
							</Grid>

							<Grid item xs={1} className={classes.vehicle} container direction="column" alignItems="left" justify="center">
								<Box m={0.5}>
									:
								</Box>
								<Box m={0.5} >
									:
								</Box>
								<Box m={0.5} >
									:
								</Box>
							</Grid>

							<Grid item xs={2} className={classes.vehicle} container direction="column" alignItems="left" justifyContent="center">
								<Box m={0.5}>
									27 Februari 2021
								</Box>
								<Box m={0.5}>
									79,784 KG
								</Box>
								<Box m={0.5}>
									4 KENDARAAN
								</Box>
							</Grid>
						</Grid>
					{/* </Paper> */}
				{/* </Box> */}
			</Grid>

			
			<Grid
					item xs={12}
					container
					direction="row"
					justify="flex-start"
				>
					{ !!chosen && chosen.length != 0 ? (
					chosen.map((item,_) => {
						return (
							<Fragment key={"frag"+_}>
								<Box m={2}>
									<a onClick={()=>handleCancel(_)}>
									<Paper elevation={3} className={classes.paperChosen}>
										<Grid container>
											<Grid item xs={5} className={classes.avatar} container direction="row" alignItems="center" justifyContent="center">
												<Grid item xs={12} className={classes.vehicleDetail} alignItems="center">
												<CardMedia
													component="img"
													height="130"
													// widht="100"
													image= {TruckPhoto}
													alt="Truck"
												/>
												</Grid>
												<Grid item xs={12} className={classes.vehicleDetail} alignItems="center">
													BK 2768 CW
												</Grid>
												<Grid item xs={12} className={classes.vehicleDetail}>
													DUMP TRUCK
												</Grid>
											</Grid>
											
											<Grid item xs={7} className={classes.driver} container direction="row" alignItems="center" justifyContent="center">
												<Grid item xs={10} className={classes.avatar}>
													<CardHeader
														avatar={
															<Avatar src={AvatarPhoto} alt="ava" />
														}
														title="Aggnar Otnay"
														subheader="+62213456789"
													/>
												</Grid>
												<Grid item xs={12} className={classes.detail}>
													<a className={classes.detail}>BELUM ADA PLAN</a>
												</Grid>
											</Grid>
										</Grid>
									</Paper>
									</a>
								</Box>					
							</Fragment>
						);
					})):
					(
						<Grid
							item xs={12}
							container
							direction="row"
							justify="center"
							className={classes.emptyGrid}
						>
							Pilih kendaraan untuk request ini
						</Grid>
					)}
				</Grid>

			<Divider variant="middle" style={{width:'100%', backgroundColor:"#000000", marginBottom:"20px"}}/>
			
			<Grid
					item xs={12}
					container
					direction="row"
					justify="flex-start"
					alignItems="center"
				>
					{!!notChosen && notChosen.length != 0 ? (
					notChosen.map((item,idx) => {
						return (
							<Fragment key={"frag"+idx}>
								<Box m={2}>
								<a onClick={()=>handlePilih(idx)}>
									<Paper elevation={3} className={classes.paper}>
									
										<Grid container>
											<Grid item xs={5} className={classes.avatar} container direction="row" alignItems="center" justifyContent="center">
												<Grid item xs={12} className={classes.vehicleDetail} alignItems="center">
												<CardMedia
													component="img"
													height="130"
													// widht="100"
													image= {TruckPhoto}
													alt="Truck"
												/>
												</Grid>
												<Grid item xs={12} className={classes.vehicleDetail} alignItems="center">
													BK 2768 CW
												</Grid>
												<Grid item xs={12} className={classes.vehicleDetail}>
													DUMP TRUCK
												</Grid>
											</Grid>
											
											<Grid item xs={7} className={classes.driver} container direction="row" alignItems="center" justifyContent="center">
												<Grid item xs={10} className={classes.avatar}>
													<CardHeader
														avatar={
															<Avatar src={AvatarPhoto} alt="ava" />
														}
														title="Aggnar Otnay"
														subheader="+62213456789"
													/>
												</Grid>
												<Grid item xs={12} className={classes.detail}>
													BELUM ADA PLAN
												</Grid>
											</Grid>
										</Grid>
										
									</Paper>
									</a>
								</Box>		
							</Fragment>
						);
					})):(
						<Grid
							item xs={12}
							container
							direction="row"
							justify="center"
							className={classes.emptyGrid}
						>
							Tak ada kendaraan untuk dipilih
						</Grid>
					)}
			</Grid>
		</Grid>
		
			{/* </Grid> */}
		</Grid>
		<Grid
			item xs={12}
			container
			direction="row"
			justify="flex-end"
			className={classes.foot}
		>
			<PillButton
				element="button"
				type="button"
				text="Lanjut ke pemilihan rute"
				backgroundColor="#B9940D"
				color="white"
				onClick={handleRoutePlan}
				additionalClasses={[classes.pillButton]}
			/>
		</Grid>
		</>
		) : (
			<>
				<RequestTransportRoute chosen={chosen}/>
			</>
		)}
		</>
	);
};

const mapStateToProps = createStructuredSelector({
 	company: selectCurrentUserCompany,
	estates: selectAllEstates,
	afdelings: selectAfdelingPlural,
 	isEstateLoading: selectEstateLoading,
	blocks: selectBlockPlural,
});

const mapDispatchToProps = (dispatch) => ({
	fetchAllEstatesStart: (companyId) =>
		dispatch(fetchAllEstatesStart(companyId)),
	fetchAfdelingByEstateStart: (companyId) =>
    	dispatch(fetchAfdelingByEstateStart(companyId)),
	fetchBlocksStart: (filters) => dispatch(fetchBlocksStart(filters)),
// 	deleteEstateStart: (estateId) => dispatch(deleteEstateStart(estateId)),
});

export default connect(mapStateToProps, mapDispatchToProps) (RequestTransportNew);
