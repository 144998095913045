import MapsActionTypes from "./maps.types";

const INITIAL_STATE = {
	// general
	error: null,
	loading: false,
	// filters
	company: "",
	estate: "",
	afdeling: "",
	block: "",
	activity: "",
	startDate: null,
	endDate: null,
	companies: [],
	estates: [],
	afdelings: [],
	blocks: [],
	trees: [],
	// maps
	geoJSON: null,
	// land summary
	landSummary: null,
	// tree activities
	treeActivities: null,
	// land activities
	landActivities: null,
};

const mapsReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case MapsActionTypes.CLEAR_ERROR:
			return {
				...state,
				error: null,
			};
		case MapsActionTypes.FETCH_MAP_COMPANIES_START:
		case MapsActionTypes.FETCH_MAP_ESTATES_START:
		case MapsActionTypes.FETCH_MAP_AFDELINGS_START:
		case MapsActionTypes.FETCH_MAP_BLOCKS_START:
		case MapsActionTypes.FETCH_TREES_START:
		case MapsActionTypes.FETCH_LAND_SUMMARY_START:
		case MapsActionTypes.FETCH_LAND_ACTIVITIES_START:
			return {
				...state,
				loading: true,
			};
		case MapsActionTypes.FETCH_MAP_COMPANIES_FAILURE:
		case MapsActionTypes.FETCH_MAP_ESTATES_FAILURE:
		case MapsActionTypes.FETCH_MAP_AFDELINGS_FAILURE:
		case MapsActionTypes.FETCH_MAP_BLOCKS_FAILURE:
		case MapsActionTypes.FETCH_TREES_FAILURE:
		case MapsActionTypes.FETCH_LAND_SUMMARY_FAILURE:
		case MapsActionTypes.FETCH_LAND_ACTIVITIES_FAILURE:
			return {
				...state,
				loading: false,
				error: action.payload,
			};
		case MapsActionTypes.SET_GEO_JSON:
			return {
				...state,
				geoJSON: action.payload,
			};
		case MapsActionTypes.FETCH_MAP_COMPANIES_SUCCESS:
			return {
				...state,
				companies: action.payload,
				loading: false,
			};
		case MapsActionTypes.FETCH_MAP_ESTATES_SUCCESS:
			return {
				...state,
				estates: action.payload,
				loading: false,
			};
		case MapsActionTypes.FETCH_MAP_AFDELINGS_SUCCESS:
			return {
				...state,
				afdelings: action.payload,
				loading: false,
			};
		case MapsActionTypes.FETCH_MAP_BLOCKS_SUCCESS:
			return {
				...state,
				blocks: action.payload,
				loading: false,
			};
		case MapsActionTypes.FETCH_TREES_SUCCESS:
			return {
				...state,
				trees: action.payload,
				loading: false,
			};
		case MapsActionTypes.SET_COMPANY:
			return {
				...state,
				company: action.payload,
			};
		case MapsActionTypes.SET_ESTATE:
			return {
				...state,
				estate: action.payload,
			};
		case MapsActionTypes.SET_AFDELING:
			return {
				...state,
				afdeling: action.payload,
			};
		case MapsActionTypes.SET_BLOCK:
			return {
				...state,
				block: action.payload,
			};
		case MapsActionTypes.SET_ACTIVITY:
			return {
				...state,
				activity: action.payload,
			};
		case MapsActionTypes.SET_START_DATE:
			return {
				...state,
				startDate: action.payload,
			};
		case MapsActionTypes.SET_END_DATE:
			return {
				...state,
				endDate: action.payload,
			};
		case MapsActionTypes.FETCH_LAND_SUMMARY_SUCCESS:
			return {
				...state,
				loading: false,
				landSummary: action.payload,
			};
		case MapsActionTypes.FETCH_LAND_ACTIVITIES_SUCCESS:
			return {
				...state,
				loading: false,
				landActivities: action.payload,
			};
		case MapsActionTypes.RESET_MAPS_FILTER:
			return {
				...state,
				afdeling: "",
				block: "",
				activity: "",
				startDate: null,
				endDate: null,
			};
		default:
			return state;
	}
};

export default mapsReducer;
