import { Grid, Paper, Button, TextField, Typography, Box, Divider, CardMedia, CardHeader, Avatar } from "@material-ui/core";
import MaterialTable from "material-table";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import moment from "moment";
import { createStructuredSelector } from "reselect";
import { tableColumns } from "../../helpers/RoutePlanTable.helper";
import CustomModal from "../CustomModal/CustomModal";
import CustomSelect from "../CustomSelect/CustomSelect";
import { useStyles } from "./RequestTransportRoute.styles";
import PillButton from "../PillButton/PillButton";
import CustomDatePicker from "../../components/CustomDatePicker/CustomDatePicker";
import { selectCurrentUserCompany } from "../../redux/company/company.selector";
import { selectAfdelingPlural } from "../../redux/afdeling/afdeling.selector";
import { fetchAfdelingByEstateStart } from "../../redux/afdeling/afdeling.action";
import {
	deleteEstateStart,
	fetchAllEstatesStart,
} from "../../redux/estate/estate.action";
import {
	selectAllEstates,
	selectEstateLoading,
} from "../../redux/estate/estate.selector";
import {
	selectBlockLoading,
	selectBlockPlural,
} from "../../redux/block/block.selector";
import {
	fetchBlocksStart,
} from "../../redux/block/block.action";
import { selectMembersCompanies } from "../../redux/member/member.selector";
import InputWorkDetailModal from "../InputWorkDetailModal/InputWorkDetailModal";
import UpdateWorkDetailModal from "../UpdateWorkDetailModal/UpdateWorkDetailModal";
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import { Fragment } from "react";
import TruckPhoto from "../../assets/img/truck.png"
import AvatarPhoto from "../../assets/img/avatar.jpg"
import NoDriver from "../../assets/img/no_driver.png"
import KirimOverlay from "../KirimOverlay/KirimOverlay";
import { AddBox } from "@material-ui/icons";
import DriverAssignOverlay from "../DriverAssignOverlay/DriverAssignOverlay";
import CreateRoutePlanModal from "../CreateRoutePlanModal/CreateRoutePlanModal";

function populate(num){
	const arr= [];
	for(var i = 0; i < num ; i++){
		arr.push({tph: i+1, taken:""});
	}
	return arr;
}

const RequestTransportRoute = (props) => {
	const classes = useStyles();

	const {
		chosen,
		company,
		// activityPlan,
		// fetchActivitiesPlanStart,
		// fetchBlocksByIdStart,
		blocks,
		estates,
		afdelings,
		// blockSingle,
		// isBlockLoading,
		// isVehicleLoading,
		// vehicle,
		// fetchAllVehicleStart,
		// createRoutePlanStart,
		fetchAfdelingByEstateStart,
    	fetchAllEstatesStart,
		fetchBlocksStart,
		openSnackbar,
	} = props;


	const {register, handleSubmit, control, watch, setValue} = useForm();
	const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
	const [chosenCar, setCar] = useState("0");
	const [optionBlok1, setBlok1] = useState(null);
	const [optionBlok2, setBlok2] = useState(null);
	const [optionBlok3, setBlok3] = useState(null);

	const [day, setDay] = useState("");
	const planDate = watch("planDate",null);
	const block = watch("block", "");
	const activity = watch("activityPlan", "");
	const truck = watch("vehicle", "");
	const tph = watch("tph", "");
	const idEstate = watch("estate", "");
	const afdeling = watch ("afdeling","");


	const blok1 = populate(50);
	const blok2 = populate(40);
	const blok3 = populate(30);
	
	useEffect(() => {
			setBlok1(blok1);
			setBlok2(blok2);
			setBlok3(blok3);
	}, [company, fetchAllEstatesStart]);

	useEffect(() => {
		if (!!company) {
		  if (!!idEstate) {
			fetchAfdelingByEstateStart(idEstate);
		  } else {
			fetchAfdelingByEstateStart();
		  }
		}
	  }, [idEstate, company]);

	useEffect(() => {
		if (!!company) {
		  if (!!afdeling) {
			fetchBlocksStart({fltDeleted:0, fltAfdeling:afdeling});
		  } 
		}
	  }, [afdeling, company]);

	

	const resetAll =() =>{
		setValue("planDate", null);
		setValue("block", "");
		setValue("activityPlan", "");
		setValue("vehicle", "");
		setValue("tph", "");
		setDay("");
	}


	const opsiMaksud = [
		{ id: 'tbs', value: 'tbs', name: 'Angkut TBS' },
		{ id: 'tbs2', value: 'tbs2', name: 'Angkut TBS 2' },
		{ id: 'tbs3', value: 'tbs3', name: 'Angkut TBS 3' },
		{ id: 'tbs4', value: 'tbs4', name: 'Angkut TBS 4' },
	]

	const handlePilih = (idx) => {
		setCar(idx)
	};

	const handleChoose1 = (tph) => {
		let updatedList = optionBlok1.map(item => {
			if (item.tph == tph) {
			   return {
				  ...item,
				  taken: chosenCar.toString()
			   }; //gets everything that was already in item, and updates "done"
			}
			return item; // else return unmodified item 
		 });
		setBlok1(updatedList);
	};

	const handleChoose2 = (tph) => {
		let updatedList = optionBlok2.map(item => {
			if (item.tph == tph) {
			   return {
				  ...item,
				  taken: chosenCar.toString()
			   }; //gets everything that was already in item, and updates "done"
			}
			return item; // else return unmodified item 
		 });
		setBlok2(updatedList);
	};

	const handleChoose3 = (tph) => {
		let updatedList = optionBlok3.map(item => {
			if (item.tph == tph) {
			   return {
				  ...item,
				  taken: chosenCar.toString()
			   }; //gets everything that was already in item, and updates "done"
			}
			return item; // else return unmodified item 
		 });
		setBlok3(updatedList);
	};



	return (
		<>
		<Grid
			container
			// className={classes.produksiBulananContainer}
			spacing={3}		
		>
			
		<Grid
			item xs={12}
			container
			direction="row"
			justify="center"
			alignItems="center"
		>
			
			<Box m={2}>
				<Typography variant="h6">
					Buat rute baru
				</Typography>
			</Box>

			<Grid
					item xs={12}
					container
					direction="row"
					justify="flex-start"
				>
					{ 
					chosen.map((item,idx) => {
						return (
							<Fragment key={"frag"+idx}>
								<Box m={2}>
								<a onClick={()=>handlePilih(idx)}>
									<Paper elevation={3} className={chosenCar == idx ? classes.paperSelected : classes.paperChosen}>
										<Grid container>
											<Grid item xs={5} className={classes.avatar} container direction="row" alignItems="center" justifyContent="center">
												<Grid item xs={12} className={classes.vehicleDetail} alignItems="center">
												<CardMedia
													component="img"
													height="130"
													// widht="100"
													image= {TruckPhoto}
													alt="Truck"
												/>
												</Grid>
												<Grid item xs={12} className={classes.vehicleDetail} alignItems="center">
													BK 2768 CW
												</Grid>
												<Grid item xs={12} className={classes.vehicleDetail}>
													DUMP TRUCK
												</Grid>
											</Grid>
											
											<Grid item xs={7} className={classes.driver} container direction="row" alignItems="center" justifyContent="center">
												<Grid item xs={10} className={classes.avatar}>
													<CardHeader
														avatar={
															<Avatar src={AvatarPhoto} alt="ava" />
														}
														title="Aggnar Otnay"
														subheader="+62213456789"
													/>
												</Grid>
												<Grid item xs={12} className={classes.detail}>
													<a className={classes.detail}>BELUM ADA PLAN</a>
												</Grid>
											</Grid>
										</Grid>
									</Paper>
									</a>
								</Box>					
							</Fragment>
						);
					})}
				</Grid>

			<Divider variant="middle" style={{width:'100%', backgroundColor:"#000000", marginBottom:"20px"}}/>
			
			<Grid
					item xs={12}
					container
					direction="row"
					justify="flex-start"
			>
				<Grid item xs={3} className={classes.avatar} container direction="row" alignItems="center" justify="center">
					Estate VII
				</Grid>
				<Grid item xs={3} className={classes.avatar} container direction="row" alignItems="center" justify="center">
					Afdeling 8
				</Grid>
				<Grid item xs={12} className={classes.avatar} container direction="row" alignItems="center" justifyContent="center">
					<Box m={2}>
						<Paper elevation={3} className={classes.paperBlok}>
								<Grid container>
								<Grid item xs={2} container direction="row"  justify="center">
								Blok K21
								</Grid>
								<Grid item xs={10} container direction="row" alignItems="center" justifyContent="center">
								{ !!optionBlok1 &&
									optionBlok1.map((b, idx)=>{
										return (
										<PillButton
											element="button"
											type="button"
											text={b.tph}
											backgroundColor={b.taken == chosenCar.toString() ? "#B9940D" : (b.taken=="" ? "white": "rgba(114, 114, 114, 1)")}
											color={b.taken == "" ? "black" : "white"}
											onClick={() => handleChoose1(b.tph)}
											additionalClasses={[classes.pillButtonDays]}
										/>);
									})
								}
								</Grid>
								</Grid>
						</Paper>
					</Box>
				</Grid>
				<Grid item xs={12} className={classes.avatar} container direction="row" alignItems="center" justifyContent="center">
					<Box m={2}>
						<Paper elevation={3} className={classes.paperBlok}>
								<Grid container>
								<Grid item xs={2} container direction="row"  justify="center">
								Blok K22
								</Grid>
								<Grid item xs={10} container direction="row" alignItems="center" justifyContent="center">
								{ !!optionBlok2 &&
									optionBlok2.map((b, idx)=>{
										return (
										<PillButton
											element="button"
											type="button"
											text={b.tph}
											backgroundColor={b.taken == chosenCar.toString() ? "#B9940D" : (b.taken=="" ? "white": "rgba(114, 114, 114, 1)")}
											color={b.taken == "" ? "black" : "white"}
											onClick={() => handleChoose2(b.tph)}
											additionalClasses={[classes.pillButtonDays]}
										/>);
									})
								}
								</Grid>
								</Grid>
						</Paper>
					</Box>
				</Grid>
				<Grid item xs={12} className={classes.avatar} container direction="row" alignItems="center" justifyContent="center">
					<Box m={2}>
						<Paper elevation={3} className={classes.paperBlok}>
								<Grid container>
								<Grid item xs={2} container direction="row"  justify="center">
								Blok K23
								</Grid>
								<Grid item xs={10} container direction="row" alignItems="center" justifyContent="center">
								{ !!optionBlok3 &&
									optionBlok3.map((b, idx)=>{
										return (
										<PillButton
											element="button"
											type="button"
											text={b.tph}
											backgroundColor={b.taken == chosenCar.toString() ? "#B9940D" : (b.taken=="" ? "white": "rgba(114, 114, 114, 1)")}
											color={b.taken == "" ? "black" : "white"}
											onClick={() => handleChoose3(b.tph)}
											additionalClasses={[classes.pillButtonDays]}
										/>);
									})
								}
								</Grid>
								</Grid>
						</Paper>
					</Box>
				</Grid>
			</Grid>
		</Grid>
		
			{/* </Grid> */}
		</Grid>
		<Grid
			item xs={12}
			container
			direction="row"
			justify="flex-end"
			className={classes.foot}
		>
			<PillButton
				element="button"
				type="button"
				text="Simpan"
				backgroundColor="#B9940D"
				color="white"
				//onClick={handleRoutePlan}
				additionalClasses={[classes.pillButton]}
			/>
		</Grid>
		</>
	);
};

const mapStateToProps = createStructuredSelector({
 	company: selectCurrentUserCompany,
	estates: selectAllEstates,
	afdelings: selectAfdelingPlural,
 	isEstateLoading: selectEstateLoading,
	blocks: selectBlockPlural,
});

const mapDispatchToProps = (dispatch) => ({
	fetchAllEstatesStart: (companyId) =>
		dispatch(fetchAllEstatesStart(companyId)),
	fetchAfdelingByEstateStart: (companyId) =>
    	dispatch(fetchAfdelingByEstateStart(companyId)),
	fetchBlocksStart: (filters) => dispatch(fetchBlocksStart(filters)),
// 	deleteEstateStart: (estateId) => dispatch(deleteEstateStart(estateId)),
});

export default connect(mapStateToProps, mapDispatchToProps) (RequestTransportRoute);
