import axios from "axios";
import moment from "moment";
import { takeLatest, put, all, call, select } from "redux-saga/effects";
import { BASE_URL_API } from "../../configs/env";
import { openSnackbar } from "../snackbar/snackbar.action";
import { selectCurrentUser } from "../user/user.selector";
import {
	clearAfdelingError,
	fetchAllAfdelingSuccess,
	fetchAllAfdelingFailure,
	fetchAfdelingByEstateSuccess,
	fetchAfdelingByEstateFailure,
	createAfdelingSuccess,
	createAfdelingFailure,
	updateAfdelingSuccess,
	updateAfdelingFailure,
	deleteAfdelingSuccess,
	deleteAfdelingFailure,
	fetchAllAfdelingStart,
} from "./afdeling.action";
import AfdelingActionTypes from "./afdeling.types";
import queryString from "query-string";
import { selectCurrentUserCompany } from "../company/company.selector";

export function* fetchAllAfdeling() {
	const company = yield select(selectCurrentUserCompany);
	const query = queryString.stringify({
		fltCompany: company.id,
		fltDeleted: 0,
		pageSize: 0,
		sidx: "name",
		sord: "asc"
	});
	try {
		const response = yield axios({
			method: "GET",
			url: `${BASE_URL_API}/afdelings?${query}`,
		});

		if (!response.data.success) {
			throw new Error(response.data.message);
		}

		const afdeling = response.data.records;

		yield put(fetchAllAfdelingSuccess(afdeling));
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearAfdelingError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(fetchAllAfdelingFailure(errorMessage));
	}
}

export function* onFetchAllAfdelingStart() {
	yield takeLatest(
		AfdelingActionTypes.FETCH_ALL_AFDELING_START,
		fetchAllAfdeling
	);
}

export function* fetchAfdelingByEstate({ payload }) {
	const info = payload;
	if (!!!info) {
		yield put(fetchAfdelingByEstateSuccess([]));
		return;
	}
	const company = yield select(selectCurrentUserCompany);
	const query = queryString.stringify({
		fltEstate: info,
		fltCompany: company.id,
		fltDeleted: 0,
		pageSize: 0,
		sidx: "name",
		sord: "asc"
	});
	try {
		const response = yield axios({
			method: "GET",
			url: `${BASE_URL_API}/afdelings?${query}`,
		});

		if (!response.data.success) {
			throw new Error(response.data.message);
		}

		const afdeling = response.data.records;
		yield put(fetchAfdelingByEstateSuccess(afdeling));
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearAfdelingError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(fetchAfdelingByEstateFailure(errorMessage));
	}
}

export function* onFetchAfdelingByEstateStart() {
	yield takeLatest(
		AfdelingActionTypes.FETCH_ESTATE_AFDELING_START,
		fetchAfdelingByEstate
	);
}

export function* createAfdeling({ payload }) {
	const currentUser = yield select(selectCurrentUser);
	const company = yield select(selectCurrentUserCompany);
	const afdelingInfo = payload;
	try {
		const uuid = yield axios({
			method: "GET",
			url: "https://www.uuidgenerator.net/api/version1",
		});

		const response = yield axios({
			method: "POST",
			url: `${BASE_URL_API}/afdelings`,
			data: {
				areaHa: afdelingInfo.area,
				companyId: company.id,
				name: afdelingInfo.name,
				description: afdelingInfo.description,
				estateId: afdelingInfo.estate,
				isDeleted: 0,
				createdBy: currentUser.username,
				createdTime: moment().format("YYYY-MM-DD[T]HH:mm:ssZZ"),
				deletedBy: null,
				deletedTime: null,
				id: uuid.data,
			},
		});

		if (!response.data.success) {
			throw new Error(response.data.message);
		}

		yield put(createAfdelingSuccess());
		yield put(fetchAllAfdelingStart());
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearAfdelingError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(createAfdelingFailure(errorMessage));
	}
}

export function* onCreateAfdelingStart() {
	yield takeLatest(AfdelingActionTypes.CREATE_AFDELING_START, createAfdeling);
}

export function* updateAfdeling({ payload }) {
	const { id, info, version } = payload;
	const currentUser = yield select(selectCurrentUser);
	const company = yield select(selectCurrentUserCompany);
	try {
		const response = yield axios({
			method: "PUT",
			url: `${BASE_URL_API}/afdelings/${id}`,
			data: {
				areaHa: info.area,
				companyId: company.id,
				name: info.name,
				description: info.description,
				estateId: info.estate,
				updatedBy: currentUser.username,
				updatedTime: moment().format("YYYY-MM-DD[T]HH:mm:ssZZ"),
				id: id,
				version: version, 
			},
		});

		if (!response.data.success) {
			throw new Error(response.data.message);
		}

		yield put(updateAfdelingSuccess());
		yield put(fetchAllAfdelingStart());
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearAfdelingError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(updateAfdelingFailure(errorMessage));
	}
}

export function* onUpdateAfdelingStart() {
	yield takeLatest(AfdelingActionTypes.UPDATE_AFDELING_START, updateAfdeling);
}

export function* deleteAfdeling({ payload }) {
	const id = payload;
	try {
		const response = yield axios({
			method: "DELETE",
			url: `${BASE_URL_API}/afdelings/${id}`,
		});

		if (!response.data.success) {
			throw new Error(response.data.message);
		}

		yield put(deleteAfdelingSuccess());
		yield put(fetchAllAfdelingStart());
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearAfdelingError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(deleteAfdelingFailure(errorMessage));
	}
}

export function* onDeleteAfdelingStart() {
	yield takeLatest(AfdelingActionTypes.DELETE_AFDELING_START, deleteAfdeling);
}

export function* afdelingSagas() {
	yield all([
		call(onFetchAllAfdelingStart),
		call(onCreateAfdelingStart),
		call(onUpdateAfdelingStart),
		call(onDeleteAfdelingStart),
		call(onFetchAfdelingByEstateStart),
	]);
}
