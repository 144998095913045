import { KEYCLOAK_ID, REDIRECT_URI, SERVER_ENV } from "../../configs/env";
import queryString from "query-string";
import { CODE_VERIFIER } from "../../configs/PCKEConfigs";
import URL from "url";

const KeycloakToken = async (code) => {
	const baseUrlKeycloak =
		SERVER_ENV === "development"
			? "accounts-dev.semaigroup.com"
			: "accounts.semaigroup.com";
	let params = {
		client_id: KEYCLOAK_ID,
		code: code,
		grant_type: "authorization_code",
		redirect_uri: REDIRECT_URI,
		code_verifier: CODE_VERIFIER,
		state: JSON.stringify({ provider: "Keycloak" }),
		proxyBaseUrl: `https://${baseUrlKeycloak}/realms/semai-pro/protocol/openid-connect/token`,
	};

	const post_data = queryString.stringify(params);
	let parsedUrl = URL.parse(
		`https://${baseUrlKeycloak}/realms/semai-pro/protocol/openid-connect/token`,
		true
	);

	let realHeaders = {};
	realHeaders["Host"] = parsedUrl.host;
	realHeaders["Content-Length"] = post_data.length;
	realHeaders["Content-Type"] = "application/x-www-form-urlencoded";

	const options = {
		host: parsedUrl.hostname,
		port: parsedUrl.port,
		path: parsedUrl.pathname,
		method: "POST",
		headers: realHeaders,
	};

	const payload = Object.assign(
		{
			body: post_data,
		},
		options
	);

	let response = await fetch(
		`https://${baseUrlKeycloak}/realms/semai-pro/protocol/openid-connect/token`,
		payload
	);

	let res = await response.json();
	return { accessToken: res.access_token, refreshToken: res.refresh_token };
};

export default KeycloakToken;
