import React from "react";
import MaskedInput from "react-text-mask";
import PropTypes from "prop-types";

const MaskedInputCustom = (props) => {
	const { inputRef, ...other } = props;

	return (
		<MaskedInput
			{...other}
			ref={(ref) => {
				inputRef(ref ? ref.inputElement : null);
			}}
		/>
	);
};

MaskedInputCustom.propTypes = {
	inputRef: PropTypes.func.isRequired,
};

export default MaskedInputCustom;
