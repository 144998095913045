import { SERVER_ENV } from "../../configs/env";

const KeycloakUser = async (token) => {
	let baseUrl =
		process.env.NODE_ENV === "development"
			? ""
			: SERVER_ENV === "development"
			? "https://accounts-dev.semaigroup.com"
			: "https://accounts.semaigroup.com";
	const res = await fetch(
		`${baseUrl}/realms/semai-pro/protocol/openid-connect/userinfo`,
		{
			method: "get",
			headers: {
				Authorization: `Bearer ${token}`,
			},
		}
	);
	const res_obj = await res.json();
	return res_obj;
};

export default KeycloakUser;
