import { Hidden } from "@material-ui/core";
import React, { useEffect } from "react";
import clsx from "clsx";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import { useStyles } from "./Content.styles";
import BurgerIcon from "../BurgerIcon/BurgerIcon";
import publicRoutes from "../../routes/public.routes";
import NavLinkItem from "../NavlinkItem/NavLinkItem";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { useHistory } from "react-router";
import { logoutStart } from "../../redux/user/user.action";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectCurrentUser } from "../../redux/user/user.selector";

const Content = (props) => {
	const classes = useStyles();
	const { currentUser, userLogoutStart } = props;
	const history = useHistory();
	const [open, setOpen] = React.useState(false);

	const handleDrawerOpen = () => {
		setOpen(true);
	};

	const handleDrawerClose = () => {
		setOpen(false);
	};

	const handleLogout = () => {
		userLogoutStart();
	};

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [history.location.pathname]);
	
	return (
		<div className={classes.root}>
			<Hidden smDown>
				<CssBaseline />
				<Drawer
					variant="permanent"
					className={clsx(classes.drawer, {
						[classes.drawerOpen]: open,
						[classes.drawerClose]: !open,
					})}
					classes={{
						paper: clsx({
							[classes.drawerOpen]: open,
							[classes.drawerClose]: !open,
						}),
					}}
				>
					<div className={classes.toolbar}>
						<BurgerIcon
							open={open}
							onClick={
								open ? handleDrawerClose : handleDrawerOpen
							}
						/>
					</div>
					<Divider />
					<List>
						{publicRoutes
							.filter((route) => route.hasMenu)
							.map((route, idx) => (
								<NavLinkItem open={open} {...route} key={idx} />
							))}
						<Divider />
						<ListItem button className={classes.listItem} onClick={handleLogout}>
							<ListItemIcon
								className={classes.listItemIcon}
							>
								<ExitToAppIcon />
							</ListItemIcon>
							<ListItemText
								disableTypography
								primary={"Logout"}
								className={classes.listItemText}
							/>
						</ListItem>
					</List>
				</Drawer>
			</Hidden>
			<main className={classes.content}>{props.children}</main>
		</div>
	);
};

const mapStateToProps = createStructuredSelector({
	currentUser: selectCurrentUser,
});

const mapDispatchToProps = (dispatch) => ({
	userLogoutStart: () => dispatch(logoutStart()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Content);