import MonthlyActivityPlanActionTypes from "./monthly-activity-plan.types";

export const clearMonthlyActivityPlanError = () => ({
	type: MonthlyActivityPlanActionTypes.CLEAR_ERROR,
});

export const fetchAllMonthlyActivityPlanStart = (filter) => ({
	type: MonthlyActivityPlanActionTypes.FETCH_ALL_MONTHLY_ACTIVITY_PLAN_START,
	payload: filter,
});

export const fetchAllMonthlyActivityPlanSuccess = (monthlyActivityPlan) => ({
	type: MonthlyActivityPlanActionTypes.FETCH_ALL_MONTHLY_ACTIVITY_PLAN_SUCCESS,
	payload: monthlyActivityPlan,
});

export const fetchAllMonthlyActivityPlanFailure = (error) => ({
	type: MonthlyActivityPlanActionTypes.FETCH_ALL_MONTHLY_ACTIVITY_PLAN_FAILURE,
	payload: error,
});

export const createMonthlyActivityPlanStart = ({ info, filter }) => ({
	type: MonthlyActivityPlanActionTypes.CREATE_MONTHLY_ACTIVITY_PLAN_START,
	payload : { info, filter },
});

export const createMonthlyActivityPlanSuccess = () => ({
	type: MonthlyActivityPlanActionTypes.CREATE_MONTHLY_ACTIVITY_PLAN_SUCCESS,
});

export const createMonthlyActivityPlanFailure = (error) => ({
	type: MonthlyActivityPlanActionTypes.CREATE_MONTHLY_ACTIVITY_PLAN_FAILURE,
	payload: error,
});

export const updateMonthlyActivityPlanStart = ({ newMonth, current, filter }) => ({
	type: MonthlyActivityPlanActionTypes.UPDATE_MONTHLY_ACTIVITY_PLAN_START,
	payload: { newMonth, current, filter },
});

export const updateMonthlyActivityPlanSuccess = () => ({
	type: MonthlyActivityPlanActionTypes.UPDATE_MONTHLY_ACTIVITY_PLAN_SUCCESS,
});

export const updateMonthlyActivityPlanFailure = (error) => ({
	type: MonthlyActivityPlanActionTypes.UPDATE_MONTHLY_ACTIVITY_PLAN_FAILURE,
	payload: error,
});