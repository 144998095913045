import SnackbarActionTypes from "./snackbar.types";

const INITIAL_STATE = {
	open: false,
	message: "",
	color: "info",
	place: "bl",
	onClose: null,
	timer: 5000, // on ms
	dispatchActions: [],
};

const snackbarReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case SnackbarActionTypes.OPEN_SNACKBAR:
			const {
				message,
				color,
				place,
				onClose,
				timer,
				dispatchActions,
			} = action.payload;
			return {
				...state,
				open: true,
				message,
				color,
				place,
				onClose,
				timer: !!timer ? timer : 15000,
				dispatchActions,
			};
		case SnackbarActionTypes.CLOSE_SNACKBAR:
			return {
				...state,
				open: false,
				message: "",
				color: "info",
				place: "bl",
				onClose: null,
				timer: 15000,
				dispatchActions: [],
			};
		default:
			return state;
	}
};

export default snackbarReducer;
